import { LoginFlow, LogoutFlow, Session } from "@ory/client";
import { useEffect, useState } from "react";
import { frontendApi } from "../api/oryApi";
import SignallingServerConnection from "../stores/SignallingServerConnection";


export const useAuth = (signallingStore: SignallingServerConnection) => {
  const [session, setSession] = useState<Session | undefined>();
  const [sessionJwt, setSessionJwt] = useState<string | undefined>();
  const [logoutFlow, setLogoutFlow] = useState<LogoutFlow | undefined>();
  const [loginFlow, setLoginFlow] = useState<LoginFlow | undefined>();

  useEffect(() => {
    frontendApi
      .toSession()
      .then(({ data }) => {
        setSession(data);
        frontendApi.createBrowserLogoutFlow().then(({ data: flow }) => {
          setLogoutFlow(flow);
        });
      })
      .then(() =>
        frontendApi.toSession({tokenizeAs: "webrtc"})
      ).then((session) => {
        const jwt = session.data.tokenized;
        if (!jwt) {
          throw new Error("No tokenized session");
        }
        signallingStore.setJwt(jwt)
        setSessionJwt(jwt)
    })
      .catch(() => {
        frontendApi
          .createBrowserLoginFlow({ returnTo: window.location.toString() })
          .then(({ data: flow }) => {
            setLoginFlow(flow);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const isSessionVivacityAdmin =
    session && session.identity?.traits.email.endsWith("@vivacitylabs.com");

  return {
    session,
    sessionJwt,
    isSessionVivacityAdmin,
    loginFlow,
    logoutFlow,
  };
};
