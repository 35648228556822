/* eslint-disable */
// source: vivacity/core/detector_tracker_frame.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_core_point_pb = require('../../vivacity/core/point_pb.js');
goog.object.extend(proto, vivacity_core_point_pb);
var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_core_classifying_detector_class_types_pb = require('../../vivacity/core/classifying_detector_class_types_pb.js');
goog.object.extend(proto, vivacity_core_classifying_detector_class_types_pb);
var vivacity_core_countline_crossing_pb = require('../../vivacity/core/countline_crossing_pb.js');
goog.object.extend(proto, vivacity_core_countline_crossing_pb);
var vivacity_core_vector_pb = require('../../vivacity/core/vector_pb.js');
goog.object.extend(proto, vivacity_core_vector_pb);
var vivacity_core_movement_pb = require('../../vivacity/core/movement_pb.js');
goog.object.extend(proto, vivacity_core_movement_pb);
var vivacity_core_zonal_features_pb = require('../../vivacity/core/zonal_features_pb.js');
goog.object.extend(proto, vivacity_core_zonal_features_pb);
var vivacity_core_tensor_pb = require('../../vivacity/core/tensor_pb.js');
goog.object.extend(proto, vivacity_core_tensor_pb);
var vivacity_core_nvosd_params_pb = require('../../vivacity/core/nvosd_params_pb.js');
goog.object.extend(proto, vivacity_core_nvosd_params_pb);
var vivacity_core_anpr_confidence_pb = require('../../vivacity/core/anpr_confidence_pb.js');
goog.object.extend(proto, vivacity_core_anpr_confidence_pb);
goog.exportSymbol('proto.vivacity.core.AssembledTrack', null, global);
goog.exportSymbol('proto.vivacity.core.AssembledTrackBatch', null, global);
goog.exportSymbol('proto.vivacity.core.BrokenTrackStitchingMessage', null, global);
goog.exportSymbol('proto.vivacity.core.ClassificationLabelInfo', null, global);
goog.exportSymbol('proto.vivacity.core.ClassificationMetadata', null, global);
goog.exportSymbol('proto.vivacity.core.DebugMatchScore', null, global);
goog.exportSymbol('proto.vivacity.core.DebugMatchScore.GatingProgress', null, global);
goog.exportSymbol('proto.vivacity.core.DetectionBox', null, global);
goog.exportSymbol('proto.vivacity.core.DetectionFeatureVectors', null, global);
goog.exportSymbol('proto.vivacity.core.DetectionMetadata', null, global);
goog.exportSymbol('proto.vivacity.core.DetectorTrackerFrame', null, global);
goog.exportSymbol('proto.vivacity.core.DetectorTrackerFrame.VideoID', null, global);
goog.exportSymbol('proto.vivacity.core.FootprintEstimate3d', null, global);
goog.exportSymbol('proto.vivacity.core.FragmentedDetectorTrackerFrame', null, global);
goog.exportSymbol('proto.vivacity.core.FrameMetadata', null, global);
goog.exportSymbol('proto.vivacity.core.PoseEstimate3d', null, global);
goog.exportSymbol('proto.vivacity.core.TrackHead', null, global);
goog.exportSymbol('proto.vivacity.core.TrackMetadata', null, global);
goog.exportSymbol('proto.vivacity.core.TrackMetadata.TrackTerminationReason', null, global);
goog.exportSymbol('proto.vivacity.core.TrackSegment', null, global);
goog.exportSymbol('proto.vivacity.core.VivacityFeatureExtractorVectors', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.VivacityFeatureExtractorVectors = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.VivacityFeatureExtractorVectors.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.VivacityFeatureExtractorVectors, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.VivacityFeatureExtractorVectors.displayName = 'proto.vivacity.core.VivacityFeatureExtractorVectors';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DetectionFeatureVectors = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.DetectionFeatureVectors.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.DetectionFeatureVectors, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DetectionFeatureVectors.displayName = 'proto.vivacity.core.DetectionFeatureVectors';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DetectionMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.DetectionMetadata.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.DetectionMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DetectionMetadata.displayName = 'proto.vivacity.core.DetectionMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ClassificationMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.ClassificationMetadata.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.ClassificationMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ClassificationMetadata.displayName = 'proto.vivacity.core.ClassificationMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.ClassificationLabelInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.ClassificationLabelInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.ClassificationLabelInfo.displayName = 'proto.vivacity.core.ClassificationLabelInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.PoseEstimate3d = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.PoseEstimate3d, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.PoseEstimate3d.displayName = 'proto.vivacity.core.PoseEstimate3d';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.FootprintEstimate3d = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.FootprintEstimate3d, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.FootprintEstimate3d.displayName = 'proto.vivacity.core.FootprintEstimate3d';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DetectionBox = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.DetectionBox.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.DetectionBox, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DetectionBox.displayName = 'proto.vivacity.core.DetectionBox';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DebugMatchScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.DebugMatchScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DebugMatchScore.displayName = 'proto.vivacity.core.DebugMatchScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TrackMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.TrackMetadata.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.TrackMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TrackMetadata.displayName = 'proto.vivacity.core.TrackMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TrackHead = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.TrackHead.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.TrackHead, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TrackHead.displayName = 'proto.vivacity.core.TrackHead';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.DetectorTrackerFrame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.DetectorTrackerFrame.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.DetectorTrackerFrame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.DetectorTrackerFrame.displayName = 'proto.vivacity.core.DetectorTrackerFrame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.FrameMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.FrameMetadata.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.FrameMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.FrameMetadata.displayName = 'proto.vivacity.core.FrameMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.FragmentedDetectorTrackerFrame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.FragmentedDetectorTrackerFrame.displayName = 'proto.vivacity.core.FragmentedDetectorTrackerFrame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AssembledTrack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.AssembledTrack.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.AssembledTrack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AssembledTrack.displayName = 'proto.vivacity.core.AssembledTrack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.AssembledTrackBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.AssembledTrackBatch.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.AssembledTrackBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.AssembledTrackBatch.displayName = 'proto.vivacity.core.AssembledTrackBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.TrackSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.core.TrackSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.TrackSegment.displayName = 'proto.vivacity.core.TrackSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.core.BrokenTrackStitchingMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.core.BrokenTrackStitchingMessage.repeatedFields_, null);
};
goog.inherits(proto.vivacity.core.BrokenTrackStitchingMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.core.BrokenTrackStitchingMessage.displayName = 'proto.vivacity.core.BrokenTrackStitchingMessage';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.repeatedFields_ = [1,2,3,18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.VivacityFeatureExtractorVectors.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.VivacityFeatureExtractorVectors} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.toObject = function(includeInstance, msg) {
  var f, obj = {
    labHistogramList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    lbpSimpleHistogramList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    lbpOrientedHistogramList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    lbpColourOrientedHistogram: (f = msg.getLbpColourOrientedHistogram()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    lbpFullnessOrientedHistogram: (f = msg.getLbpFullnessOrientedHistogram()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    lbpStrengthOrientedHistogram: (f = msg.getLbpStrengthOrientedHistogram()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    hogHistogram: (f = msg.getHogHistogram()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    width: jspb.Message.getFieldWithDefault(msg, 8, 0),
    height: jspb.Message.getFieldWithDefault(msg, 9, 0),
    bgrBuffer: (f = msg.getBgrBuffer()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    gaussianBuffer: (f = msg.getGaussianBuffer()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    labBuffer: (f = msg.getLabBuffer()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    lbpSimpleBuffer: (f = msg.getLbpSimpleBuffer()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    lbpOrientedBuffer: (f = msg.getLbpOrientedBuffer()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    lbpColourOrientedBuffer: (f = msg.getLbpColourOrientedBuffer()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    lbpFullnessOrientedBuffer: (f = msg.getLbpFullnessOrientedBuffer()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    lbpStrengthOrientedBuffer: (f = msg.getLbpStrengthOrientedBuffer()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    labHistogramOpencvList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 18)) == null ? undefined : f,
    lbpSimpleHistogramOpencvList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 19)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.VivacityFeatureExtractorVectors;
  return proto.vivacity.core.VivacityFeatureExtractorVectors.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.VivacityFeatureExtractorVectors} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLabHistogram(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLbpSimpleHistogram(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLbpOrientedHistogram(values[i]);
      }
      break;
    case 4:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setLbpColourOrientedHistogram(value);
      break;
    case 5:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setLbpFullnessOrientedHistogram(value);
      break;
    case 6:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setLbpStrengthOrientedHistogram(value);
      break;
    case 7:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setHogHistogram(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 10:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setBgrBuffer(value);
      break;
    case 11:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setGaussianBuffer(value);
      break;
    case 12:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setLabBuffer(value);
      break;
    case 13:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setLbpSimpleBuffer(value);
      break;
    case 14:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setLbpOrientedBuffer(value);
      break;
    case 15:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setLbpColourOrientedBuffer(value);
      break;
    case 16:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setLbpFullnessOrientedBuffer(value);
      break;
    case 17:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setLbpStrengthOrientedBuffer(value);
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLabHistogramOpencv(values[i]);
      }
      break;
    case 19:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLbpSimpleHistogramOpencv(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.VivacityFeatureExtractorVectors.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.VivacityFeatureExtractorVectors} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabHistogramList();
  if (f.length > 0) {
    writer.writePackedFloat(
      1,
      f
    );
  }
  f = message.getLbpSimpleHistogramList();
  if (f.length > 0) {
    writer.writePackedFloat(
      2,
      f
    );
  }
  f = message.getLbpOrientedHistogramList();
  if (f.length > 0) {
    writer.writePackedFloat(
      3,
      f
    );
  }
  f = message.getLbpColourOrientedHistogram();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getLbpFullnessOrientedHistogram();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getLbpStrengthOrientedHistogram();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getHogHistogram();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getBgrBuffer();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getGaussianBuffer();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getLabBuffer();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getLbpSimpleBuffer();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getLbpOrientedBuffer();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getLbpColourOrientedBuffer();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getLbpFullnessOrientedBuffer();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getLbpStrengthOrientedBuffer();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getLabHistogramOpencvList();
  if (f.length > 0) {
    writer.writePackedFloat(
      18,
      f
    );
  }
  f = message.getLbpSimpleHistogramOpencvList();
  if (f.length > 0) {
    writer.writePackedFloat(
      19,
      f
    );
  }
};


/**
 * repeated float lab_histogram = 1;
 * @return {!Array<number>}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLabHistogramList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLabHistogramList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.addLabHistogram = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLabHistogramList = function() {
  return this.setLabHistogramList([]);
};


/**
 * repeated float lbp_simple_histogram = 2;
 * @return {!Array<number>}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpSimpleHistogramList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpSimpleHistogramList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.addLbpSimpleHistogram = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpSimpleHistogramList = function() {
  return this.setLbpSimpleHistogramList([]);
};


/**
 * repeated float lbp_oriented_histogram = 3;
 * @return {!Array<number>}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpOrientedHistogramList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpOrientedHistogramList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.addLbpOrientedHistogram = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpOrientedHistogramList = function() {
  return this.setLbpOrientedHistogramList([]);
};


/**
 * optional Tensor lbp_colour_oriented_histogram = 4;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpColourOrientedHistogram = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 4));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpColourOrientedHistogram = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpColourOrientedHistogram = function() {
  return this.setLbpColourOrientedHistogram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasLbpColourOrientedHistogram = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Tensor lbp_fullness_oriented_histogram = 5;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpFullnessOrientedHistogram = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 5));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpFullnessOrientedHistogram = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpFullnessOrientedHistogram = function() {
  return this.setLbpFullnessOrientedHistogram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasLbpFullnessOrientedHistogram = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Tensor lbp_strength_oriented_histogram = 6;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpStrengthOrientedHistogram = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 6));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpStrengthOrientedHistogram = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpStrengthOrientedHistogram = function() {
  return this.setLbpStrengthOrientedHistogram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasLbpStrengthOrientedHistogram = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Tensor hog_histogram = 7;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getHogHistogram = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 7));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setHogHistogram = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearHogHistogram = function() {
  return this.setHogHistogram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasHogHistogram = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 width = 8;
 * @return {number}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 height = 9;
 * @return {number}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional Tensor bgr_buffer = 10;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getBgrBuffer = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 10));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setBgrBuffer = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearBgrBuffer = function() {
  return this.setBgrBuffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasBgrBuffer = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Tensor gaussian_buffer = 11;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getGaussianBuffer = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 11));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setGaussianBuffer = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearGaussianBuffer = function() {
  return this.setGaussianBuffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasGaussianBuffer = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Tensor lab_buffer = 12;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLabBuffer = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 12));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLabBuffer = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLabBuffer = function() {
  return this.setLabBuffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasLabBuffer = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Tensor lbp_simple_buffer = 13;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpSimpleBuffer = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 13));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpSimpleBuffer = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpSimpleBuffer = function() {
  return this.setLbpSimpleBuffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasLbpSimpleBuffer = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Tensor lbp_oriented_buffer = 14;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpOrientedBuffer = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 14));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpOrientedBuffer = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpOrientedBuffer = function() {
  return this.setLbpOrientedBuffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasLbpOrientedBuffer = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Tensor lbp_colour_oriented_buffer = 15;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpColourOrientedBuffer = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 15));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpColourOrientedBuffer = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpColourOrientedBuffer = function() {
  return this.setLbpColourOrientedBuffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasLbpColourOrientedBuffer = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Tensor lbp_fullness_oriented_buffer = 16;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpFullnessOrientedBuffer = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 16));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpFullnessOrientedBuffer = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpFullnessOrientedBuffer = function() {
  return this.setLbpFullnessOrientedBuffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasLbpFullnessOrientedBuffer = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Tensor lbp_strength_oriented_buffer = 17;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpStrengthOrientedBuffer = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 17));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
*/
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpStrengthOrientedBuffer = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpStrengthOrientedBuffer = function() {
  return this.setLbpStrengthOrientedBuffer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.hasLbpStrengthOrientedBuffer = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated float lab_histogram_opencv = 18;
 * @return {!Array<number>}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLabHistogramOpencvList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLabHistogramOpencvList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.addLabHistogramOpencv = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLabHistogramOpencvList = function() {
  return this.setLabHistogramOpencvList([]);
};


/**
 * repeated float lbp_simple_histogram_opencv = 19;
 * @return {!Array<number>}
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.getLbpSimpleHistogramOpencvList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 19));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.setLbpSimpleHistogramOpencvList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.addLbpSimpleHistogramOpencv = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.VivacityFeatureExtractorVectors} returns this
 */
proto.vivacity.core.VivacityFeatureExtractorVectors.prototype.clearLbpSimpleHistogramOpencvList = function() {
  return this.setLbpSimpleHistogramOpencvList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.DetectionFeatureVectors.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DetectionFeatureVectors.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DetectionFeatureVectors.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DetectionFeatureVectors} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionFeatureVectors.toObject = function(includeInstance, msg) {
  var f, obj = {
    vivafxFeatures: (f = msg.getVivafxFeatures()) && proto.vivacity.core.VivacityFeatureExtractorVectors.toObject(includeInstance, f),
    yoloEmbedding512List: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DetectionFeatureVectors}
 */
proto.vivacity.core.DetectionFeatureVectors.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DetectionFeatureVectors;
  return proto.vivacity.core.DetectionFeatureVectors.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DetectionFeatureVectors} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DetectionFeatureVectors}
 */
proto.vivacity.core.DetectionFeatureVectors.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.VivacityFeatureExtractorVectors;
      reader.readMessage(value,proto.vivacity.core.VivacityFeatureExtractorVectors.deserializeBinaryFromReader);
      msg.setVivafxFeatures(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addYoloEmbedding512(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DetectionFeatureVectors.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DetectionFeatureVectors.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DetectionFeatureVectors} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionFeatureVectors.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVivafxFeatures();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.VivacityFeatureExtractorVectors.serializeBinaryToWriter
    );
  }
  f = message.getYoloEmbedding512List();
  if (f.length > 0) {
    writer.writePackedFloat(
      2,
      f
    );
  }
};


/**
 * optional VivacityFeatureExtractorVectors vivafx_features = 1;
 * @return {?proto.vivacity.core.VivacityFeatureExtractorVectors}
 */
proto.vivacity.core.DetectionFeatureVectors.prototype.getVivafxFeatures = function() {
  return /** @type{?proto.vivacity.core.VivacityFeatureExtractorVectors} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.VivacityFeatureExtractorVectors, 1));
};


/**
 * @param {?proto.vivacity.core.VivacityFeatureExtractorVectors|undefined} value
 * @return {!proto.vivacity.core.DetectionFeatureVectors} returns this
*/
proto.vivacity.core.DetectionFeatureVectors.prototype.setVivafxFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionFeatureVectors} returns this
 */
proto.vivacity.core.DetectionFeatureVectors.prototype.clearVivafxFeatures = function() {
  return this.setVivafxFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionFeatureVectors.prototype.hasVivafxFeatures = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated float yolo_embedding_512 = 2;
 * @return {!Array<number>}
 */
proto.vivacity.core.DetectionFeatureVectors.prototype.getYoloEmbedding512List = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.DetectionFeatureVectors} returns this
 */
proto.vivacity.core.DetectionFeatureVectors.prototype.setYoloEmbedding512List = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.DetectionFeatureVectors} returns this
 */
proto.vivacity.core.DetectionFeatureVectors.prototype.addYoloEmbedding512 = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectionFeatureVectors} returns this
 */
proto.vivacity.core.DetectionFeatureVectors.prototype.clearYoloEmbedding512List = function() {
  return this.setYoloEmbedding512List([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.DetectionMetadata.repeatedFields_ = [3,13,15,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DetectionMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DetectionMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DetectionMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    objectness: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    classConfidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    allClassConfidencesList: jspb.Message.toObjectList(msg.getAllClassConfidencesList(),
    vivacity_core_classifying_detector_class_types_pb.ClassConfidence.toObject, includeInstance),
    gridBoxIndexX: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gridBoxIndexY: jspb.Message.getFieldWithDefault(msg, 5, 0),
    yoloLayerIndex: jspb.Message.getFieldWithDefault(msg, 6, 0),
    anchorBoxIndex: jspb.Message.getFieldWithDefault(msg, 14, 0),
    features: (f = msg.getFeatures()) && proto.vivacity.core.DetectionFeatureVectors.toObject(includeInstance, f),
    uniqueComponentId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    textParams: (f = msg.getTextParams()) && vivacity_core_nvosd_params_pb.NvOSDTextParams.toObject(includeInstance, f),
    rectParams: (f = msg.getRectParams()) && vivacity_core_nvosd_params_pb.NvOSDRectParams.toObject(includeInstance, f),
    objectLabel: jspb.Message.getFieldWithDefault(msg, 11, ""),
    secondaryDetectionsList: jspb.Message.toObjectList(msg.getSecondaryDetectionsList(),
    proto.vivacity.core.DetectionBox.toObject, includeInstance),
    outputTensorsList: jspb.Message.toObjectList(msg.getOutputTensorsList(),
    vivacity_core_tensor_pb.Tensor.toObject, includeInstance),
    anprConfidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    trackerConfidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    secondaryClassificationsList: jspb.Message.toObjectList(msg.getSecondaryClassificationsList(),
    proto.vivacity.core.ClassificationMetadata.toObject, includeInstance),
    isMasked: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    isLethalMasked: jspb.Message.getBooleanFieldWithDefault(msg, 20, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DetectionMetadata}
 */
proto.vivacity.core.DetectionMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DetectionMetadata;
  return proto.vivacity.core.DetectionMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DetectionMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DetectionMetadata}
 */
proto.vivacity.core.DetectionMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setObjectness(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClassConfidence(value);
      break;
    case 3:
      var value = new vivacity_core_classifying_detector_class_types_pb.ClassConfidence;
      reader.readMessage(value,vivacity_core_classifying_detector_class_types_pb.ClassConfidence.deserializeBinaryFromReader);
      msg.addAllClassConfidences(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGridBoxIndexX(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGridBoxIndexY(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYoloLayerIndex(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAnchorBoxIndex(value);
      break;
    case 7:
      var value = new proto.vivacity.core.DetectionFeatureVectors;
      reader.readMessage(value,proto.vivacity.core.DetectionFeatureVectors.deserializeBinaryFromReader);
      msg.setFeatures(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUniqueComponentId(value);
      break;
    case 9:
      var value = new vivacity_core_nvosd_params_pb.NvOSDTextParams;
      reader.readMessage(value,vivacity_core_nvosd_params_pb.NvOSDTextParams.deserializeBinaryFromReader);
      msg.setTextParams(value);
      break;
    case 10:
      var value = new vivacity_core_nvosd_params_pb.NvOSDRectParams;
      reader.readMessage(value,vivacity_core_nvosd_params_pb.NvOSDRectParams.deserializeBinaryFromReader);
      msg.setRectParams(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectLabel(value);
      break;
    case 13:
      var value = new proto.vivacity.core.DetectionBox;
      reader.readMessage(value,proto.vivacity.core.DetectionBox.deserializeBinaryFromReader);
      msg.addSecondaryDetections(value);
      break;
    case 15:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.addOutputTensors(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAnprConfidence(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTrackerConfidence(value);
      break;
    case 18:
      var value = new proto.vivacity.core.ClassificationMetadata;
      reader.readMessage(value,proto.vivacity.core.ClassificationMetadata.deserializeBinaryFromReader);
      msg.addSecondaryClassifications(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMasked(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLethalMasked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DetectionMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DetectionMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DetectionMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjectness();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getClassConfidence();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getAllClassConfidencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      vivacity_core_classifying_detector_class_types_pb.ClassConfidence.serializeBinaryToWriter
    );
  }
  f = message.getGridBoxIndexX();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getGridBoxIndexY();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getYoloLayerIndex();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAnchorBoxIndex();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getFeatures();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.vivacity.core.DetectionFeatureVectors.serializeBinaryToWriter
    );
  }
  f = message.getUniqueComponentId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTextParams();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      vivacity_core_nvosd_params_pb.NvOSDTextParams.serializeBinaryToWriter
    );
  }
  f = message.getRectParams();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_core_nvosd_params_pb.NvOSDRectParams.serializeBinaryToWriter
    );
  }
  f = message.getObjectLabel();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSecondaryDetectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.vivacity.core.DetectionBox.serializeBinaryToWriter
    );
  }
  f = message.getOutputTensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getAnprConfidence();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getTrackerConfidence();
  if (f !== 0.0) {
    writer.writeFloat(
      17,
      f
    );
  }
  f = message.getSecondaryClassificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.vivacity.core.ClassificationMetadata.serializeBinaryToWriter
    );
  }
  f = message.getIsMasked();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getIsLethalMasked();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
};


/**
 * optional float objectness = 1;
 * @return {number}
 */
proto.vivacity.core.DetectionMetadata.prototype.getObjectness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setObjectness = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float class_confidence = 2;
 * @return {number}
 */
proto.vivacity.core.DetectionMetadata.prototype.getClassConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setClassConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated ClassConfidence all_class_confidences = 3;
 * @return {!Array<!proto.vivacity.core.ClassConfidence>}
 */
proto.vivacity.core.DetectionMetadata.prototype.getAllClassConfidencesList = function() {
  return /** @type{!Array<!proto.vivacity.core.ClassConfidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_classifying_detector_class_types_pb.ClassConfidence, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassConfidence>} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
*/
proto.vivacity.core.DetectionMetadata.prototype.setAllClassConfidencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.ClassConfidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ClassConfidence}
 */
proto.vivacity.core.DetectionMetadata.prototype.addAllClassConfidences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.ClassConfidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.clearAllClassConfidencesList = function() {
  return this.setAllClassConfidencesList([]);
};


/**
 * optional uint32 grid_box_index_x = 4;
 * @return {number}
 */
proto.vivacity.core.DetectionMetadata.prototype.getGridBoxIndexX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setGridBoxIndexX = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 grid_box_index_y = 5;
 * @return {number}
 */
proto.vivacity.core.DetectionMetadata.prototype.getGridBoxIndexY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setGridBoxIndexY = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 yolo_layer_index = 6;
 * @return {number}
 */
proto.vivacity.core.DetectionMetadata.prototype.getYoloLayerIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setYoloLayerIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 anchor_box_index = 14;
 * @return {number}
 */
proto.vivacity.core.DetectionMetadata.prototype.getAnchorBoxIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setAnchorBoxIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional DetectionFeatureVectors features = 7;
 * @return {?proto.vivacity.core.DetectionFeatureVectors}
 */
proto.vivacity.core.DetectionMetadata.prototype.getFeatures = function() {
  return /** @type{?proto.vivacity.core.DetectionFeatureVectors} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.DetectionFeatureVectors, 7));
};


/**
 * @param {?proto.vivacity.core.DetectionFeatureVectors|undefined} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
*/
proto.vivacity.core.DetectionMetadata.prototype.setFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.clearFeatures = function() {
  return this.setFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionMetadata.prototype.hasFeatures = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 unique_component_id = 8;
 * @return {number}
 */
proto.vivacity.core.DetectionMetadata.prototype.getUniqueComponentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setUniqueComponentId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional NvOSDTextParams text_params = 9;
 * @return {?proto.vivacity.core.NvOSDTextParams}
 */
proto.vivacity.core.DetectionMetadata.prototype.getTextParams = function() {
  return /** @type{?proto.vivacity.core.NvOSDTextParams} */ (
    jspb.Message.getWrapperField(this, vivacity_core_nvosd_params_pb.NvOSDTextParams, 9));
};


/**
 * @param {?proto.vivacity.core.NvOSDTextParams|undefined} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
*/
proto.vivacity.core.DetectionMetadata.prototype.setTextParams = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.clearTextParams = function() {
  return this.setTextParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionMetadata.prototype.hasTextParams = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional NvOSDRectParams rect_params = 10;
 * @return {?proto.vivacity.core.NvOSDRectParams}
 */
proto.vivacity.core.DetectionMetadata.prototype.getRectParams = function() {
  return /** @type{?proto.vivacity.core.NvOSDRectParams} */ (
    jspb.Message.getWrapperField(this, vivacity_core_nvosd_params_pb.NvOSDRectParams, 10));
};


/**
 * @param {?proto.vivacity.core.NvOSDRectParams|undefined} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
*/
proto.vivacity.core.DetectionMetadata.prototype.setRectParams = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.clearRectParams = function() {
  return this.setRectParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionMetadata.prototype.hasRectParams = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string object_label = 11;
 * @return {string}
 */
proto.vivacity.core.DetectionMetadata.prototype.getObjectLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setObjectLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated DetectionBox secondary_detections = 13;
 * @return {!Array<!proto.vivacity.core.DetectionBox>}
 */
proto.vivacity.core.DetectionMetadata.prototype.getSecondaryDetectionsList = function() {
  return /** @type{!Array<!proto.vivacity.core.DetectionBox>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.DetectionBox, 13));
};


/**
 * @param {!Array<!proto.vivacity.core.DetectionBox>} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
*/
proto.vivacity.core.DetectionMetadata.prototype.setSecondaryDetectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.vivacity.core.DetectionBox=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.DetectionMetadata.prototype.addSecondaryDetections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.vivacity.core.DetectionBox, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.clearSecondaryDetectionsList = function() {
  return this.setSecondaryDetectionsList([]);
};


/**
 * repeated Tensor output_tensors = 15;
 * @return {!Array<!proto.vivacity.core.Tensor>}
 */
proto.vivacity.core.DetectionMetadata.prototype.getOutputTensorsList = function() {
  return /** @type{!Array<!proto.vivacity.core.Tensor>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_tensor_pb.Tensor, 15));
};


/**
 * @param {!Array<!proto.vivacity.core.Tensor>} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
*/
proto.vivacity.core.DetectionMetadata.prototype.setOutputTensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.vivacity.core.Tensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor}
 */
proto.vivacity.core.DetectionMetadata.prototype.addOutputTensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.vivacity.core.Tensor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.clearOutputTensorsList = function() {
  return this.setOutputTensorsList([]);
};


/**
 * optional float anpr_confidence = 16;
 * @return {number}
 */
proto.vivacity.core.DetectionMetadata.prototype.getAnprConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setAnprConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float tracker_confidence = 17;
 * @return {number}
 */
proto.vivacity.core.DetectionMetadata.prototype.getTrackerConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setTrackerConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * repeated ClassificationMetadata secondary_classifications = 18;
 * @return {!Array<!proto.vivacity.core.ClassificationMetadata>}
 */
proto.vivacity.core.DetectionMetadata.prototype.getSecondaryClassificationsList = function() {
  return /** @type{!Array<!proto.vivacity.core.ClassificationMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.ClassificationMetadata, 18));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassificationMetadata>} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
*/
proto.vivacity.core.DetectionMetadata.prototype.setSecondaryClassificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.vivacity.core.ClassificationMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ClassificationMetadata}
 */
proto.vivacity.core.DetectionMetadata.prototype.addSecondaryClassifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.vivacity.core.ClassificationMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.clearSecondaryClassificationsList = function() {
  return this.setSecondaryClassificationsList([]);
};


/**
 * optional bool is_masked = 19;
 * @return {boolean}
 */
proto.vivacity.core.DetectionMetadata.prototype.getIsMasked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setIsMasked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool is_lethal_masked = 20;
 * @return {boolean}
 */
proto.vivacity.core.DetectionMetadata.prototype.getIsLethalMasked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.DetectionMetadata} returns this
 */
proto.vivacity.core.DetectionMetadata.prototype.setIsLethalMasked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.ClassificationMetadata.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ClassificationMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ClassificationMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ClassificationMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassificationMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    uniqueComponentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numLabels: jspb.Message.getFieldWithDefault(msg, 2, 0),
    labelInfoListList: jspb.Message.toObjectList(msg.getLabelInfoListList(),
    proto.vivacity.core.ClassificationLabelInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ClassificationMetadata}
 */
proto.vivacity.core.ClassificationMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ClassificationMetadata;
  return proto.vivacity.core.ClassificationMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ClassificationMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ClassificationMetadata}
 */
proto.vivacity.core.ClassificationMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUniqueComponentId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumLabels(value);
      break;
    case 3:
      var value = new proto.vivacity.core.ClassificationLabelInfo;
      reader.readMessage(value,proto.vivacity.core.ClassificationLabelInfo.deserializeBinaryFromReader);
      msg.addLabelInfoList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ClassificationMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ClassificationMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ClassificationMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassificationMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUniqueComponentId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNumLabels();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLabelInfoListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.core.ClassificationLabelInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 unique_component_id = 1;
 * @return {number}
 */
proto.vivacity.core.ClassificationMetadata.prototype.getUniqueComponentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassificationMetadata} returns this
 */
proto.vivacity.core.ClassificationMetadata.prototype.setUniqueComponentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 num_labels = 2;
 * @return {number}
 */
proto.vivacity.core.ClassificationMetadata.prototype.getNumLabels = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassificationMetadata} returns this
 */
proto.vivacity.core.ClassificationMetadata.prototype.setNumLabels = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ClassificationLabelInfo label_info_list = 3;
 * @return {!Array<!proto.vivacity.core.ClassificationLabelInfo>}
 */
proto.vivacity.core.ClassificationMetadata.prototype.getLabelInfoListList = function() {
  return /** @type{!Array<!proto.vivacity.core.ClassificationLabelInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.ClassificationLabelInfo, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassificationLabelInfo>} value
 * @return {!proto.vivacity.core.ClassificationMetadata} returns this
*/
proto.vivacity.core.ClassificationMetadata.prototype.setLabelInfoListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.ClassificationLabelInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ClassificationLabelInfo}
 */
proto.vivacity.core.ClassificationMetadata.prototype.addLabelInfoList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.ClassificationLabelInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.ClassificationMetadata} returns this
 */
proto.vivacity.core.ClassificationMetadata.prototype.clearLabelInfoListList = function() {
  return this.setLabelInfoListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.ClassificationLabelInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.ClassificationLabelInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassificationLabelInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_class: jspb.Message.getFieldWithDefault(msg, 1, 0),
    resultLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resultClassId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numClasses: jspb.Message.getFieldWithDefault(msg, 4, 0),
    labelId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    probability: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.ClassificationLabelInfo}
 */
proto.vivacity.core.ClassificationLabelInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.ClassificationLabelInfo;
  return proto.vivacity.core.ClassificationLabelInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.ClassificationLabelInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.ClassificationLabelInfo}
 */
proto.vivacity.core.ClassificationLabelInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setClass(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResultLabel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setResultClassId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumClasses(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLabelId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProbability(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.ClassificationLabelInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.ClassificationLabelInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.ClassificationLabelInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClass();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getResultLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResultClassId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getNumClasses();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getLabelId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getProbability();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional ClassifyingDetectorClassTypes class = 1;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.getClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.ClassificationLabelInfo} returns this
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.setClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string result_label = 2;
 * @return {string}
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.getResultLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.ClassificationLabelInfo} returns this
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.setResultLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 result_class_id = 3;
 * @return {number}
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.getResultClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassificationLabelInfo} returns this
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.setResultClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 num_classes = 4;
 * @return {number}
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.getNumClasses = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassificationLabelInfo} returns this
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.setNumClasses = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 label_id = 5;
 * @return {number}
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.getLabelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassificationLabelInfo} returns this
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.setLabelId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional float probability = 6;
 * @return {number}
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.getProbability = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.ClassificationLabelInfo} returns this
 */
proto.vivacity.core.ClassificationLabelInfo.prototype.setProbability = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.PoseEstimate3d.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.PoseEstimate3d.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.PoseEstimate3d} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.PoseEstimate3d.toObject = function(includeInstance, msg) {
  var f, obj = {
    center: (f = msg.getCenter()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    front: (f = msg.getFront()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    left: (f = msg.getLeft()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    top: (f = msg.getTop()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    back: (f = msg.getBack()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    right: (f = msg.getRight()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    bottom: (f = msg.getBottom()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    footprint: (f = msg.getFootprint()) && proto.vivacity.core.FootprintEstimate3d.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.PoseEstimate3d}
 */
proto.vivacity.core.PoseEstimate3d.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.PoseEstimate3d;
  return proto.vivacity.core.PoseEstimate3d.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.PoseEstimate3d} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.PoseEstimate3d}
 */
proto.vivacity.core.PoseEstimate3d.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setCenter(value);
      break;
    case 2:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setFront(value);
      break;
    case 3:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setLeft(value);
      break;
    case 4:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setTop(value);
      break;
    case 5:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setBack(value);
      break;
    case 6:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setRight(value);
      break;
    case 7:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setBottom(value);
      break;
    case 8:
      var value = new proto.vivacity.core.FootprintEstimate3d;
      reader.readMessage(value,proto.vivacity.core.FootprintEstimate3d.deserializeBinaryFromReader);
      msg.setFootprint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.PoseEstimate3d.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.PoseEstimate3d.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.PoseEstimate3d} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.PoseEstimate3d.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCenter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getFront();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getLeft();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getTop();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getBack();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getRight();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getBottom();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getFootprint();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.vivacity.core.FootprintEstimate3d.serializeBinaryToWriter
    );
  }
};


/**
 * optional Point center = 1;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.PoseEstimate3d.prototype.getCenter = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 1));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
*/
proto.vivacity.core.PoseEstimate3d.prototype.setCenter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
 */
proto.vivacity.core.PoseEstimate3d.prototype.clearCenter = function() {
  return this.setCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PoseEstimate3d.prototype.hasCenter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Point front = 2;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.PoseEstimate3d.prototype.getFront = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 2));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
*/
proto.vivacity.core.PoseEstimate3d.prototype.setFront = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
 */
proto.vivacity.core.PoseEstimate3d.prototype.clearFront = function() {
  return this.setFront(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PoseEstimate3d.prototype.hasFront = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Point left = 3;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.PoseEstimate3d.prototype.getLeft = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 3));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
*/
proto.vivacity.core.PoseEstimate3d.prototype.setLeft = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
 */
proto.vivacity.core.PoseEstimate3d.prototype.clearLeft = function() {
  return this.setLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PoseEstimate3d.prototype.hasLeft = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Point top = 4;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.PoseEstimate3d.prototype.getTop = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 4));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
*/
proto.vivacity.core.PoseEstimate3d.prototype.setTop = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
 */
proto.vivacity.core.PoseEstimate3d.prototype.clearTop = function() {
  return this.setTop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PoseEstimate3d.prototype.hasTop = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Point back = 5;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.PoseEstimate3d.prototype.getBack = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 5));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
*/
proto.vivacity.core.PoseEstimate3d.prototype.setBack = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
 */
proto.vivacity.core.PoseEstimate3d.prototype.clearBack = function() {
  return this.setBack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PoseEstimate3d.prototype.hasBack = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Point right = 6;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.PoseEstimate3d.prototype.getRight = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 6));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
*/
proto.vivacity.core.PoseEstimate3d.prototype.setRight = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
 */
proto.vivacity.core.PoseEstimate3d.prototype.clearRight = function() {
  return this.setRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PoseEstimate3d.prototype.hasRight = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Point bottom = 7;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.PoseEstimate3d.prototype.getBottom = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 7));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
*/
proto.vivacity.core.PoseEstimate3d.prototype.setBottom = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
 */
proto.vivacity.core.PoseEstimate3d.prototype.clearBottom = function() {
  return this.setBottom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PoseEstimate3d.prototype.hasBottom = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FootprintEstimate3d footprint = 8;
 * @return {?proto.vivacity.core.FootprintEstimate3d}
 */
proto.vivacity.core.PoseEstimate3d.prototype.getFootprint = function() {
  return /** @type{?proto.vivacity.core.FootprintEstimate3d} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.FootprintEstimate3d, 8));
};


/**
 * @param {?proto.vivacity.core.FootprintEstimate3d|undefined} value
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
*/
proto.vivacity.core.PoseEstimate3d.prototype.setFootprint = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.PoseEstimate3d} returns this
 */
proto.vivacity.core.PoseEstimate3d.prototype.clearFootprint = function() {
  return this.setFootprint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.PoseEstimate3d.prototype.hasFootprint = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.FootprintEstimate3d.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.FootprintEstimate3d} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FootprintEstimate3d.toObject = function(includeInstance, msg) {
  var f, obj = {
    frontCenterGroundPlane: (f = msg.getFrontCenterGroundPlane()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    frontLeftGroundPlane: (f = msg.getFrontLeftGroundPlane()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    frontRightGroundPlane: (f = msg.getFrontRightGroundPlane()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    backCenterGroundPlane: (f = msg.getBackCenterGroundPlane()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    backLeftGroundPlane: (f = msg.getBackLeftGroundPlane()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    backRightGroundPlane: (f = msg.getBackRightGroundPlane()) && vivacity_core_point_pb.Point.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.FootprintEstimate3d}
 */
proto.vivacity.core.FootprintEstimate3d.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.FootprintEstimate3d;
  return proto.vivacity.core.FootprintEstimate3d.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.FootprintEstimate3d} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.FootprintEstimate3d}
 */
proto.vivacity.core.FootprintEstimate3d.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setFrontCenterGroundPlane(value);
      break;
    case 2:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setFrontLeftGroundPlane(value);
      break;
    case 3:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setFrontRightGroundPlane(value);
      break;
    case 4:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setBackCenterGroundPlane(value);
      break;
    case 5:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setBackLeftGroundPlane(value);
      break;
    case 6:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setBackRightGroundPlane(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.FootprintEstimate3d.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.FootprintEstimate3d} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FootprintEstimate3d.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrontCenterGroundPlane();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getFrontLeftGroundPlane();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getFrontRightGroundPlane();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getBackCenterGroundPlane();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getBackLeftGroundPlane();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getBackRightGroundPlane();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
};


/**
 * optional Point front_center_ground_plane = 1;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.getFrontCenterGroundPlane = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 1));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
*/
proto.vivacity.core.FootprintEstimate3d.prototype.setFrontCenterGroundPlane = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
 */
proto.vivacity.core.FootprintEstimate3d.prototype.clearFrontCenterGroundPlane = function() {
  return this.setFrontCenterGroundPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.hasFrontCenterGroundPlane = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Point front_left_ground_plane = 2;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.getFrontLeftGroundPlane = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 2));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
*/
proto.vivacity.core.FootprintEstimate3d.prototype.setFrontLeftGroundPlane = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
 */
proto.vivacity.core.FootprintEstimate3d.prototype.clearFrontLeftGroundPlane = function() {
  return this.setFrontLeftGroundPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.hasFrontLeftGroundPlane = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Point front_right_ground_plane = 3;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.getFrontRightGroundPlane = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 3));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
*/
proto.vivacity.core.FootprintEstimate3d.prototype.setFrontRightGroundPlane = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
 */
proto.vivacity.core.FootprintEstimate3d.prototype.clearFrontRightGroundPlane = function() {
  return this.setFrontRightGroundPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.hasFrontRightGroundPlane = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Point back_center_ground_plane = 4;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.getBackCenterGroundPlane = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 4));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
*/
proto.vivacity.core.FootprintEstimate3d.prototype.setBackCenterGroundPlane = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
 */
proto.vivacity.core.FootprintEstimate3d.prototype.clearBackCenterGroundPlane = function() {
  return this.setBackCenterGroundPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.hasBackCenterGroundPlane = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Point back_left_ground_plane = 5;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.getBackLeftGroundPlane = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 5));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
*/
proto.vivacity.core.FootprintEstimate3d.prototype.setBackLeftGroundPlane = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
 */
proto.vivacity.core.FootprintEstimate3d.prototype.clearBackLeftGroundPlane = function() {
  return this.setBackLeftGroundPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.hasBackLeftGroundPlane = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Point back_right_ground_plane = 6;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.getBackRightGroundPlane = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 6));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
*/
proto.vivacity.core.FootprintEstimate3d.prototype.setBackRightGroundPlane = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FootprintEstimate3d} returns this
 */
proto.vivacity.core.FootprintEstimate3d.prototype.clearBackRightGroundPlane = function() {
  return this.setBackRightGroundPlane(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FootprintEstimate3d.prototype.hasBackRightGroundPlane = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.DetectionBox.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DetectionBox.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DetectionBox.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DetectionBox} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionBox.toObject = function(includeInstance, msg) {
  var f, obj = {
    topLeft: (f = msg.getTopLeft()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    bottomRight: (f = msg.getBottomRight()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    detectionClass: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hashedAnprPlate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    anprPlate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    centerCenter: (f = msg.getCenterCenter()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    bottomCenter: (f = msg.getBottomCenter()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    licensePlateCenterCenter: (f = msg.getLicensePlateCenterCenter()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    occupancyZonePointsList: jspb.Message.toObjectList(msg.getOccupancyZonePointsList(),
    vivacity_core_point_pb.Point.toObject, includeInstance),
    customPointsMap: (f = msg.getCustomPointsMap()) ? f.toObject(includeInstance, proto.vivacity.core.Point.toObject) : [],
    topRight: (f = msg.getTopRight()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    bottomLeft: (f = msg.getBottomLeft()) && vivacity_core_point_pb.Point.toObject(includeInstance, f),
    poseEstimate3d: (f = msg.getPoseEstimate3d()) && proto.vivacity.core.PoseEstimate3d.toObject(includeInstance, f),
    metadata: (f = msg.getMetadata()) && proto.vivacity.core.DetectionMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.DetectionBox.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DetectionBox;
  return proto.vivacity.core.DetectionBox.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DetectionBox} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.DetectionBox.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setTopLeft(value);
      break;
    case 2:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setBottomRight(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setDetectionClass(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHashedAnprPlate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnprPlate(value);
      break;
    case 6:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setCenterCenter(value);
      break;
    case 7:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setBottomCenter(value);
      break;
    case 8:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setLicensePlateCenterCenter(value);
      break;
    case 9:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.addOccupancyZonePoints(value);
      break;
    case 16:
      var value = msg.getCustomPointsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.Point.deserializeBinaryFromReader, "", new proto.vivacity.core.Point());
         });
      break;
    case 17:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setTopRight(value);
      break;
    case 18:
      var value = new vivacity_core_point_pb.Point;
      reader.readMessage(value,vivacity_core_point_pb.Point.deserializeBinaryFromReader);
      msg.setBottomLeft(value);
      break;
    case 19:
      var value = new proto.vivacity.core.PoseEstimate3d;
      reader.readMessage(value,proto.vivacity.core.PoseEstimate3d.deserializeBinaryFromReader);
      msg.setPoseEstimate3d(value);
      break;
    case 31:
      var value = new proto.vivacity.core.DetectionMetadata;
      reader.readMessage(value,proto.vivacity.core.DetectionMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DetectionBox.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DetectionBox.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DetectionBox} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectionBox.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopLeft();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getBottomRight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getDetectionClass();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getHashedAnprPlate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAnprPlate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCenterCenter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getBottomCenter();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getLicensePlateCenterCenter();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getOccupancyZonePointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getCustomPointsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.Point.serializeBinaryToWriter);
  }
  f = message.getTopRight();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getBottomLeft();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_core_point_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getPoseEstimate3d();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.vivacity.core.PoseEstimate3d.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.vivacity.core.DetectionMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional Point top_left = 1;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.DetectionBox.prototype.getTopLeft = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 1));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setTopLeft = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearTopLeft = function() {
  return this.setTopLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionBox.prototype.hasTopLeft = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Point bottom_right = 2;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.DetectionBox.prototype.getBottomRight = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 2));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setBottomRight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearBottomRight = function() {
  return this.setBottomRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionBox.prototype.hasBottomRight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ClassifyingDetectorClassTypes detection_class = 3;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.DetectionBox.prototype.getDetectionClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.setDetectionClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string hashed_anpr_plate = 5;
 * @return {string}
 */
proto.vivacity.core.DetectionBox.prototype.getHashedAnprPlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.setHashedAnprPlate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string anpr_plate = 10;
 * @return {string}
 */
proto.vivacity.core.DetectionBox.prototype.getAnprPlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.setAnprPlate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Point center_center = 6;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.DetectionBox.prototype.getCenterCenter = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 6));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setCenterCenter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearCenterCenter = function() {
  return this.setCenterCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionBox.prototype.hasCenterCenter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Point bottom_center = 7;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.DetectionBox.prototype.getBottomCenter = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 7));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setBottomCenter = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearBottomCenter = function() {
  return this.setBottomCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionBox.prototype.hasBottomCenter = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Point license_plate_center_center = 8;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.DetectionBox.prototype.getLicensePlateCenterCenter = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 8));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setLicensePlateCenterCenter = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearLicensePlateCenterCenter = function() {
  return this.setLicensePlateCenterCenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionBox.prototype.hasLicensePlateCenterCenter = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Point occupancy_zone_points = 9;
 * @return {!Array<!proto.vivacity.core.Point>}
 */
proto.vivacity.core.DetectionBox.prototype.getOccupancyZonePointsList = function() {
  return /** @type{!Array<!proto.vivacity.core.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_point_pb.Point, 9));
};


/**
 * @param {!Array<!proto.vivacity.core.Point>} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setOccupancyZonePointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.vivacity.core.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Point}
 */
proto.vivacity.core.DetectionBox.prototype.addOccupancyZonePoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.vivacity.core.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearOccupancyZonePointsList = function() {
  return this.setOccupancyZonePointsList([]);
};


/**
 * map<string, Point> custom_points = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.vivacity.core.Point>}
 */
proto.vivacity.core.DetectionBox.prototype.getCustomPointsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.vivacity.core.Point>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      proto.vivacity.core.Point));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearCustomPointsMap = function() {
  this.getCustomPointsMap().clear();
  return this;
};


/**
 * optional Point top_right = 17;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.DetectionBox.prototype.getTopRight = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 17));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setTopRight = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearTopRight = function() {
  return this.setTopRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionBox.prototype.hasTopRight = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Point bottom_left = 18;
 * @return {?proto.vivacity.core.Point}
 */
proto.vivacity.core.DetectionBox.prototype.getBottomLeft = function() {
  return /** @type{?proto.vivacity.core.Point} */ (
    jspb.Message.getWrapperField(this, vivacity_core_point_pb.Point, 18));
};


/**
 * @param {?proto.vivacity.core.Point|undefined} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setBottomLeft = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearBottomLeft = function() {
  return this.setBottomLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionBox.prototype.hasBottomLeft = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional PoseEstimate3d pose_estimate_3d = 19;
 * @return {?proto.vivacity.core.PoseEstimate3d}
 */
proto.vivacity.core.DetectionBox.prototype.getPoseEstimate3d = function() {
  return /** @type{?proto.vivacity.core.PoseEstimate3d} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.PoseEstimate3d, 19));
};


/**
 * @param {?proto.vivacity.core.PoseEstimate3d|undefined} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setPoseEstimate3d = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearPoseEstimate3d = function() {
  return this.setPoseEstimate3d(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionBox.prototype.hasPoseEstimate3d = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional DetectionMetadata metadata = 31;
 * @return {?proto.vivacity.core.DetectionMetadata}
 */
proto.vivacity.core.DetectionBox.prototype.getMetadata = function() {
  return /** @type{?proto.vivacity.core.DetectionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.DetectionMetadata, 31));
};


/**
 * @param {?proto.vivacity.core.DetectionMetadata|undefined} value
 * @return {!proto.vivacity.core.DetectionBox} returns this
*/
proto.vivacity.core.DetectionBox.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectionBox} returns this
 */
proto.vivacity.core.DetectionBox.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectionBox.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 31) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DebugMatchScore.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DebugMatchScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DebugMatchScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DebugMatchScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    iouScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    labScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lbpSimpleScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lbpOrientedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    lbpColourOrientedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lbpFullnessOrientedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    lbpStrengthOrientedScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    hogScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    yoloEmbedding1024Score: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    overallWeightedFeatureScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    localVsGlobalMotionAngleScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    classTransitionScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    gatingProgress: jspb.Message.getFieldWithDefault(msg, 13, 0),
    candidateBox: (f = msg.getCandidateBox()) && proto.vivacity.core.DetectionBox.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DebugMatchScore}
 */
proto.vivacity.core.DebugMatchScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DebugMatchScore;
  return proto.vivacity.core.DebugMatchScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DebugMatchScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DebugMatchScore}
 */
proto.vivacity.core.DebugMatchScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIouScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLabScore(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpSimpleScore(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpOrientedScore(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpColourOrientedScore(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpFullnessOrientedScore(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLbpStrengthOrientedScore(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHogScore(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setYoloEmbedding1024Score(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOverallWeightedFeatureScore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLocalVsGlobalMotionAngleScore(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClassTransitionScore(value);
      break;
    case 13:
      var value = /** @type {!proto.vivacity.core.DebugMatchScore.GatingProgress} */ (reader.readEnum());
      msg.setGatingProgress(value);
      break;
    case 14:
      var value = new proto.vivacity.core.DetectionBox;
      reader.readMessage(value,proto.vivacity.core.DetectionBox.deserializeBinaryFromReader);
      msg.setCandidateBox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DebugMatchScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DebugMatchScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DebugMatchScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DebugMatchScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIouScore();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLabScore();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLbpSimpleScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLbpOrientedScore();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getLbpColourOrientedScore();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLbpFullnessOrientedScore();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getLbpStrengthOrientedScore();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getHogScore();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getYoloEmbedding1024Score();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getOverallWeightedFeatureScore();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getLocalVsGlobalMotionAngleScore();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getClassTransitionScore();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getGatingProgress();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getCandidateBox();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.vivacity.core.DetectionBox.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.DebugMatchScore.GatingProgress = {
  NOT_GATED: 0,
  IOU: 1,
  IOU_LOOSENED_NOT_YET_VALID: 2,
  FEATURE_HISTOGRAM: 3,
  LOCAL_VS_GLOBAL_MOTION_ANGLE: 4
};

/**
 * optional float iou_score = 1;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getIouScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setIouScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float lab_score = 2;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLabScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLabScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float lbp_simple_score = 3;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpSimpleScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpSimpleScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float lbp_oriented_score = 4;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpOrientedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpOrientedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float lbp_colour_oriented_score = 5;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpColourOrientedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpColourOrientedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float lbp_fullness_oriented_score = 6;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpFullnessOrientedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpFullnessOrientedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float lbp_strength_oriented_score = 7;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLbpStrengthOrientedScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLbpStrengthOrientedScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float hog_score = 8;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getHogScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setHogScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float yolo_embedding_1024_score = 9;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getYoloEmbedding1024Score = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setYoloEmbedding1024Score = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float overall_weighted_feature_score = 10;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getOverallWeightedFeatureScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setOverallWeightedFeatureScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float local_vs_global_motion_angle_score = 11;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getLocalVsGlobalMotionAngleScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setLocalVsGlobalMotionAngleScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float class_transition_score = 12;
 * @return {number}
 */
proto.vivacity.core.DebugMatchScore.prototype.getClassTransitionScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setClassTransitionScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional GatingProgress gating_progress = 13;
 * @return {!proto.vivacity.core.DebugMatchScore.GatingProgress}
 */
proto.vivacity.core.DebugMatchScore.prototype.getGatingProgress = function() {
  return /** @type {!proto.vivacity.core.DebugMatchScore.GatingProgress} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.vivacity.core.DebugMatchScore.GatingProgress} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.setGatingProgress = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional DetectionBox candidate_box = 14;
 * @return {?proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.DebugMatchScore.prototype.getCandidateBox = function() {
  return /** @type{?proto.vivacity.core.DetectionBox} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.DetectionBox, 14));
};


/**
 * @param {?proto.vivacity.core.DetectionBox|undefined} value
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
*/
proto.vivacity.core.DebugMatchScore.prototype.setCandidateBox = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DebugMatchScore} returns this
 */
proto.vivacity.core.DebugMatchScore.prototype.clearCandidateBox = function() {
  return this.setCandidateBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DebugMatchScore.prototype.hasCandidateBox = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.TrackMetadata.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TrackMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TrackMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TrackMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchedScore: (f = msg.getMatchedScore()) && proto.vivacity.core.DebugMatchScore.toObject(includeInstance, f),
    unmatchedScoresList: jspb.Message.toObjectList(msg.getUnmatchedScoresList(),
    proto.vivacity.core.DebugMatchScore.toObject, includeInstance),
    globalMotionDirection: (f = msg.getGlobalMotionDirection()) && vivacity_core_vector_pb.VectorF.toObject(includeInstance, f),
    localMotionDirection: (f = msg.getLocalMotionDirection()) && vivacity_core_vector_pb.VectorF.toObject(includeInstance, f),
    terminationReason: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TrackMetadata}
 */
proto.vivacity.core.TrackMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TrackMetadata;
  return proto.vivacity.core.TrackMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TrackMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TrackMetadata}
 */
proto.vivacity.core.TrackMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.DebugMatchScore;
      reader.readMessage(value,proto.vivacity.core.DebugMatchScore.deserializeBinaryFromReader);
      msg.setMatchedScore(value);
      break;
    case 2:
      var value = new proto.vivacity.core.DebugMatchScore;
      reader.readMessage(value,proto.vivacity.core.DebugMatchScore.deserializeBinaryFromReader);
      msg.addUnmatchedScores(value);
      break;
    case 11:
      var value = new vivacity_core_vector_pb.VectorF;
      reader.readMessage(value,vivacity_core_vector_pb.VectorF.deserializeBinaryFromReader);
      msg.setGlobalMotionDirection(value);
      break;
    case 12:
      var value = new vivacity_core_vector_pb.VectorF;
      reader.readMessage(value,vivacity_core_vector_pb.VectorF.deserializeBinaryFromReader);
      msg.setLocalMotionDirection(value);
      break;
    case 13:
      var value = /** @type {!proto.vivacity.core.TrackMetadata.TrackTerminationReason} */ (reader.readEnum());
      msg.setTerminationReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TrackMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TrackMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TrackMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchedScore();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.DebugMatchScore.serializeBinaryToWriter
    );
  }
  f = message.getUnmatchedScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.vivacity.core.DebugMatchScore.serializeBinaryToWriter
    );
  }
  f = message.getGlobalMotionDirection();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_core_vector_pb.VectorF.serializeBinaryToWriter
    );
  }
  f = message.getLocalMotionDirection();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_core_vector_pb.VectorF.serializeBinaryToWriter
    );
  }
  f = message.getTerminationReason();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.TrackMetadata.TrackTerminationReason = {
  NOT_TERMINATED_YET: 0,
  NOT_YET_VALID: 1,
  PREDICTION_WIDTH_OUT_OF_BOUNDS: 2,
  PREDICTION_HEIGHT_OUT_OF_BOUNDS: 3,
  PREDICTION_X_OUTSIDE_FRAME: 4,
  PREDICTION_Y_OUTSIDE_FRAME: 5,
  NOT_MATCHED_WITHIN_FRAME_LOOKBACK_WINDOW: 6,
  LETHAL_MASK: 7
};

/**
 * optional DebugMatchScore matched_score = 1;
 * @return {?proto.vivacity.core.DebugMatchScore}
 */
proto.vivacity.core.TrackMetadata.prototype.getMatchedScore = function() {
  return /** @type{?proto.vivacity.core.DebugMatchScore} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.DebugMatchScore, 1));
};


/**
 * @param {?proto.vivacity.core.DebugMatchScore|undefined} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setMatchedScore = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearMatchedScore = function() {
  return this.setMatchedScore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackMetadata.prototype.hasMatchedScore = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DebugMatchScore unmatched_scores = 2;
 * @return {!Array<!proto.vivacity.core.DebugMatchScore>}
 */
proto.vivacity.core.TrackMetadata.prototype.getUnmatchedScoresList = function() {
  return /** @type{!Array<!proto.vivacity.core.DebugMatchScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.DebugMatchScore, 2));
};


/**
 * @param {!Array<!proto.vivacity.core.DebugMatchScore>} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setUnmatchedScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.vivacity.core.DebugMatchScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.DebugMatchScore}
 */
proto.vivacity.core.TrackMetadata.prototype.addUnmatchedScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.vivacity.core.DebugMatchScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearUnmatchedScoresList = function() {
  return this.setUnmatchedScoresList([]);
};


/**
 * optional VectorF global_motion_direction = 11;
 * @return {?proto.vivacity.core.VectorF}
 */
proto.vivacity.core.TrackMetadata.prototype.getGlobalMotionDirection = function() {
  return /** @type{?proto.vivacity.core.VectorF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorF, 11));
};


/**
 * @param {?proto.vivacity.core.VectorF|undefined} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setGlobalMotionDirection = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearGlobalMotionDirection = function() {
  return this.setGlobalMotionDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackMetadata.prototype.hasGlobalMotionDirection = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional VectorF local_motion_direction = 12;
 * @return {?proto.vivacity.core.VectorF}
 */
proto.vivacity.core.TrackMetadata.prototype.getLocalMotionDirection = function() {
  return /** @type{?proto.vivacity.core.VectorF} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.VectorF, 12));
};


/**
 * @param {?proto.vivacity.core.VectorF|undefined} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
*/
proto.vivacity.core.TrackMetadata.prototype.setLocalMotionDirection = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.clearLocalMotionDirection = function() {
  return this.setLocalMotionDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackMetadata.prototype.hasLocalMotionDirection = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional TrackTerminationReason termination_reason = 13;
 * @return {!proto.vivacity.core.TrackMetadata.TrackTerminationReason}
 */
proto.vivacity.core.TrackMetadata.prototype.getTerminationReason = function() {
  return /** @type {!proto.vivacity.core.TrackMetadata.TrackTerminationReason} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.vivacity.core.TrackMetadata.TrackTerminationReason} value
 * @return {!proto.vivacity.core.TrackMetadata} returns this
 */
proto.vivacity.core.TrackMetadata.prototype.setTerminationReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.TrackHead.repeatedFields_ = [5,6,18,21,24,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TrackHead.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TrackHead.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TrackHead} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackHead.toObject = function(includeInstance, msg) {
  var f, obj = {
    detectionBox: (f = msg.getDetectionBox()) && proto.vivacity.core.DetectionBox.toObject(includeInstance, f),
    trackNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isPredicted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isTracked: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    lastDetectedTimestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    occupancyZoneIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    countlineCrossingsList: jspb.Message.toObjectList(msg.getCountlineCrossingsList(),
    vivacity_core_countline_crossing_pb.CountlineCrossing.toObject, includeInstance),
    frameTimeMicroseconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    displacement: (f = msg.getDisplacement()) && vivacity_core_vector_pb.Vector.toObject(includeInstance, f),
    isStopped: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    movement: (f = msg.getMovement()) && vivacity_core_movement_pb.Movement.toObject(includeInstance, f),
    trackClass: jspb.Message.getFieldWithDefault(msg, 11, 0),
    metadata: (f = msg.getMetadata()) && proto.vivacity.core.TrackMetadata.toObject(includeInstance, f),
    cumulativeStoppedFrames: jspb.Message.getFieldWithDefault(msg, 14, 0),
    cumulativeStoppedFramesByZoneIdMap: (f = msg.getCumulativeStoppedFramesByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeTotalFrames: jspb.Message.getFieldWithDefault(msg, 16, 0),
    cumulativeTotalFramesByZoneIdMap: (f = msg.getCumulativeTotalFramesByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    cumulativeClassConfidenceList: jspb.Message.toObjectList(msg.getCumulativeClassConfidenceList(),
    vivacity_core_classifying_detector_class_types_pb.ClassConfidence.toObject, includeInstance),
    trackPlate: jspb.Message.getFieldWithDefault(msg, 19, ""),
    trackHashedPlate: jspb.Message.getFieldWithDefault(msg, 20, ""),
    cumulativeAnprConfidenceList: jspb.Message.toObjectList(msg.getCumulativeAnprConfidenceList(),
    vivacity_core_anpr_confidence_pb.AnprConfidence.toObject, includeInstance),
    anprVehicleClass: jspb.Message.getFieldWithDefault(msg, 22, 0),
    anprAssociatedTrackNumber: jspb.Message.getFieldWithDefault(msg, 23, 0),
    directionalOccupancyZoneIdList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    contraDirectionalOccupancyZoneIdList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    firstTimestampMicrosecondsEnteredZoneByZoneIdMap: (f = msg.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap()) ? f.toObject(includeInstance, undefined) : [],
    lastTimestampMicrosecondsLeftZoneByZoneIdMap: (f = msg.getLastTimestampMicrosecondsLeftZoneByZoneIdMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.TrackHead.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TrackHead;
  return proto.vivacity.core.TrackHead.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TrackHead} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.TrackHead.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.DetectionBox;
      reader.readMessage(value,proto.vivacity.core.DetectionBox.deserializeBinaryFromReader);
      msg.setDetectionBox(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPredicted(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTracked(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastDetectedTimestampMicroseconds(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOccupancyZoneId(values[i]);
      }
      break;
    case 6:
      var value = new vivacity_core_countline_crossing_pb.CountlineCrossing;
      reader.readMessage(value,vivacity_core_countline_crossing_pb.CountlineCrossing.deserializeBinaryFromReader);
      msg.addCountlineCrossings(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameTimeMicroseconds(value);
      break;
    case 8:
      var value = new vivacity_core_vector_pb.Vector;
      reader.readMessage(value,vivacity_core_vector_pb.Vector.deserializeBinaryFromReader);
      msg.setDisplacement(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStopped(value);
      break;
    case 10:
      var value = new vivacity_core_movement_pb.Movement;
      reader.readMessage(value,vivacity_core_movement_pb.Movement.deserializeBinaryFromReader);
      msg.setMovement(value);
      break;
    case 11:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setTrackClass(value);
      break;
    case 12:
      var value = new proto.vivacity.core.TrackMetadata;
      reader.readMessage(value,proto.vivacity.core.TrackMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCumulativeStoppedFrames(value);
      break;
    case 15:
      var value = msg.getCumulativeStoppedFramesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCumulativeTotalFrames(value);
      break;
    case 17:
      var value = msg.getCumulativeTotalFramesByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0, 0);
         });
      break;
    case 18:
      var value = new vivacity_core_classifying_detector_class_types_pb.ClassConfidence;
      reader.readMessage(value,vivacity_core_classifying_detector_class_types_pb.ClassConfidence.deserializeBinaryFromReader);
      msg.addCumulativeClassConfidence(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackPlate(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackHashedPlate(value);
      break;
    case 21:
      var value = new vivacity_core_anpr_confidence_pb.AnprConfidence;
      reader.readMessage(value,vivacity_core_anpr_confidence_pb.AnprConfidence.deserializeBinaryFromReader);
      msg.addCumulativeAnprConfidence(value);
      break;
    case 22:
      var value = /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (reader.readEnum());
      msg.setAnprVehicleClass(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAnprAssociatedTrackNumber(value);
      break;
    case 24:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDirectionalOccupancyZoneId(values[i]);
      }
      break;
    case 25:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addContraDirectionalOccupancyZoneId(values[i]);
      }
      break;
    case 26:
      var value = msg.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    case 27:
      var value = msg.getLastTimestampMicrosecondsLeftZoneByZoneIdMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint64, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TrackHead.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TrackHead.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TrackHead} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackHead.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetectionBox();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.core.DetectionBox.serializeBinaryToWriter
    );
  }
  f = message.getTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIsPredicted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsTracked();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLastDetectedTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getOccupancyZoneIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
  f = message.getCountlineCrossingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      vivacity_core_countline_crossing_pb.CountlineCrossing.serializeBinaryToWriter
    );
  }
  f = message.getFrameTimeMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getDisplacement();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_core_vector_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getIsStopped();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMovement();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      vivacity_core_movement_pb.Movement.serializeBinaryToWriter
    );
  }
  f = message.getTrackClass();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.vivacity.core.TrackMetadata.serializeBinaryToWriter
    );
  }
  f = message.getCumulativeStoppedFrames();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getCumulativeStoppedFramesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getCumulativeTotalFrames();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getCumulativeTotalFramesByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(17, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getCumulativeClassConfidenceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      vivacity_core_classifying_detector_class_types_pb.ClassConfidence.serializeBinaryToWriter
    );
  }
  f = message.getTrackPlate();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTrackHashedPlate();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCumulativeAnprConfidenceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      vivacity_core_anpr_confidence_pb.AnprConfidence.serializeBinaryToWriter
    );
  }
  f = message.getAnprVehicleClass();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getAnprAssociatedTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      23,
      f
    );
  }
  f = message.getDirectionalOccupancyZoneIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      24,
      f
    );
  }
  f = message.getContraDirectionalOccupancyZoneIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      25,
      f
    );
  }
  f = message.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(26, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getLastTimestampMicrosecondsLeftZoneByZoneIdMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(27, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint64);
  }
};


/**
 * optional DetectionBox detection_box = 1;
 * @return {?proto.vivacity.core.DetectionBox}
 */
proto.vivacity.core.TrackHead.prototype.getDetectionBox = function() {
  return /** @type{?proto.vivacity.core.DetectionBox} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.DetectionBox, 1));
};


/**
 * @param {?proto.vivacity.core.DetectionBox|undefined} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setDetectionBox = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearDetectionBox = function() {
  return this.setDetectionBox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.hasDetectionBox = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 track_number = 2;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_predicted = 3;
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.getIsPredicted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setIsPredicted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_tracked = 13;
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.getIsTracked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setIsTracked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional uint64 last_detected_timestamp_microseconds = 4;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getLastDetectedTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setLastDetectedTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated uint32 occupancy_zone_id = 5;
 * @return {!Array<number>}
 */
proto.vivacity.core.TrackHead.prototype.getOccupancyZoneIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setOccupancyZoneIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.addOccupancyZoneId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearOccupancyZoneIdList = function() {
  return this.setOccupancyZoneIdList([]);
};


/**
 * repeated CountlineCrossing countline_crossings = 6;
 * @return {!Array<!proto.vivacity.core.CountlineCrossing>}
 */
proto.vivacity.core.TrackHead.prototype.getCountlineCrossingsList = function() {
  return /** @type{!Array<!proto.vivacity.core.CountlineCrossing>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_countline_crossing_pb.CountlineCrossing, 6));
};


/**
 * @param {!Array<!proto.vivacity.core.CountlineCrossing>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setCountlineCrossingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vivacity.core.CountlineCrossing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.CountlineCrossing}
 */
proto.vivacity.core.TrackHead.prototype.addCountlineCrossings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vivacity.core.CountlineCrossing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCountlineCrossingsList = function() {
  return this.setCountlineCrossingsList([]);
};


/**
 * optional uint64 frame_time_microseconds = 7;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getFrameTimeMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setFrameTimeMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Vector displacement = 8;
 * @return {?proto.vivacity.core.Vector}
 */
proto.vivacity.core.TrackHead.prototype.getDisplacement = function() {
  return /** @type{?proto.vivacity.core.Vector} */ (
    jspb.Message.getWrapperField(this, vivacity_core_vector_pb.Vector, 8));
};


/**
 * @param {?proto.vivacity.core.Vector|undefined} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setDisplacement = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearDisplacement = function() {
  return this.setDisplacement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.hasDisplacement = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_stopped = 9;
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.getIsStopped = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setIsStopped = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional Movement movement = 10;
 * @return {?proto.vivacity.core.Movement}
 */
proto.vivacity.core.TrackHead.prototype.getMovement = function() {
  return /** @type{?proto.vivacity.core.Movement} */ (
    jspb.Message.getWrapperField(this, vivacity_core_movement_pb.Movement, 10));
};


/**
 * @param {?proto.vivacity.core.Movement|undefined} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setMovement = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearMovement = function() {
  return this.setMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.hasMovement = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ClassifyingDetectorClassTypes track_class = 11;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.TrackHead.prototype.getTrackClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setTrackClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional TrackMetadata metadata = 12;
 * @return {?proto.vivacity.core.TrackMetadata}
 */
proto.vivacity.core.TrackHead.prototype.getMetadata = function() {
  return /** @type{?proto.vivacity.core.TrackMetadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.TrackMetadata, 12));
};


/**
 * @param {?proto.vivacity.core.TrackMetadata|undefined} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackHead.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint32 cumulative_stopped_frames = 14;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setCumulativeStoppedFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * map<uint32, uint32> cumulative_stopped_frames_by_zone_id = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeStoppedFramesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 15, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeStoppedFramesByZoneIdMap = function() {
  this.getCumulativeStoppedFramesByZoneIdMap().clear();
  return this;
};


/**
 * optional uint32 cumulative_total_frames = 16;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeTotalFrames = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setCumulativeTotalFrames = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * map<uint32, uint32> cumulative_total_frames_by_zone_id = 17;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeTotalFramesByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 17, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeTotalFramesByZoneIdMap = function() {
  this.getCumulativeTotalFramesByZoneIdMap().clear();
  return this;
};


/**
 * repeated ClassConfidence cumulative_class_confidence = 18;
 * @return {!Array<!proto.vivacity.core.ClassConfidence>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeClassConfidenceList = function() {
  return /** @type{!Array<!proto.vivacity.core.ClassConfidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_classifying_detector_class_types_pb.ClassConfidence, 18));
};


/**
 * @param {!Array<!proto.vivacity.core.ClassConfidence>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setCumulativeClassConfidenceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.vivacity.core.ClassConfidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ClassConfidence}
 */
proto.vivacity.core.TrackHead.prototype.addCumulativeClassConfidence = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.vivacity.core.ClassConfidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeClassConfidenceList = function() {
  return this.setCumulativeClassConfidenceList([]);
};


/**
 * optional string track_plate = 19;
 * @return {string}
 */
proto.vivacity.core.TrackHead.prototype.getTrackPlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setTrackPlate = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string track_hashed_plate = 20;
 * @return {string}
 */
proto.vivacity.core.TrackHead.prototype.getTrackHashedPlate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setTrackHashedPlate = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated AnprConfidence cumulative_anpr_confidence = 21;
 * @return {!Array<!proto.vivacity.core.AnprConfidence>}
 */
proto.vivacity.core.TrackHead.prototype.getCumulativeAnprConfidenceList = function() {
  return /** @type{!Array<!proto.vivacity.core.AnprConfidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_anpr_confidence_pb.AnprConfidence, 21));
};


/**
 * @param {!Array<!proto.vivacity.core.AnprConfidence>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
*/
proto.vivacity.core.TrackHead.prototype.setCumulativeAnprConfidenceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.vivacity.core.AnprConfidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AnprConfidence}
 */
proto.vivacity.core.TrackHead.prototype.addCumulativeAnprConfidence = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.vivacity.core.AnprConfidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearCumulativeAnprConfidenceList = function() {
  return this.setCumulativeAnprConfidenceList([]);
};


/**
 * optional ClassifyingDetectorClassTypes anpr_vehicle_class = 22;
 * @return {!proto.vivacity.core.ClassifyingDetectorClassTypes}
 */
proto.vivacity.core.TrackHead.prototype.getAnprVehicleClass = function() {
  return /** @type {!proto.vivacity.core.ClassifyingDetectorClassTypes} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.vivacity.core.ClassifyingDetectorClassTypes} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setAnprVehicleClass = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional uint32 anpr_associated_track_number = 23;
 * @return {number}
 */
proto.vivacity.core.TrackHead.prototype.getAnprAssociatedTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setAnprAssociatedTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * repeated uint32 directional_occupancy_zone_id = 24;
 * @return {!Array<number>}
 */
proto.vivacity.core.TrackHead.prototype.getDirectionalOccupancyZoneIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setDirectionalOccupancyZoneIdList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.addDirectionalOccupancyZoneId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearDirectionalOccupancyZoneIdList = function() {
  return this.setDirectionalOccupancyZoneIdList([]);
};


/**
 * repeated uint32 contra_directional_occupancy_zone_id = 25;
 * @return {!Array<number>}
 */
proto.vivacity.core.TrackHead.prototype.getContraDirectionalOccupancyZoneIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.setContraDirectionalOccupancyZoneIdList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.addContraDirectionalOccupancyZoneId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearContraDirectionalOccupancyZoneIdList = function() {
  return this.setContraDirectionalOccupancyZoneIdList([]);
};


/**
 * map<uint32, uint64> first_timestamp_microseconds_entered_zone_by_zone_id = 26;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 26, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearFirstTimestampMicrosecondsEnteredZoneByZoneIdMap = function() {
  this.getFirstTimestampMicrosecondsEnteredZoneByZoneIdMap().clear();
  return this;
};


/**
 * map<uint32, uint64> last_timestamp_microseconds_left_zone_by_zone_id = 27;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.vivacity.core.TrackHead.prototype.getLastTimestampMicrosecondsLeftZoneByZoneIdMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 27, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.TrackHead} returns this
 */
proto.vivacity.core.TrackHead.prototype.clearLastTimestampMicrosecondsLeftZoneByZoneIdMap = function() {
  this.getLastTimestampMicrosecondsLeftZoneByZoneIdMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.DetectorTrackerFrame.repeatedFields_ = [7,9,10,12,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.DetectorTrackerFrame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.DetectorTrackerFrame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectorTrackerFrame.toObject = function(includeInstance, msg) {
  var f, obj = {
    frameNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    frameTimeMicroseconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    videoId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    videoPath: jspb.Message.getFieldWithDefault(msg, 5, ""),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    trackHeadsList: jspb.Message.toObjectList(msg.getTrackHeadsList(),
    proto.vivacity.core.TrackHead.toObject, includeInstance),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    zoneOrientedFeaturesList: jspb.Message.toObjectList(msg.getZoneOrientedFeaturesList(),
    vivacity_core_zonal_features_pb.ZonalFeatures.toObject, includeInstance),
    trackSegmentsList: jspb.Message.toObjectList(msg.getTrackSegmentsList(),
    proto.vivacity.core.TrackSegment.toObject, includeInstance),
    trackTailsList: jspb.Message.toObjectList(msg.getTrackTailsList(),
    proto.vivacity.core.AssembledTrack.toObject, includeInstance),
    metadata: (f = msg.getMetadata()) && proto.vivacity.core.FrameMetadata.toObject(includeInstance, f),
    trackTerminationsList: jspb.Message.toObjectList(msg.getTrackTerminationsList(),
    proto.vivacity.core.TrackHead.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.DetectorTrackerFrame}
 */
proto.vivacity.core.DetectorTrackerFrame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.DetectorTrackerFrame;
  return proto.vivacity.core.DetectorTrackerFrame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.DetectorTrackerFrame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.DetectorTrackerFrame}
 */
proto.vivacity.core.DetectorTrackerFrame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrameNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameTimeMicroseconds(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.core.DetectorTrackerFrame.VideoID} */ (reader.readEnum());
      msg.setVideoId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVideoPath(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 7:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.addTrackHeads(value);
      break;
    case 8:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 9:
      var value = new vivacity_core_zonal_features_pb.ZonalFeatures;
      reader.readMessage(value,vivacity_core_zonal_features_pb.ZonalFeatures.deserializeBinaryFromReader);
      msg.addZoneOrientedFeatures(value);
      break;
    case 10:
      var value = new proto.vivacity.core.TrackSegment;
      reader.readMessage(value,proto.vivacity.core.TrackSegment.deserializeBinaryFromReader);
      msg.addTrackSegments(value);
      break;
    case 12:
      var value = new proto.vivacity.core.AssembledTrack;
      reader.readMessage(value,proto.vivacity.core.AssembledTrack.deserializeBinaryFromReader);
      msg.addTrackTails(value);
      break;
    case 13:
      var value = new proto.vivacity.core.FrameMetadata;
      reader.readMessage(value,proto.vivacity.core.FrameMetadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    case 15:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.addTrackTerminations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.DetectorTrackerFrame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.DetectorTrackerFrame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.DetectorTrackerFrame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFrameTimeMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getVideoId();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVideoPath();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTrackHeadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getZoneOrientedFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      vivacity_core_zonal_features_pb.ZonalFeatures.serializeBinaryToWriter
    );
  }
  f = message.getTrackSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.vivacity.core.TrackSegment.serializeBinaryToWriter
    );
  }
  f = message.getTrackTailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.vivacity.core.AssembledTrack.serializeBinaryToWriter
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.vivacity.core.FrameMetadata.serializeBinaryToWriter
    );
  }
  f = message.getTrackTerminationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.core.DetectorTrackerFrame.VideoID = {
  UNKNOWN_VIDEO_ID: 0,
  CAM0_MJPEG_YUYV: 1,
  CAM1_MJPEG_YUYV: 2,
  FILE: 3
};

/**
 * optional uint32 frame_number = 1;
 * @return {number}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 restart_number = 2;
 * @return {number}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 frame_time_microseconds = 3;
 * @return {number}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getFrameTimeMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setFrameTimeMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional VideoID video_id = 4;
 * @return {!proto.vivacity.core.DetectorTrackerFrame.VideoID}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getVideoId = function() {
  return /** @type {!proto.vivacity.core.DetectorTrackerFrame.VideoID} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.core.DetectorTrackerFrame.VideoID} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setVideoId = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string video_path = 5;
 * @return {string}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getVideoPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setVideoPath = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 vision_program_id = 6;
 * @return {number}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated TrackHead track_heads = 7;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTrackHeadsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.TrackHead, 7));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTrackHeadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addTrackHeads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTrackHeadsList = function() {
  return this.setTrackHeadsList([]);
};


/**
 * optional TracingContext tracing_ctx = 8;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 8));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated ZonalFeatures zone_oriented_features = 9;
 * @return {!Array<!proto.vivacity.core.ZonalFeatures>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getZoneOrientedFeaturesList = function() {
  return /** @type{!Array<!proto.vivacity.core.ZonalFeatures>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_zonal_features_pb.ZonalFeatures, 9));
};


/**
 * @param {!Array<!proto.vivacity.core.ZonalFeatures>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setZoneOrientedFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.vivacity.core.ZonalFeatures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.ZonalFeatures}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addZoneOrientedFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.vivacity.core.ZonalFeatures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearZoneOrientedFeaturesList = function() {
  return this.setZoneOrientedFeaturesList([]);
};


/**
 * repeated TrackSegment track_segments = 10;
 * @return {!Array<!proto.vivacity.core.TrackSegment>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTrackSegmentsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.TrackSegment, 10));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackSegment>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTrackSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.vivacity.core.TrackSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackSegment}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addTrackSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.vivacity.core.TrackSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTrackSegmentsList = function() {
  return this.setTrackSegmentsList([]);
};


/**
 * repeated AssembledTrack track_tails = 12;
 * @return {!Array<!proto.vivacity.core.AssembledTrack>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTrackTailsList = function() {
  return /** @type{!Array<!proto.vivacity.core.AssembledTrack>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.AssembledTrack, 12));
};


/**
 * @param {!Array<!proto.vivacity.core.AssembledTrack>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTrackTailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.vivacity.core.AssembledTrack=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AssembledTrack}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addTrackTails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.vivacity.core.AssembledTrack, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTrackTailsList = function() {
  return this.setTrackTailsList([]);
};


/**
 * optional FrameMetadata metadata = 13;
 * @return {?proto.vivacity.core.FrameMetadata}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getMetadata = function() {
  return /** @type{?proto.vivacity.core.FrameMetadata} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.FrameMetadata, 13));
};


/**
 * @param {?proto.vivacity.core.FrameMetadata|undefined} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated TrackHead track_terminations = 15;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.getTrackTerminationsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.TrackHead, 15));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
*/
proto.vivacity.core.DetectorTrackerFrame.prototype.setTrackTerminationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.addTrackTerminations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.DetectorTrackerFrame} returns this
 */
proto.vivacity.core.DetectorTrackerFrame.prototype.clearTrackTerminationsList = function() {
  return this.setTrackTerminationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.FrameMetadata.repeatedFields_ = [1,3,7,9,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.FrameMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.FrameMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.FrameMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FrameMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    outputTensorsList: jspb.Message.toObjectList(msg.getOutputTensorsList(),
    vivacity_core_tensor_pb.Tensor.toObject, includeInstance),
    opticalFlow: (f = msg.getOpticalFlow()) && vivacity_core_tensor_pb.Tensor.toObject(includeInstance, f),
    displayMetaList: jspb.Message.toObjectList(msg.getDisplayMetaList(),
    vivacity_core_nvosd_params_pb.NvDsDisplayMeta.toObject, includeInstance),
    height: jspb.Message.getFieldWithDefault(msg, 4, 0),
    width: jspb.Message.getFieldWithDefault(msg, 5, 0),
    predictionsMap: (f = msg.getPredictionsMap()) ? f.toObject(includeInstance, proto.vivacity.core.TrackHead.toObject) : [],
    deletedDuplicateDetectionsList: jspb.Message.toObjectList(msg.getDeletedDuplicateDetectionsList(),
    proto.vivacity.core.TrackHead.toObject, includeInstance),
    totalIouMatchScore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lowConfidenceDetectionsList: jspb.Message.toObjectList(msg.getLowConfidenceDetectionsList(),
    proto.vivacity.core.TrackHead.toObject, includeInstance),
    trackIdCounter: jspb.Message.getFieldWithDefault(msg, 10, 0),
    totalTrackLength: jspb.Message.getFieldWithDefault(msg, 11, 0),
    deletedMaskedTrackHeadsList: jspb.Message.toObjectList(msg.getDeletedMaskedTrackHeadsList(),
    proto.vivacity.core.TrackHead.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.FrameMetadata}
 */
proto.vivacity.core.FrameMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.FrameMetadata;
  return proto.vivacity.core.FrameMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.FrameMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.FrameMetadata}
 */
proto.vivacity.core.FrameMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.addOutputTensors(value);
      break;
    case 2:
      var value = new vivacity_core_tensor_pb.Tensor;
      reader.readMessage(value,vivacity_core_tensor_pb.Tensor.deserializeBinaryFromReader);
      msg.setOpticalFlow(value);
      break;
    case 3:
      var value = new vivacity_core_nvosd_params_pb.NvDsDisplayMeta;
      reader.readMessage(value,vivacity_core_nvosd_params_pb.NvDsDisplayMeta.deserializeBinaryFromReader);
      msg.addDisplayMeta(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 6:
      var value = msg.getPredictionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.vivacity.core.TrackHead.deserializeBinaryFromReader, 0, new proto.vivacity.core.TrackHead());
         });
      break;
    case 7:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.addDeletedDuplicateDetections(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalIouMatchScore(value);
      break;
    case 9:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.addLowConfidenceDetections(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrackIdCounter(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalTrackLength(value);
      break;
    case 12:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.addDeletedMaskedTrackHeads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.FrameMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.FrameMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.FrameMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FrameMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutputTensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getOpticalFlow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      vivacity_core_tensor_pb.Tensor.serializeBinaryToWriter
    );
  }
  f = message.getDisplayMetaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      vivacity_core_nvosd_params_pb.NvDsDisplayMeta.serializeBinaryToWriter
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPredictionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.vivacity.core.TrackHead.serializeBinaryToWriter);
  }
  f = message.getDeletedDuplicateDetectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTotalIouMatchScore();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLowConfidenceDetectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTrackIdCounter();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTotalTrackLength();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getDeletedMaskedTrackHeadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tensor output_tensors = 1;
 * @return {!Array<!proto.vivacity.core.Tensor>}
 */
proto.vivacity.core.FrameMetadata.prototype.getOutputTensorsList = function() {
  return /** @type{!Array<!proto.vivacity.core.Tensor>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_tensor_pb.Tensor, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.Tensor>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setOutputTensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.Tensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.Tensor}
 */
proto.vivacity.core.FrameMetadata.prototype.addOutputTensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.Tensor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearOutputTensorsList = function() {
  return this.setOutputTensorsList([]);
};


/**
 * optional Tensor optical_flow = 2;
 * @return {?proto.vivacity.core.Tensor}
 */
proto.vivacity.core.FrameMetadata.prototype.getOpticalFlow = function() {
  return /** @type{?proto.vivacity.core.Tensor} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tensor_pb.Tensor, 2));
};


/**
 * @param {?proto.vivacity.core.Tensor|undefined} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setOpticalFlow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearOpticalFlow = function() {
  return this.setOpticalFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FrameMetadata.prototype.hasOpticalFlow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated NvDsDisplayMeta display_meta = 3;
 * @return {!Array<!proto.vivacity.core.NvDsDisplayMeta>}
 */
proto.vivacity.core.FrameMetadata.prototype.getDisplayMetaList = function() {
  return /** @type{!Array<!proto.vivacity.core.NvDsDisplayMeta>} */ (
    jspb.Message.getRepeatedWrapperField(this, vivacity_core_nvosd_params_pb.NvDsDisplayMeta, 3));
};


/**
 * @param {!Array<!proto.vivacity.core.NvDsDisplayMeta>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setDisplayMetaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.core.NvDsDisplayMeta=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.NvDsDisplayMeta}
 */
proto.vivacity.core.FrameMetadata.prototype.addDisplayMeta = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.core.NvDsDisplayMeta, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearDisplayMetaList = function() {
  return this.setDisplayMetaList([]);
};


/**
 * optional int32 height = 4;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 width = 5;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * map<uint32, TrackHead> predictions = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.FrameMetadata.prototype.getPredictionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.vivacity.core.TrackHead>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.vivacity.core.TrackHead));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearPredictionsMap = function() {
  this.getPredictionsMap().clear();
  return this;
};


/**
 * repeated TrackHead deleted_duplicate_detections = 7;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.FrameMetadata.prototype.getDeletedDuplicateDetectionsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.TrackHead, 7));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setDeletedDuplicateDetectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.FrameMetadata.prototype.addDeletedDuplicateDetections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearDeletedDuplicateDetectionsList = function() {
  return this.setDeletedDuplicateDetectionsList([]);
};


/**
 * optional float total_iou_match_score = 8;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getTotalIouMatchScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setTotalIouMatchScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * repeated TrackHead low_confidence_detections = 9;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.FrameMetadata.prototype.getLowConfidenceDetectionsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.TrackHead, 9));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setLowConfidenceDetectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.FrameMetadata.prototype.addLowConfidenceDetections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearLowConfidenceDetectionsList = function() {
  return this.setLowConfidenceDetectionsList([]);
};


/**
 * optional int32 track_id_counter = 10;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getTrackIdCounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setTrackIdCounter = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 total_track_length = 11;
 * @return {number}
 */
proto.vivacity.core.FrameMetadata.prototype.getTotalTrackLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.setTotalTrackLength = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated TrackHead deleted_masked_track_heads = 12;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.FrameMetadata.prototype.getDeletedMaskedTrackHeadsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.TrackHead, 12));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.FrameMetadata} returns this
*/
proto.vivacity.core.FrameMetadata.prototype.setDeletedMaskedTrackHeadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.FrameMetadata.prototype.addDeletedMaskedTrackHeads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.FrameMetadata} returns this
 */
proto.vivacity.core.FrameMetadata.prototype.clearDeletedMaskedTrackHeadsList = function() {
  return this.setDeletedMaskedTrackHeadsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.FragmentedDetectorTrackerFrame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.FragmentedDetectorTrackerFrame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.toObject = function(includeInstance, msg) {
  var f, obj = {
    fragmentNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fragmentTotal: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalTrackHeads: jspb.Message.getFieldWithDefault(msg, 3, 0),
    detectorTrackerFrameFragment: (f = msg.getDetectorTrackerFrameFragment()) && proto.vivacity.core.DetectorTrackerFrame.toObject(includeInstance, f),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.FragmentedDetectorTrackerFrame;
  return proto.vivacity.core.FragmentedDetectorTrackerFrame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.FragmentedDetectorTrackerFrame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFragmentNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFragmentTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalTrackHeads(value);
      break;
    case 4:
      var value = new proto.vivacity.core.DetectorTrackerFrame;
      reader.readMessage(value,proto.vivacity.core.DetectorTrackerFrame.deserializeBinaryFromReader);
      msg.setDetectorTrackerFrameFragment(value);
      break;
    case 5:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.FragmentedDetectorTrackerFrame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.FragmentedDetectorTrackerFrame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFragmentNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFragmentTotal();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotalTrackHeads();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDetectorTrackerFrameFragment();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.vivacity.core.DetectorTrackerFrame.serializeBinaryToWriter
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 fragment_number = 1;
 * @return {number}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getFragmentNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setFragmentNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 fragment_total = 2;
 * @return {number}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getFragmentTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setFragmentTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total_track_heads = 3;
 * @return {number}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getTotalTrackHeads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setTotalTrackHeads = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional DetectorTrackerFrame detector_tracker_frame_fragment = 4;
 * @return {?proto.vivacity.core.DetectorTrackerFrame}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getDetectorTrackerFrameFragment = function() {
  return /** @type{?proto.vivacity.core.DetectorTrackerFrame} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.DetectorTrackerFrame, 4));
};


/**
 * @param {?proto.vivacity.core.DetectorTrackerFrame|undefined} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
*/
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setDetectorTrackerFrameFragment = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.clearDetectorTrackerFrameFragment = function() {
  return this.setDetectorTrackerFrameFragment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.hasDetectorTrackerFrameFragment = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TracingContext tracing_ctx = 5;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 5));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
*/
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.FragmentedDetectorTrackerFrame} returns this
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.FragmentedDetectorTrackerFrame.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.AssembledTrack.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AssembledTrack.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AssembledTrack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AssembledTrack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AssembledTrack.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackHeadsList: jspb.Message.toObjectList(msg.getTrackHeadsList(),
    proto.vivacity.core.TrackHead.toObject, includeInstance),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 4, 0),
    turningMovementZonesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AssembledTrack}
 */
proto.vivacity.core.AssembledTrack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AssembledTrack;
  return proto.vivacity.core.AssembledTrack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AssembledTrack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AssembledTrack}
 */
proto.vivacity.core.AssembledTrack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.addTrackHeads(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 3:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTurningMovementZones(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AssembledTrack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AssembledTrack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AssembledTrack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AssembledTrack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackHeadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTurningMovementZonesList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
};


/**
 * repeated TrackHead track_heads = 1;
 * @return {!Array<!proto.vivacity.core.TrackHead>}
 */
proto.vivacity.core.AssembledTrack.prototype.getTrackHeadsList = function() {
  return /** @type{!Array<!proto.vivacity.core.TrackHead>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.TrackHead, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.TrackHead>} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
*/
proto.vivacity.core.AssembledTrack.prototype.setTrackHeadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.TrackHead=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.AssembledTrack.prototype.addTrackHeads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.TrackHead, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.clearTrackHeadsList = function() {
  return this.setTrackHeadsList([]);
};


/**
 * optional uint32 vision_program_id = 2;
 * @return {number}
 */
proto.vivacity.core.AssembledTrack.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TracingContext tracing_ctx = 3;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.AssembledTrack.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 3));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
*/
proto.vivacity.core.AssembledTrack.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.AssembledTrack.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 restart_number = 4;
 * @return {number}
 */
proto.vivacity.core.AssembledTrack.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated uint32 turning_movement_zones = 5;
 * @return {!Array<number>}
 */
proto.vivacity.core.AssembledTrack.prototype.getTurningMovementZonesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.setTurningMovementZonesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.addTurningMovementZones = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AssembledTrack} returns this
 */
proto.vivacity.core.AssembledTrack.prototype.clearTurningMovementZonesList = function() {
  return this.setTurningMovementZonesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.AssembledTrackBatch.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.AssembledTrackBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.AssembledTrackBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.AssembledTrackBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AssembledTrackBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    assembledTracksList: jspb.Message.toObjectList(msg.getAssembledTracksList(),
    proto.vivacity.core.AssembledTrack.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.AssembledTrackBatch}
 */
proto.vivacity.core.AssembledTrackBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.AssembledTrackBatch;
  return proto.vivacity.core.AssembledTrackBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.AssembledTrackBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.AssembledTrackBatch}
 */
proto.vivacity.core.AssembledTrackBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.core.AssembledTrack;
      reader.readMessage(value,proto.vivacity.core.AssembledTrack.deserializeBinaryFromReader);
      msg.addAssembledTracks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.AssembledTrackBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.AssembledTrackBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.AssembledTrackBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.AssembledTrackBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssembledTracksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.vivacity.core.AssembledTrack.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssembledTrack assembled_tracks = 1;
 * @return {!Array<!proto.vivacity.core.AssembledTrack>}
 */
proto.vivacity.core.AssembledTrackBatch.prototype.getAssembledTracksList = function() {
  return /** @type{!Array<!proto.vivacity.core.AssembledTrack>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.core.AssembledTrack, 1));
};


/**
 * @param {!Array<!proto.vivacity.core.AssembledTrack>} value
 * @return {!proto.vivacity.core.AssembledTrackBatch} returns this
*/
proto.vivacity.core.AssembledTrackBatch.prototype.setAssembledTracksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.vivacity.core.AssembledTrack=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.AssembledTrack}
 */
proto.vivacity.core.AssembledTrackBatch.prototype.addAssembledTracks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.vivacity.core.AssembledTrack, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.AssembledTrackBatch} returns this
 */
proto.vivacity.core.AssembledTrackBatch.prototype.clearAssembledTracksList = function() {
  return this.setAssembledTracksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.TrackSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.TrackSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.TrackSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    trackNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    trackHeadStart: (f = msg.getTrackHeadStart()) && proto.vivacity.core.TrackHead.toObject(includeInstance, f),
    trackHeadEnd: (f = msg.getTrackHeadEnd()) && proto.vivacity.core.TrackHead.toObject(includeInstance, f),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    restartNumber: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.TrackSegment}
 */
proto.vivacity.core.TrackSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.TrackSegment;
  return proto.vivacity.core.TrackSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.TrackSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.TrackSegment}
 */
proto.vivacity.core.TrackSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrackNumber(value);
      break;
    case 2:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.setTrackHeadStart(value);
      break;
    case 3:
      var value = new proto.vivacity.core.TrackHead;
      reader.readMessage(value,proto.vivacity.core.TrackHead.deserializeBinaryFromReader);
      msg.setTrackHeadEnd(value);
      break;
    case 4:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.TrackSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.TrackSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.TrackSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.TrackSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTrackNumber();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getTrackHeadStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTrackHeadEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.vivacity.core.TrackHead.serializeBinaryToWriter
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.core.TrackSegment.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 track_number = 9;
 * @return {number}
 */
proto.vivacity.core.TrackSegment.prototype.getTrackNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.setTrackNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional TrackHead track_head_start = 2;
 * @return {?proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.TrackSegment.prototype.getTrackHeadStart = function() {
  return /** @type{?proto.vivacity.core.TrackHead} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.TrackHead, 2));
};


/**
 * @param {?proto.vivacity.core.TrackHead|undefined} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
*/
proto.vivacity.core.TrackSegment.prototype.setTrackHeadStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.clearTrackHeadStart = function() {
  return this.setTrackHeadStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackSegment.prototype.hasTrackHeadStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TrackHead track_head_end = 3;
 * @return {?proto.vivacity.core.TrackHead}
 */
proto.vivacity.core.TrackSegment.prototype.getTrackHeadEnd = function() {
  return /** @type{?proto.vivacity.core.TrackHead} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.core.TrackHead, 3));
};


/**
 * @param {?proto.vivacity.core.TrackHead|undefined} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
*/
proto.vivacity.core.TrackSegment.prototype.setTrackHeadEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.clearTrackHeadEnd = function() {
  return this.setTrackHeadEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackSegment.prototype.hasTrackHeadEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TracingContext tracing_ctx = 4;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.core.TrackSegment.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 4));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
*/
proto.vivacity.core.TrackSegment.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.core.TrackSegment.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 restart_number = 5;
 * @return {number}
 */
proto.vivacity.core.TrackSegment.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.TrackSegment} returns this
 */
proto.vivacity.core.TrackSegment.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.core.BrokenTrackStitchingMessage.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.core.BrokenTrackStitchingMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.core.BrokenTrackStitchingMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.BrokenTrackStitchingMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    restartNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    linkedTrackNumbersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.core.BrokenTrackStitchingMessage;
  return proto.vivacity.core.BrokenTrackStitchingMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.core.BrokenTrackStitchingMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRestartNumber(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLinkedTrackNumbers(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.core.BrokenTrackStitchingMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.core.BrokenTrackStitchingMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.core.BrokenTrackStitchingMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRestartNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLinkedTrackNumbersList();
  if (f.length > 0) {
    writer.writePackedUint32(
      2,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 restart_number = 1;
 * @return {number}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.getRestartNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.setRestartNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated uint32 linked_track_numbers = 2;
 * @return {!Array<number>}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.getLinkedTrackNumbersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.setLinkedTrackNumbersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.addLinkedTrackNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.clearLinkedTrackNumbersList = function() {
  return this.setLinkedTrackNumbersList([]);
};


/**
 * optional uint32 vision_program_id = 3;
 * @return {number}
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.core.BrokenTrackStitchingMessage} returns this
 */
proto.vivacity.core.BrokenTrackStitchingMessage.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.vivacity.core);
