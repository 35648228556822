/* eslint-disable */
// source: vivacity/config/vision_program_modification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.vivacity.config.VisionProgramFields', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramFields.OperationType', null, global);
goog.exportSymbol('proto.vivacity.config.VisionProgramToHardware', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.VisionProgramFields.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.VisionProgramFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramFields.displayName = 'proto.vivacity.config.VisionProgramFields';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.VisionProgramToHardware = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.VisionProgramToHardware, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.VisionProgramToHardware.displayName = 'proto.vivacity.config.VisionProgramToHardware';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.VisionProgramFields.repeatedFields_ = [19,27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramFields.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    extraValuesJson: jspb.Message.getFieldWithDefault(msg, 3, ""),
    supermarioHttpPort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    supermarioImage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    visionProgramImage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cameraNumberIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    cameraNumber: jspb.Message.getFieldWithDefault(msg, 8, 0),
    kafkaProducerEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    kafkaProducerEnable: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    crossingsEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    crossingsEnable: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    zonalSpeedInstantaneousEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    zonalSpeedInstantaneousEnable: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    zonalOccupancyInstantaneousEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    zonalOccupancyInstantaneousEnable: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    protoclearEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    protoclearEnable: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    protoclearFieldListList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    udpEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    udpEnable: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    udpProtodumpEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    udpProtodumpEnable: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    udpForceRedialEnableIsPopulated: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    udpForceRedialEnable: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    udpForceRedialInterval: jspb.Message.getFieldWithDefault(msg, 26, 0),
    udpDestinationsList: (f = jspb.Message.getRepeatedField(msg, 27)) == null ? undefined : f,
    automaticityConfigs: jspb.Message.getFieldWithDefault(msg, 28, ""),
    extraValuesJsonMerge: jspb.Message.getFieldWithDefault(msg, 29, ""),
    extraValuesJsonUnsafeMerge: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    automaticityConfigsMerge: jspb.Message.getFieldWithDefault(msg, 31, ""),
    automaticityConfigsUnsafeMerge: jspb.Message.getBooleanFieldWithDefault(msg, 32, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramFields}
 */
proto.vivacity.config.VisionProgramFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramFields;
  return proto.vivacity.config.VisionProgramFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramFields}
 */
proto.vivacity.config.VisionProgramFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.VisionProgramFields.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJson(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSupermarioHttpPort(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupermarioImage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVisionProgramImage(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCameraNumberIsPopulated(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCameraNumber(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKafkaProducerEnableIsPopulated(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKafkaProducerEnable(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCrossingsEnableIsPopulated(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCrossingsEnable(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalSpeedInstantaneousEnableIsPopulated(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalSpeedInstantaneousEnable(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalOccupancyInstantaneousEnableIsPopulated(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZonalOccupancyInstantaneousEnable(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProtoclearEnableIsPopulated(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProtoclearEnable(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addProtoclearFieldList(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpEnableIsPopulated(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpEnable(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpProtodumpEnableIsPopulated(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpProtodumpEnable(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpForceRedialEnableIsPopulated(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUdpForceRedialEnable(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUdpForceRedialInterval(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.addUdpDestinations(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomaticityConfigs(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtraValuesJsonMerge(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExtraValuesJsonUnsafeMerge(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomaticityConfigsMerge(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomaticityConfigsUnsafeMerge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getExtraValuesJson();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupermarioHttpPort();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSupermarioImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVisionProgramImage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCameraNumberIsPopulated();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCameraNumber();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getKafkaProducerEnableIsPopulated();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getKafkaProducerEnable();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCrossingsEnableIsPopulated();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCrossingsEnable();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getZonalSpeedInstantaneousEnableIsPopulated();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getZonalSpeedInstantaneousEnable();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getZonalOccupancyInstantaneousEnableIsPopulated();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getZonalOccupancyInstantaneousEnable();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getProtoclearEnableIsPopulated();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getProtoclearEnable();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getProtoclearFieldListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getUdpEnableIsPopulated();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getUdpEnable();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getUdpProtodumpEnableIsPopulated();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getUdpProtodumpEnable();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getUdpForceRedialEnableIsPopulated();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getUdpForceRedialEnable();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getUdpForceRedialInterval();
  if (f !== 0) {
    writer.writeUint32(
      26,
      f
    );
  }
  f = message.getUdpDestinationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      27,
      f
    );
  }
  f = message.getAutomaticityConfigs();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getExtraValuesJsonMerge();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getExtraValuesJsonUnsafeMerge();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getAutomaticityConfigsMerge();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getAutomaticityConfigsUnsafeMerge();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.VisionProgramFields.OperationType = {
  UNKNOWN: 0,
  SET: 1,
  CLEAR_ZERO: 2,
  CLEAR_NON_ZERO: 3
};

/**
 * optional OperationType operation_type = 1;
 * @return {!proto.vivacity.config.VisionProgramFields.OperationType}
 */
proto.vivacity.config.VisionProgramFields.prototype.getOperationType = function() {
  return /** @type {!proto.vivacity.config.VisionProgramFields.OperationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.VisionProgramFields.OperationType} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 vision_program_id = 2;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string extra_values_json = 3;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getExtraValuesJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setExtraValuesJson = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 supermario_http_port = 4;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getSupermarioHttpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setSupermarioHttpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string supermario_image = 5;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getSupermarioImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setSupermarioImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string vision_program_image = 6;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getVisionProgramImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setVisionProgramImage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool camera_number_is_populated = 7;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getCameraNumberIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setCameraNumberIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional uint32 camera_number = 8;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getCameraNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setCameraNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool kafka_producer_enable_is_populated = 9;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getKafkaProducerEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setKafkaProducerEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool kafka_producer_enable = 10;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getKafkaProducerEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setKafkaProducerEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool crossings_enable_is_populated = 11;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getCrossingsEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setCrossingsEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool crossings_enable = 12;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getCrossingsEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setCrossingsEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool zonal_speed_instantaneous_enable_is_populated = 13;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalSpeedInstantaneousEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalSpeedInstantaneousEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool zonal_speed_instantaneous_enable = 14;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalSpeedInstantaneousEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalSpeedInstantaneousEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool zonal_occupancy_instantaneous_enable_is_populated = 15;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalOccupancyInstantaneousEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalOccupancyInstantaneousEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool zonal_occupancy_instantaneous_enable = 16;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getZonalOccupancyInstantaneousEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setZonalOccupancyInstantaneousEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool protoclear_enable_is_populated = 17;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getProtoclearEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setProtoclearEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool protoclear_enable = 18;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getProtoclearEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setProtoclearEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * repeated string protoclear_field_list = 19;
 * @return {!Array<string>}
 */
proto.vivacity.config.VisionProgramFields.prototype.getProtoclearFieldListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setProtoclearFieldListList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.addProtoclearFieldList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.clearProtoclearFieldListList = function() {
  return this.setProtoclearFieldListList([]);
};


/**
 * optional bool udp_enable_is_populated = 20;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool udp_enable = 21;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool udp_protodump_enable_is_populated = 22;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpProtodumpEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpProtodumpEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool udp_protodump_enable = 23;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpProtodumpEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpProtodumpEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool udp_force_redial_enable_is_populated = 24;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpForceRedialEnableIsPopulated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpForceRedialEnableIsPopulated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool udp_force_redial_enable = 25;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpForceRedialEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpForceRedialEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional uint32 udp_force_redial_interval = 26;
 * @return {number}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpForceRedialInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpForceRedialInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * repeated string udp_destinations = 27;
 * @return {!Array<string>}
 */
proto.vivacity.config.VisionProgramFields.prototype.getUdpDestinationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 27));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setUdpDestinationsList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.addUdpDestinations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.clearUdpDestinationsList = function() {
  return this.setUdpDestinationsList([]);
};


/**
 * optional string automaticity_configs = 28;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getAutomaticityConfigs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setAutomaticityConfigs = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string extra_values_json_merge = 29;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getExtraValuesJsonMerge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setExtraValuesJsonMerge = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional bool extra_values_json_unsafe_merge = 30;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getExtraValuesJsonUnsafeMerge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setExtraValuesJsonUnsafeMerge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional string automaticity_configs_merge = 31;
 * @return {string}
 */
proto.vivacity.config.VisionProgramFields.prototype.getAutomaticityConfigsMerge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setAutomaticityConfigsMerge = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional bool automaticity_configs_unsafe_merge = 32;
 * @return {boolean}
 */
proto.vivacity.config.VisionProgramFields.prototype.getAutomaticityConfigsUnsafeMerge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.VisionProgramFields} returns this
 */
proto.vivacity.config.VisionProgramFields.prototype.setAutomaticityConfigsUnsafeMerge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.VisionProgramToHardware.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.VisionProgramToHardware.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.VisionProgramToHardware} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramToHardware.toObject = function(includeInstance, msg) {
  var f, obj = {
    visionProgramId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.VisionProgramToHardware.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.VisionProgramToHardware;
  return proto.vivacity.config.VisionProgramToHardware.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.VisionProgramToHardware} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.VisionProgramToHardware.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.VisionProgramToHardware.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.VisionProgramToHardware.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.VisionProgramToHardware} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.VisionProgramToHardware.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVisionProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 vision_program_id = 1;
 * @return {number}
 */
proto.vivacity.config.VisionProgramToHardware.prototype.getVisionProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramToHardware} returns this
 */
proto.vivacity.config.VisionProgramToHardware.prototype.setVisionProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 hardware_id = 2;
 * @return {number}
 */
proto.vivacity.config.VisionProgramToHardware.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.VisionProgramToHardware} returns this
 */
proto.vivacity.config.VisionProgramToHardware.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.vivacity.config);
