import {action, observable} from 'mobx';
import {DetectionBox, DetectorTrackerFrame, TrackHead, TrackSegment} from "../vivacity/core/detector_tracker_frame_pb";
import WebRTCPeerConnectionState from "./WebRTCPeerConnectionState";
import {PeerID} from "../components/common/types";
import {UniversalEnvelope} from "../vivacity/universal_envelope_pb";
import base64ArrayBuffer from "../utils/base64";
import {VisionProgramZonesConfig, ZonesConfigChange} from "../vivacity/config/zone_config_pb";
import {averageAndScalePoints} from "../utils/geometry";
import * as jspb from "google-protobuf";
import {Point} from "../vivacity/core/point_pb";
import {WebRTCDataChannelDataChunk} from "../vivacity/core/webrtc_peer_connector_pb";
import transposeMap from "../utils/proto-enum-helper";
import {AlertMessage} from "../vivacity/core/alerts_pb";
import {ZonalFeatures} from "../vivacity/core/zonal_features_pb";

type VisionProgramID = number;
type ZonesID = number;
type AlertZoneID = number;
type AlertName = string;

type DataChannel = {
    channel: RTCDataChannel
    chunkSize: number
    pendingChunks: {
        [key: number]: {
            [key: number]: ArrayBuffer
        }
    }

}

const alertTypesMap = transposeMap(AlertMessage.AlertType);

const alertNamesMap = transposeMap(AlertMessage.AlertName);

class DetectorTrackerFrameStore {
    private peerConnState: WebRTCPeerConnectionState;

    xScale = 640 / 16383;
    yScale = 360 / 16383;

    @observable compactMode: boolean = true;

    @observable showControls: boolean = false;

    @observable visionProgramIDsByPeerID: Map<PeerID, number> = new Map<PeerID, number>();

    @observable peerIDsByVisionProgramID: Map<number, PeerID> = new Map<number, PeerID>();

    @observable detectorTrackerChannels: Map<PeerID, RTCDataChannel> = new Map<PeerID, RTCDataChannel>();

    @observable imageSnapshotChannels: Map<PeerID, RTCDataChannel> = new Map<PeerID, RTCDataChannel>();

    @observable latestSnapshots: Map<PeerID, HTMLImageElement> = new Map<PeerID, HTMLImageElement>();

    @observable latestFrames: Map<VisionProgramID, DetectorTrackerFrame> = new Map<VisionProgramID, DetectorTrackerFrame>();

    @observable latestFPS: Map<VisionProgramID, number> = new Map<VisionProgramID, number>();

    @observable latestFrameLatencies: Map<VisionProgramID, number[]> = new Map<VisionProgramID, number[]>();

    @observable latestFrameAvgLatencies: Map<VisionProgramID, number> = new Map<VisionProgramID, number>();

    @observable latestFrameJitter: Map<VisionProgramID, number> = new Map<VisionProgramID, number>();

    @observable latestTrackHeads: Map<VisionProgramID, Array<TrackHead>> = new Map<ZonesID, Array<TrackHead>>();

    @observable latestTrackSegments: Map<VisionProgramID, Array<TrackSegment>> = new Map<ZonesID, Array<TrackSegment>>();

    @observable latestOccupanciesByZoneID: Map<ZonesID, number> = new Map<ZonesID, number>();
    @observable latestAverageDistanceScalarByZoneID: Map<ZonesID, number> = new Map<ZonesID, number>();
    @observable latestStoppedVehiclesByZoneID: Map<ZonesID, number> = new Map<ZonesID, number>();
    @observable latestCrossingsClockwiseByZoneID: Map<ZonesID, number> = new Map<ZonesID, number>();
    @observable latestCrossingsAnticlockwiseByZoneID: Map<ZonesID, number> = new Map<ZonesID, number>();

    @observable occupancyZoneDefinitions: Map<VisionProgramID, Map<ZonesID, number[]>> = new Map<VisionProgramID, Map<ZonesID, number[]>>();

    @observable occupancyZoneCentroids: Map<VisionProgramID, Map<ZonesID, [number, number]>> = new Map<VisionProgramID, Map<ZonesID, [number, number]>>();

    @observable zonalAlerts: Map<VisionProgramID, Map<AlertZoneID, Map<AlertName, string>>> = new Map<VisionProgramID, Map<AlertZoneID, Map<AlertName, string>>>();

    @observable alertsHistory: AlertMessage[] = [];

    constructor(rtcPeerConnectionState: WebRTCPeerConnectionState) {
        this.peerConnState = rtcPeerConnectionState;
        let messageHandler: (e: MessageEvent) => void;
        let snapshotHandler: (e: MessageEvent) => void;

        this.peerConnState.on("data-channel-open", action((channel: RTCDataChannel, peerID: PeerID, label: string) => {
            // Store the message handler so we can removeEventListener when the data channel closes
            messageHandler = (e: MessageEvent) => {
                const protoBytes = e.data;
                this.handleProtoMessage(protoBytes, peerID);
            };

            const dataChannel: DataChannel = {
                channel: channel,
                chunkSize: 0,
                pendingChunks: {}
            };

            snapshotHandler = (e: MessageEvent) => {
                const imgBytes = e.data;
                this.handleSnapshotData(imgBytes, peerID, dataChannel);
            };

            // The name of the channel we're expecting frames for
            if (label === "detector_tracker_frames") {
                channel.addEventListener("message", messageHandler);
                this.detectorTrackerChannels.set(peerID, channel);
            }

            if (label === "snapshot") {
                dataChannel.chunkSize = 4000;
                channel.addEventListener("message", snapshotHandler);
                this.imageSnapshotChannels.set(peerID, channel);
            }
        }));

        this.peerConnState.on("data-channel-closed", action((channel: RTCDataChannel, peerID: PeerID, label: string) => {
            if (label === "detector_tracker_frames") {
                channel.removeEventListener("message", messageHandler);
                this.detectorTrackerChannels.delete(peerID);
            }

            if (label === "snapshot") {
                channel.removeEventListener("message", snapshotHandler);
                this.imageSnapshotChannels.delete(peerID);
            }
        }));
    }

    scaleBoxPoints(box: DetectionBox): DetectionBox {
        const topLeft = box.getTopLeft();
        const bottomRight = box.getBottomRight();
        const bottomCenter = box.getBottomCenter();
        const bottomLeft = box.getBottomLeft();
        const centerCenter = box.getCenterCenter();
        const topRight = box.getTopRight();
        const licensePlateCenterCenter = box.getLicensePlateCenterCenter();
        if (topLeft) {
            box.setTopLeft(this.scalePoint(topLeft));
        }
        if (bottomRight) {
            box.setBottomRight(this.scalePoint(bottomRight));
        }
        if (bottomCenter) {
            box.setBottomCenter(this.scalePoint(bottomCenter));
        }
        if (bottomLeft) {
            box.setBottomLeft(this.scalePoint(bottomLeft));
        }
        if (centerCenter) {
            box.setCenterCenter(this.scalePoint(centerCenter));
        }
        if (topRight) {
            box.setTopRight(this.scalePoint(topRight));
        }
        if (licensePlateCenterCenter) {
            box.setLicensePlateCenterCenter(this.scalePoint(licensePlateCenterCenter));
        }

        const customPointsMap: jspb.Map<string, Point> = box.getCustomPointsMap();

        customPointsMap.forEach((point, key) => {
            customPointsMap.set(key, this.scalePoint(point))
        });

        box.setOccupancyZonePointsList(box.getOccupancyZonePointsList().map(this.scalePoint.bind(this)));
        return box;
    }

    scalePoint(point: Point): Point {
        point.setX(point.getX() * this.xScale);
        point.setY(point.getY() * this.yScale);
        return point
    }

    @action setCompactMode(compactMode: boolean) {
        this.compactMode = compactMode;
    }

    @action setShowControls(showControls: boolean) {
        this.showControls = showControls;
    }

    @action handleSnapshotData(chunkBytes: ArrayBuffer, peerID: PeerID, dataChannel: DataChannel) {
        if (dataChannel.chunkSize !== 0) {
            let parsedChunk: WebRTCDataChannelDataChunk;

            try {
                parsedChunk = WebRTCDataChannelDataChunk.deserializeBinary(new Uint8Array(chunkBytes));
            } catch (e) {
                console.error("Failed to parse WebRTCDataChannelDataChunk protobuf from data: ", base64ArrayBuffer(chunkBytes), e);
                return;
            }

            if (!(parsedChunk.getId() in dataChannel.pendingChunks)) {
                dataChannel.pendingChunks[parsedChunk.getId()] = {}
            }

            if (parsedChunk.getFragmentNumber() >= parsedChunk.getTotalFragments()) {
                console.error("received a WebRTCDataChannelDataChunk with fragment number " + parsedChunk.getFragmentNumber() + " but expected at most " + parsedChunk.getTotalFragments());
                delete dataChannel.pendingChunks[parsedChunk.getId()];
                return;
            }

            dataChannel.pendingChunks[parsedChunk.getId()][parsedChunk.getFragmentNumber()] = parsedChunk.getData_asU8();
            if (Object.keys(dataChannel.pendingChunks[parsedChunk.getId()]).length === parsedChunk.getTotalFragments()) {
                let bytesRequired = 0;
                for (const key in dataChannel.pendingChunks[parsedChunk.getId()]) {
                    bytesRequired += dataChannel.pendingChunks[parsedChunk.getId()][key].byteLength
                }

                let imgBytes = new Uint8Array(bytesRequired);

                let offset = 0;

                for (let i = 0; i < parsedChunk.getTotalFragments(); i++) {
                    imgBytes.set(new Uint8Array(dataChannel.pendingChunks[parsedChunk.getId()][i]), offset);
                    offset += dataChannel.pendingChunks[parsedChunk.getId()][i].byteLength
                }

                const img = new Image();
                img.src = "data:image/jpeg;charset=utf-8;base64," + base64ArrayBuffer(imgBytes);

                this.latestSnapshots.set(peerID, img);
            }
        }
    }

    @action handleProtoMessage(protoBytes: ArrayBuffer, peerID: PeerID) {
        let parsedMessage: UniversalEnvelope;

        try {
            parsedMessage = UniversalEnvelope.deserializeBinary(new Uint8Array(protoBytes));
        } catch (e) {
            console.error("Failed to parse protobuf from data: ", base64ArrayBuffer(protoBytes), e);
            return;
        }

        switch (parsedMessage.getMessageType()) {
            case UniversalEnvelope.MessageType.DETECTOR_TRACKER_FRAME:
                const dtf = parsedMessage.getDetectorTrackerFrame();
                if (dtf === undefined) {
                    console.error("Got a UniversalEnvelope claiming to be a DETECTOR_TRACKER_FRAME but with no DetectorTrackerFrame included");
                    return
                }
                const visionProgramID = dtf.getVisionProgramId();
                this.visionProgramIDsByPeerID.set(peerID, visionProgramID);
                this.peerIDsByVisionProgramID.set(visionProgramID, peerID);

                const currentLatest = this.latestFrames.get(visionProgramID);

                if (currentLatest && currentLatest.getFrameTimeMicroseconds() > dtf.getFrameTimeMicroseconds()) {
                    // This frame is older as it arrived out of order, ignore it
                    return;
                }

                if (currentLatest) {
                    this.latestFPS.set(visionProgramID, 1000000 / (dtf.getFrameTimeMicroseconds() - currentLatest.getFrameTimeMicroseconds()));
                }

                this.latestFrames.set(visionProgramID, dtf);

                const currentLatencies = this.latestFrameLatencies.get(visionProgramID);
                const latencyNow = (Date.now() * 1000) - dtf.getFrameTimeMicroseconds();
                if (!currentLatencies || currentLatencies.length === 0) {
                    this.latestFrameLatencies.set(visionProgramID, [latencyNow]);
                    this.latestFrameAvgLatencies.set(visionProgramID, latencyNow);
                    this.latestFrameJitter.set(visionProgramID, 0);
                } else {
                    if (currentLatencies.length >= 10) {
                        currentLatencies.pop();
                    }

                    currentLatencies.unshift(latencyNow);

                    const latencySum = currentLatencies.reduce((previousValue, currentValue) => currentValue += previousValue);
                    const avg = latencySum / currentLatencies.length;
                    const jitter = currentLatencies
                        .map(latency => Math.abs(latency - avg))
                        .reduce((p, c) => c += p) / currentLatencies.length;

                    this.latestFrameAvgLatencies.set(visionProgramID, avg);
                    this.latestFrameLatencies.set(visionProgramID, currentLatencies);
                    this.latestFrameJitter.set(visionProgramID, jitter);
                }


                dtf.getZoneOrientedFeaturesList().forEach((zonalFeatures: ZonalFeatures) => {
                    this.latestOccupanciesByZoneID.set(zonalFeatures.getZoneId(), zonalFeatures.getAggregatedOccupancy());
                    this.latestAverageDistanceScalarByZoneID.set(zonalFeatures.getZoneId(), zonalFeatures.getAverageDistanceScalar());
                    this.latestCrossingsAnticlockwiseByZoneID.set(zonalFeatures.getZoneId(), zonalFeatures.getAggregatedCrossingsAnticlockwise());
                    this.latestCrossingsClockwiseByZoneID.set(zonalFeatures.getZoneId(), zonalFeatures.getAggregatedCrossingsClockwise());
                    this.latestStoppedVehiclesByZoneID.set(zonalFeatures.getZoneId(), zonalFeatures.getAggregatedStoppedVehiclesCount());
                });

                const trackHeads = dtf.getTrackHeadsList().map((trackHead: TrackHead) => {
                    const box = trackHead.getDetectionBox();
                    if (box) {
                        trackHead.setDetectionBox(this.scaleBoxPoints(box));
                    }

                    return trackHead;
                });

                this.latestTrackHeads.set(visionProgramID, trackHeads);

                const trackSegments = dtf.getTrackSegmentsList().map((trackSegment: TrackSegment) => {
                    const trackHeadStart = trackSegment.getTrackHeadStart();
                    const trackHeadEnd = trackSegment.getTrackHeadEnd();
                    if (trackHeadStart) {
                        const box = trackHeadStart.getDetectionBox();
                        if (box) {
                            trackHeadStart.setDetectionBox(this.scaleBoxPoints(box))
                        }
                        trackSegment.setTrackHeadStart(trackHeadStart);
                    }
                    if (trackHeadEnd) {
                        const box = trackHeadEnd.getDetectionBox();
                        if (box) {
                            trackHeadEnd.setDetectionBox(this.scaleBoxPoints(box))
                        }
                        trackSegment.setTrackHeadEnd(trackHeadEnd);
                    }

                    return trackSegment;
                });


                this.latestTrackSegments.set(visionProgramID, trackSegments);

                break;
            case UniversalEnvelope.MessageType.ZONES_CONFIG_CHANGE:
                const config = parsedMessage.getZonesConfigChange();
                if (config === undefined) {
                    console.error("Got a UniversalEnvelope claiming to be a OCCUPANCY_ZONE_CONFIG_CHANGE but with no ZonesConfigChange included");
                    return
                }

                switch (config.getOperationType()) {
                    case ZonesConfigChange.OperationType.UPDATE:
                        break;
                    case ZonesConfigChange.OperationType.SET:
                        config.getZonesList().forEach((config: VisionProgramZonesConfig) => {
                            if (!this.occupancyZoneDefinitions.has(config.getVisionProgramId())) {
                                this.occupancyZoneDefinitions.set(config.getVisionProgramId(), new Map<ZonesID, number[]>());
                            }
                            if (!this.occupancyZoneCentroids.has(config.getVisionProgramId())) {
                                this.occupancyZoneCentroids.set(config.getVisionProgramId(), new Map<ZonesID, [number, number]>())
                            }

                            this.visionProgramIDsByPeerID.set(peerID, config.getVisionProgramId());
                            this.peerIDsByVisionProgramID.set(config.getVisionProgramId(), peerID);


                            config.getZonesList().forEach(zone => {
                                const visionProgramZones = this.occupancyZoneDefinitions.get(config.getVisionProgramId());
                                const visionProgramZoneCentroids = this.occupancyZoneCentroids.get(config.getVisionProgramId());
                                const flattenedGeometryRing: number[] = [];
                                zone.getGeometryRingList().forEach(point => {
                                    flattenedGeometryRing.push(point.getX() * this.xScale, point.getY() * this.yScale)
                                });

                                if (visionProgramZones) {
                                    visionProgramZones.set(zone.getZoneId(), flattenedGeometryRing);
                                }
                                if (visionProgramZoneCentroids) {
                                    visionProgramZoneCentroids.set(zone.getZoneId(), averageAndScalePoints(flattenedGeometryRing))
                                }

                                // Creating alertZones for VisionProgramId
                                let zonalAlertsByVisionProgramId = this.zonalAlerts.get(config.getVisionProgramId());
                                if (!zonalAlertsByVisionProgramId) {
                                    zonalAlertsByVisionProgramId = new Map<AlertZoneID, Map<AlertName, string>>();
                                    this.zonalAlerts.set(config.getVisionProgramId(), zonalAlertsByVisionProgramId);
                                }

                                let alertsByZoneId = zonalAlertsByVisionProgramId.get(zone.getZoneId());
                                if (!alertsByZoneId) {
                                    alertsByZoneId = new Map<AlertName, string>();
                                    zonalAlertsByVisionProgramId.set(zone.getZoneId(), alertsByZoneId);
                                }

                                for (let alertEnum in alertNamesMap) {
                                    this.zonalAlerts.get(config.getVisionProgramId())?.get(zone.getZoneId())?.set(alertNamesMap[alertEnum], alertTypesMap[0]);
                                }
                            });
                        });
                        break;
                }
                break;
            case UniversalEnvelope.MessageType.ALERT_MESSAGE:
                const alertMessage = parsedMessage.getAlertMessage();
                if (alertMessage === undefined) {
                    console.error("Got a UniversalEnvelope claiming to be a ALERT_MESSAGE but with no AlertMessage included");
                    return
                }

                const alertVisionProgramId = alertMessage.getVisionProgramId();
                const alertZoneId = alertMessage.getZoneId();
                const alertName = alertMessage.getAlertName();
                const alertType = alertMessage.getAlertType();

                const alertNameString = transposeMap(AlertMessage.AlertName)[alertName];

                let zonalAlertsByVisionProgramId = this.zonalAlerts.get(alertVisionProgramId);
                if (!zonalAlertsByVisionProgramId) {
                    zonalAlertsByVisionProgramId = new Map<AlertZoneID, Map<AlertName, string>>();
                    this.zonalAlerts.set(alertVisionProgramId, zonalAlertsByVisionProgramId);
                }

                let alertsByZoneId = zonalAlertsByVisionProgramId.get(alertZoneId);
                if (!alertsByZoneId) {
                    alertsByZoneId = new Map<AlertName, string>();
                    zonalAlertsByVisionProgramId.set(alertZoneId, alertsByZoneId);
                }

                alertsByZoneId.set(alertNameString, alertTypesMap[alertType]);

                this.alertsHistory.push(alertMessage);

                break;
        }
    }
}

export default DetectorTrackerFrameStore;