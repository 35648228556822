/* eslint-disable */
// source: vivacity/config/config_message.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var vivacity_config_zone_config_pb = require('../../vivacity/config/zone_config_pb.js');
goog.object.extend(proto, vivacity_config_zone_config_pb);
var vivacity_config_countline_config_pb = require('../../vivacity/config/countline_config_pb.js');
goog.object.extend(proto, vivacity_config_countline_config_pb);
var vivacity_config_hardware_pb = require('../../vivacity/config/hardware_pb.js');
goog.object.extend(proto, vivacity_config_hardware_pb);
var vivacity_config_vault_pb = require('../../vivacity/config/vault_pb.js');
goog.object.extend(proto, vivacity_config_vault_pb);
var vivacity_config_vision_program_modification_pb = require('../../vivacity/config/vision_program_modification_pb.js');
goog.object.extend(proto, vivacity_config_vision_program_modification_pb);
var vivacity_config_perspective_transform_pb = require('../../vivacity/config/perspective_transform_pb.js');
goog.object.extend(proto, vivacity_config_perspective_transform_pb);
var vivacity_core_tracing_context_pb = require('../../vivacity/core/tracing_context_pb.js');
goog.object.extend(proto, vivacity_core_tracing_context_pb);
var vivacity_config_file_templates_pb = require('../../vivacity/config/file_templates_pb.js');
goog.object.extend(proto, vivacity_config_file_templates_pb);
var vivacity_config_alerts_config_pb = require('../../vivacity/config/alerts_config_pb.js');
goog.object.extend(proto, vivacity_config_alerts_config_pb);
var vivacity_config_agent_config_pb = require('../../vivacity/config/agent_config_pb.js');
goog.object.extend(proto, vivacity_config_agent_config_pb);
var vivacity_config_controller_config_pb = require('../../vivacity/config/controller_config_pb.js');
goog.object.extend(proto, vivacity_config_controller_config_pb);
var vivacity_config_region_config_pb = require('../../vivacity/config/region_config_pb.js');
goog.object.extend(proto, vivacity_config_region_config_pb);
var vivacity_config_instation_config_pb = require('../../vivacity/config/instation_config_pb.js');
goog.object.extend(proto, vivacity_config_instation_config_pb);
var vivacity_config_cronjob_pb = require('../../vivacity/config/cronjob_pb.js');
goog.object.extend(proto, vivacity_config_cronjob_pb);
var vivacity_benchmarking_cv_model_pb = require('../../vivacity/benchmarking/cv_model_pb.js');
goog.object.extend(proto, vivacity_benchmarking_cv_model_pb);
var vivacity_benchmarking_benchmark_run_pb = require('../../vivacity/benchmarking/benchmark_run_pb.js');
goog.object.extend(proto, vivacity_benchmarking_benchmark_run_pb);
var vivacity_command_docker_pb = require('../../vivacity/command/docker_pb.js');
goog.object.extend(proto, vivacity_command_docker_pb);
var vivacity_command_service_pb = require('../../vivacity/command/service_pb.js');
goog.object.extend(proto, vivacity_command_service_pb);
var vivacity_command_shell_pb = require('../../vivacity/command/shell_pb.js');
goog.object.extend(proto, vivacity_command_shell_pb);
var vivacity_command_unmanaged_file_pb = require('../../vivacity/command/unmanaged_file_pb.js');
goog.object.extend(proto, vivacity_command_unmanaged_file_pb);
goog.exportSymbol('proto.vivacity.config.CommandGroupRequest', null, global);
goog.exportSymbol('proto.vivacity.config.CommandGroupRequest.CommandState', null, global);
goog.exportSymbol('proto.vivacity.config.CommandGroupRequest.ErrorResponseType', null, global);
goog.exportSymbol('proto.vivacity.config.CommandGroupTemplateMessage', null, global);
goog.exportSymbol('proto.vivacity.config.CommandGroupTemplateQuery', null, global);
goog.exportSymbol('proto.vivacity.config.CommandRequest', null, global);
goog.exportSymbol('proto.vivacity.config.CommandRequest.CommandState', null, global);
goog.exportSymbol('proto.vivacity.config.CommandRequest.CommandType', null, global);
goog.exportSymbol('proto.vivacity.config.CommandRequest.ErrorResponseType', null, global);
goog.exportSymbol('proto.vivacity.config.ConfigError', null, global);
goog.exportSymbol('proto.vivacity.config.ConfigError.ErrorType', null, global);
goog.exportSymbol('proto.vivacity.config.ConfigMessage', null, global);
goog.exportSymbol('proto.vivacity.config.ConfigMessage.EventMessageType', null, global);
goog.exportSymbol('proto.vivacity.config.OriginatingSystem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ConfigError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.ConfigError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ConfigError.displayName = 'proto.vivacity.config.ConfigError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.ConfigMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.ConfigMessage.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.ConfigMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.ConfigMessage.displayName = 'proto.vivacity.config.ConfigMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CommandGroupTemplateMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.CommandGroupTemplateMessage.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.CommandGroupTemplateMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CommandGroupTemplateMessage.displayName = 'proto.vivacity.config.CommandGroupTemplateMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CommandGroupTemplateQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.CommandGroupTemplateQuery.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.CommandGroupTemplateQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CommandGroupTemplateQuery.displayName = 'proto.vivacity.config.CommandGroupTemplateQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CommandGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.vivacity.config.CommandGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.vivacity.config.CommandGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CommandGroupRequest.displayName = 'proto.vivacity.config.CommandGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.vivacity.config.CommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.vivacity.config.CommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.vivacity.config.CommandRequest.displayName = 'proto.vivacity.config.CommandRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ConfigError.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ConfigError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ConfigError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ConfigError.toObject = function(includeInstance, msg) {
  var f, obj = {
    originatingMessage: (f = msg.getOriginatingMessage()) && proto.vivacity.config.ConfigMessage.toObject(includeInstance, f),
    errorType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ConfigError}
 */
proto.vivacity.config.ConfigError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ConfigError;
  return proto.vivacity.config.ConfigError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ConfigError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ConfigError}
 */
proto.vivacity.config.ConfigError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.vivacity.config.ConfigMessage;
      reader.readMessage(value,proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader);
      msg.setOriginatingMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.ConfigError.ErrorType} */ (reader.readEnum());
      msg.setErrorType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ConfigError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ConfigError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ConfigError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ConfigError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginatingMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.vivacity.config.ConfigMessage.serializeBinaryToWriter
    );
  }
  f = message.getErrorType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.ConfigError.ErrorType = {
  UNKNOWN_ERROR_TYPE: 0,
  CLIENT_ERROR: 400,
  INTERNAL_SERVER_ERROR: 500
};

/**
 * optional ConfigMessage originating_message = 1;
 * @return {?proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigError.prototype.getOriginatingMessage = function() {
  return /** @type{?proto.vivacity.config.ConfigMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.ConfigMessage, 1));
};


/**
 * @param {?proto.vivacity.config.ConfigMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigError} returns this
*/
proto.vivacity.config.ConfigError.prototype.setOriginatingMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigError} returns this
 */
proto.vivacity.config.ConfigError.prototype.clearOriginatingMessage = function() {
  return this.setOriginatingMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigError.prototype.hasOriginatingMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ErrorType error_type = 2;
 * @return {!proto.vivacity.config.ConfigError.ErrorType}
 */
proto.vivacity.config.ConfigError.prototype.getErrorType = function() {
  return /** @type {!proto.vivacity.config.ConfigError.ErrorType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.ConfigError.ErrorType} value
 * @return {!proto.vivacity.config.ConfigError} returns this
 */
proto.vivacity.config.ConfigError.prototype.setErrorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string error_message = 3;
 * @return {string}
 */
proto.vivacity.config.ConfigError.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ConfigError} returns this
 */
proto.vivacity.config.ConfigError.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional OriginatingSystem originating_system = 4;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.ConfigError.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.ConfigError} returns this
 */
proto.vivacity.config.ConfigError.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.ConfigMessage.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.ConfigMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.ConfigMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.ConfigMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ConfigMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventMessageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timestampMicroseconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tracingCtx: (f = msg.getTracingCtx()) && vivacity_core_tracing_context_pb.TracingContext.toObject(includeInstance, f),
    batchedConfigMessagesList: jspb.Message.toObjectList(msg.getBatchedConfigMessagesList(),
    proto.vivacity.config.ConfigMessage.toObject, includeInstance),
    reducedBatchedConfigMessagesList: jspb.Message.toObjectList(msg.getReducedBatchedConfigMessagesList(),
    proto.vivacity.config.ConfigMessage.toObject, includeInstance),
    changeZoneImageSpace: (f = msg.getChangeZoneImageSpace()) && vivacity_config_zone_config_pb.ZonesConfigChange.toObject(includeInstance, f),
    zoneImageSpaceChanged: (f = msg.getZoneImageSpaceChanged()) && vivacity_config_zone_config_pb.ZonesConfigChange.toObject(includeInstance, f),
    changeCountlineImageSpace: (f = msg.getChangeCountlineImageSpace()) && vivacity_config_countline_config_pb.CountlinesConfigChange.toObject(includeInstance, f),
    countlineImageSpaceChanged: (f = msg.getCountlineImageSpaceChanged()) && vivacity_config_countline_config_pb.CountlinesConfigChange.toObject(includeInstance, f),
    changePerspectiveTransform: (f = msg.getChangePerspectiveTransform()) && vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.toObject(includeInstance, f),
    perspectiveTransformChanged: (f = msg.getPerspectiveTransformChanged()) && vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.toObject(includeInstance, f),
    changeCountlineRealWorldSpace: (f = msg.getChangeCountlineRealWorldSpace()) && vivacity_config_countline_config_pb.CountlinesConfigChange.toObject(includeInstance, f),
    countlineRealWorldSpaceChanged: (f = msg.getCountlineRealWorldSpaceChanged()) && vivacity_config_countline_config_pb.CountlinesConfigChange.toObject(includeInstance, f),
    changeZonalAlert: (f = msg.getChangeZonalAlert()) && vivacity_config_alerts_config_pb.ZonalAlertConfigChange.toObject(includeInstance, f),
    zonalAlertChanged: (f = msg.getZonalAlertChanged()) && vivacity_config_alerts_config_pb.ZonalAlertConfigChange.toObject(includeInstance, f),
    getHardwareId: (f = msg.getGetHardwareId()) && vivacity_config_hardware_pb.GetOrCreateHardwareId.toObject(includeInstance, f),
    createHardwareId: (f = msg.getCreateHardwareId()) && vivacity_config_hardware_pb.GetOrCreateHardwareId.toObject(includeInstance, f),
    hardwareIdTokenResponse: (f = msg.getHardwareIdTokenResponse()) && vivacity_config_hardware_pb.HardwareIdTokenResponse.toObject(includeInstance, f),
    hardwareIdCreated: (f = msg.getHardwareIdCreated()) && vivacity_config_hardware_pb.HardwareIdCreated.toObject(includeInstance, f),
    vaultResourceCreated: (f = msg.getVaultResourceCreated()) && vivacity_config_vault_pb.VaultResourceCreated.toObject(includeInstance, f),
    changeHardwareFields: (f = msg.getChangeHardwareFields()) && vivacity_config_hardware_pb.HardwareFields.toObject(includeInstance, f),
    hardwareFieldsChanged: (f = msg.getHardwareFieldsChanged()) && vivacity_config_hardware_pb.HardwareFields.toObject(includeInstance, f),
    createVisionProgram: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    visionProgramCreated: jspb.Message.getFieldWithDefault(msg, 32, 0),
    changeVisionProgramFields: (f = msg.getChangeVisionProgramFields()) && vivacity_config_vision_program_modification_pb.VisionProgramFields.toObject(includeInstance, f),
    visionProgramFieldsChanged: (f = msg.getVisionProgramFieldsChanged()) && vivacity_config_vision_program_modification_pb.VisionProgramFields.toObject(includeInstance, f),
    linkCountlineImageSpaceToRealWorld: (f = msg.getLinkCountlineImageSpaceToRealWorld()) && vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.toObject(includeInstance, f),
    countlineImageSpaceToRealWorldLinked: (f = msg.getCountlineImageSpaceToRealWorldLinked()) && vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.toObject(includeInstance, f),
    unlinkCountlineImageSpaceToRealWorld: (f = msg.getUnlinkCountlineImageSpaceToRealWorld()) && vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.toObject(includeInstance, f),
    countlineImageSpaceToRealWorldUnlinked: (f = msg.getCountlineImageSpaceToRealWorldUnlinked()) && vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.toObject(includeInstance, f),
    createFileTemplate: (f = msg.getCreateFileTemplate()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    fileTemplateCreated: (f = msg.getFileTemplateCreated()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    changeFileTemplateFields: (f = msg.getChangeFileTemplateFields()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    fileTemplateFieldsChanged: (f = msg.getFileTemplateFieldsChanged()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    deleteFileTemplate: (f = msg.getDeleteFileTemplate()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    fileTemplateDeleted: (f = msg.getFileTemplateDeleted()) && vivacity_config_file_templates_pb.FileTemplate.toObject(includeInstance, f),
    createRenderedFileEntry: (f = msg.getCreateRenderedFileEntry()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    renderedFileEntryCreated: (f = msg.getRenderedFileEntryCreated()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    deleteRenderedFile: (f = msg.getDeleteRenderedFile()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    renderedFileDeleted: (f = msg.getRenderedFileDeleted()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    renderFile: (f = msg.getRenderFile()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    fileRendered: (f = msg.getFileRendered()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    updateFileOnHardware: (f = msg.getUpdateFileOnHardware()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    fileOnHardwareUpdated: (f = msg.getFileOnHardwareUpdated()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    remoteFileStateOnDbUpdated: (f = msg.getRemoteFileStateOnDbUpdated()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    retrieveRenderedFiles: (f = msg.getRetrieveRenderedFiles()) && vivacity_config_file_templates_pb.RetrieveFile.toObject(includeInstance, f),
    linkVisionProgramToHardware: (f = msg.getLinkVisionProgramToHardware()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardware.toObject(includeInstance, f),
    visionProgramToHardwareLinked: (f = msg.getVisionProgramToHardwareLinked()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardware.toObject(includeInstance, f),
    unlinkVisionProgramToHardware: (f = msg.getUnlinkVisionProgramToHardware()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardware.toObject(includeInstance, f),
    visionProgramToHardwareUnlinked: (f = msg.getVisionProgramToHardwareUnlinked()) && vivacity_config_vision_program_modification_pb.VisionProgramToHardware.toObject(includeInstance, f),
    changeLinkageCronjobToHardware: (f = msg.getChangeLinkageCronjobToHardware()) && vivacity_config_cronjob_pb.CronjobToHardwareLinkage.toObject(includeInstance, f),
    cronjobToHardwareLinkageChanged: (f = msg.getCronjobToHardwareLinkageChanged()) && vivacity_config_cronjob_pb.CronjobToHardwareLinkage.toObject(includeInstance, f),
    changeCronjob: (f = msg.getChangeCronjob()) && vivacity_config_cronjob_pb.Cronjob.toObject(includeInstance, f),
    cronjobChanged: (f = msg.getCronjobChanged()) && vivacity_config_cronjob_pb.Cronjob.toObject(includeInstance, f),
    createCvModel: (f = msg.getCreateCvModel()) && vivacity_benchmarking_cv_model_pb.CvModel.toObject(includeInstance, f),
    createBenchmarkRun: (f = msg.getCreateBenchmarkRun()) && vivacity_benchmarking_benchmark_run_pb.BenchmarkRun.toObject(includeInstance, f),
    createCommandGroupTemplate: (f = msg.getCreateCommandGroupTemplate()) && proto.vivacity.config.CommandGroupTemplateMessage.toObject(includeInstance, f),
    commandGroupTemplateCreated: (f = msg.getCommandGroupTemplateCreated()) && proto.vivacity.config.CommandGroupTemplateMessage.toObject(includeInstance, f),
    createAgent: (f = msg.getCreateAgent()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    agentCreated: (f = msg.getAgentCreated()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    changeAgent: (f = msg.getChangeAgent()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    agentChanged: (f = msg.getAgentChanged()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    deleteAgent: (f = msg.getDeleteAgent()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    agentDeleted: (f = msg.getAgentDeleted()) && vivacity_config_agent_config_pb.Agent.toObject(includeInstance, f),
    createAgentStream: (f = msg.getCreateAgentStream()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    agentStreamCreated: (f = msg.getAgentStreamCreated()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    changeAgentStream: (f = msg.getChangeAgentStream()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    agentStreamChanged: (f = msg.getAgentStreamChanged()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    deleteAgentStream: (f = msg.getDeleteAgentStream()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    agentStreamDeleted: (f = msg.getAgentStreamDeleted()) && vivacity_config_agent_config_pb.AgentStream.toObject(includeInstance, f),
    createCountry: (f = msg.getCreateCountry()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    countryCreated: (f = msg.getCountryCreated()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    changeCountry: (f = msg.getChangeCountry()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    countryChanged: (f = msg.getCountryChanged()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    deleteCountry: (f = msg.getDeleteCountry()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    countryDeleted: (f = msg.getCountryDeleted()) && vivacity_config_region_config_pb.Country.toObject(includeInstance, f),
    createRegion: (f = msg.getCreateRegion()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    regionCreated: (f = msg.getRegionCreated()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    changeRegion: (f = msg.getChangeRegion()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    regionChanged: (f = msg.getRegionChanged()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    deleteRegion: (f = msg.getDeleteRegion()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    regionDeleted: (f = msg.getRegionDeleted()) && vivacity_config_region_config_pb.Region.toObject(includeInstance, f),
    createController: (f = msg.getCreateController()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    controllerCreated: (f = msg.getControllerCreated()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    changeController: (f = msg.getChangeController()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    controllerChanged: (f = msg.getControllerChanged()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    deleteController: (f = msg.getDeleteController()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    controllerDeleted: (f = msg.getControllerDeleted()) && vivacity_config_controller_config_pb.Controller.toObject(includeInstance, f),
    createControllerStream: (f = msg.getCreateControllerStream()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    controllerStreamCreated: (f = msg.getControllerStreamCreated()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    changeControllerStream: (f = msg.getChangeControllerStream()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    controllerStreamChanged: (f = msg.getControllerStreamChanged()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    deleteControllerStream: (f = msg.getDeleteControllerStream()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    controllerStreamDeleted: (f = msg.getControllerStreamDeleted()) && vivacity_config_controller_config_pb.ControllerStream.toObject(includeInstance, f),
    createVelocity: (f = msg.getCreateVelocity()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    velocityCreated: (f = msg.getVelocityCreated()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    changeVelocity: (f = msg.getChangeVelocity()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    velocityChanged: (f = msg.getVelocityChanged()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    deleteVelocity: (f = msg.getDeleteVelocity()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    velocityDeleted: (f = msg.getVelocityDeleted()) && vivacity_config_instation_config_pb.Velocity.toObject(includeInstance, f),
    createAgentConfig: (f = msg.getCreateAgentConfig()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    agentConfigCreated: (f = msg.getAgentConfigCreated()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    changeAgentConfig: (f = msg.getChangeAgentConfig()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    agentConfigChanged: (f = msg.getAgentConfigChanged()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    deleteAgentConfig: (f = msg.getDeleteAgentConfig()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f),
    agentConfigDeleted: (f = msg.getAgentConfigDeleted()) && vivacity_config_agent_config_pb.AgentConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.ConfigMessage;
  return proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.ConfigMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.vivacity.config.ConfigMessage.EventMessageType} */ (reader.readEnum());
      msg.setEventMessageType(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimestampMicroseconds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 6:
      var value = new vivacity_core_tracing_context_pb.TracingContext;
      reader.readMessage(value,vivacity_core_tracing_context_pb.TracingContext.deserializeBinaryFromReader);
      msg.setTracingCtx(value);
      break;
    case 7:
      var value = new proto.vivacity.config.ConfigMessage;
      reader.readMessage(value,proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader);
      msg.addBatchedConfigMessages(value);
      break;
    case 8:
      var value = new proto.vivacity.config.ConfigMessage;
      reader.readMessage(value,proto.vivacity.config.ConfigMessage.deserializeBinaryFromReader);
      msg.addReducedBatchedConfigMessages(value);
      break;
    case 11:
      var value = new vivacity_config_zone_config_pb.ZonesConfigChange;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonesConfigChange.deserializeBinaryFromReader);
      msg.setChangeZoneImageSpace(value);
      break;
    case 12:
      var value = new vivacity_config_zone_config_pb.ZonesConfigChange;
      reader.readMessage(value,vivacity_config_zone_config_pb.ZonesConfigChange.deserializeBinaryFromReader);
      msg.setZoneImageSpaceChanged(value);
      break;
    case 13:
      var value = new vivacity_config_countline_config_pb.CountlinesConfigChange;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlinesConfigChange.deserializeBinaryFromReader);
      msg.setChangeCountlineImageSpace(value);
      break;
    case 14:
      var value = new vivacity_config_countline_config_pb.CountlinesConfigChange;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlinesConfigChange.deserializeBinaryFromReader);
      msg.setCountlineImageSpaceChanged(value);
      break;
    case 15:
      var value = new vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram;
      reader.readMessage(value,vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.deserializeBinaryFromReader);
      msg.setChangePerspectiveTransform(value);
      break;
    case 16:
      var value = new vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram;
      reader.readMessage(value,vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.deserializeBinaryFromReader);
      msg.setPerspectiveTransformChanged(value);
      break;
    case 17:
      var value = new vivacity_config_countline_config_pb.CountlinesConfigChange;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlinesConfigChange.deserializeBinaryFromReader);
      msg.setChangeCountlineRealWorldSpace(value);
      break;
    case 18:
      var value = new vivacity_config_countline_config_pb.CountlinesConfigChange;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlinesConfigChange.deserializeBinaryFromReader);
      msg.setCountlineRealWorldSpaceChanged(value);
      break;
    case 19:
      var value = new vivacity_config_alerts_config_pb.ZonalAlertConfigChange;
      reader.readMessage(value,vivacity_config_alerts_config_pb.ZonalAlertConfigChange.deserializeBinaryFromReader);
      msg.setChangeZonalAlert(value);
      break;
    case 20:
      var value = new vivacity_config_alerts_config_pb.ZonalAlertConfigChange;
      reader.readMessage(value,vivacity_config_alerts_config_pb.ZonalAlertConfigChange.deserializeBinaryFromReader);
      msg.setZonalAlertChanged(value);
      break;
    case 21:
      var value = new vivacity_config_hardware_pb.GetOrCreateHardwareId;
      reader.readMessage(value,vivacity_config_hardware_pb.GetOrCreateHardwareId.deserializeBinaryFromReader);
      msg.setGetHardwareId(value);
      break;
    case 22:
      var value = new vivacity_config_hardware_pb.GetOrCreateHardwareId;
      reader.readMessage(value,vivacity_config_hardware_pb.GetOrCreateHardwareId.deserializeBinaryFromReader);
      msg.setCreateHardwareId(value);
      break;
    case 23:
      var value = new vivacity_config_hardware_pb.HardwareIdTokenResponse;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareIdTokenResponse.deserializeBinaryFromReader);
      msg.setHardwareIdTokenResponse(value);
      break;
    case 24:
      var value = new vivacity_config_hardware_pb.HardwareIdCreated;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareIdCreated.deserializeBinaryFromReader);
      msg.setHardwareIdCreated(value);
      break;
    case 25:
      var value = new vivacity_config_vault_pb.VaultResourceCreated;
      reader.readMessage(value,vivacity_config_vault_pb.VaultResourceCreated.deserializeBinaryFromReader);
      msg.setVaultResourceCreated(value);
      break;
    case 26:
      var value = new vivacity_config_hardware_pb.HardwareFields;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareFields.deserializeBinaryFromReader);
      msg.setChangeHardwareFields(value);
      break;
    case 27:
      var value = new vivacity_config_hardware_pb.HardwareFields;
      reader.readMessage(value,vivacity_config_hardware_pb.HardwareFields.deserializeBinaryFromReader);
      msg.setHardwareFieldsChanged(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateVisionProgram(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVisionProgramCreated(value);
      break;
    case 33:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramFields;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramFields.deserializeBinaryFromReader);
      msg.setChangeVisionProgramFields(value);
      break;
    case 34:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramFields;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramFields.deserializeBinaryFromReader);
      msg.setVisionProgramFieldsChanged(value);
      break;
    case 35:
      var value = new vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader);
      msg.setLinkCountlineImageSpaceToRealWorld(value);
      break;
    case 36:
      var value = new vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader);
      msg.setCountlineImageSpaceToRealWorldLinked(value);
      break;
    case 37:
      var value = new vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader);
      msg.setUnlinkCountlineImageSpaceToRealWorld(value);
      break;
    case 38:
      var value = new vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld;
      reader.readMessage(value,vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.deserializeBinaryFromReader);
      msg.setCountlineImageSpaceToRealWorldUnlinked(value);
      break;
    case 40:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setCreateFileTemplate(value);
      break;
    case 41:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setFileTemplateCreated(value);
      break;
    case 42:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setChangeFileTemplateFields(value);
      break;
    case 43:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setFileTemplateFieldsChanged(value);
      break;
    case 44:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setDeleteFileTemplate(value);
      break;
    case 45:
      var value = new vivacity_config_file_templates_pb.FileTemplate;
      reader.readMessage(value,vivacity_config_file_templates_pb.FileTemplate.deserializeBinaryFromReader);
      msg.setFileTemplateDeleted(value);
      break;
    case 46:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setCreateRenderedFileEntry(value);
      break;
    case 47:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setRenderedFileEntryCreated(value);
      break;
    case 48:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setDeleteRenderedFile(value);
      break;
    case 49:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setRenderedFileDeleted(value);
      break;
    case 50:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setRenderFile(value);
      break;
    case 51:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setFileRendered(value);
      break;
    case 52:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setUpdateFileOnHardware(value);
      break;
    case 53:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setFileOnHardwareUpdated(value);
      break;
    case 54:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setRemoteFileStateOnDbUpdated(value);
      break;
    case 55:
      var value = new vivacity_config_file_templates_pb.RetrieveFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RetrieveFile.deserializeBinaryFromReader);
      msg.setRetrieveRenderedFiles(value);
      break;
    case 60:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardware;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardware.deserializeBinaryFromReader);
      msg.setLinkVisionProgramToHardware(value);
      break;
    case 61:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardware;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardware.deserializeBinaryFromReader);
      msg.setVisionProgramToHardwareLinked(value);
      break;
    case 62:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardware;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardware.deserializeBinaryFromReader);
      msg.setUnlinkVisionProgramToHardware(value);
      break;
    case 63:
      var value = new vivacity_config_vision_program_modification_pb.VisionProgramToHardware;
      reader.readMessage(value,vivacity_config_vision_program_modification_pb.VisionProgramToHardware.deserializeBinaryFromReader);
      msg.setVisionProgramToHardwareUnlinked(value);
      break;
    case 64:
      var value = new vivacity_config_cronjob_pb.CronjobToHardwareLinkage;
      reader.readMessage(value,vivacity_config_cronjob_pb.CronjobToHardwareLinkage.deserializeBinaryFromReader);
      msg.setChangeLinkageCronjobToHardware(value);
      break;
    case 65:
      var value = new vivacity_config_cronjob_pb.CronjobToHardwareLinkage;
      reader.readMessage(value,vivacity_config_cronjob_pb.CronjobToHardwareLinkage.deserializeBinaryFromReader);
      msg.setCronjobToHardwareLinkageChanged(value);
      break;
    case 66:
      var value = new vivacity_config_cronjob_pb.Cronjob;
      reader.readMessage(value,vivacity_config_cronjob_pb.Cronjob.deserializeBinaryFromReader);
      msg.setChangeCronjob(value);
      break;
    case 67:
      var value = new vivacity_config_cronjob_pb.Cronjob;
      reader.readMessage(value,vivacity_config_cronjob_pb.Cronjob.deserializeBinaryFromReader);
      msg.setCronjobChanged(value);
      break;
    case 68:
      var value = new vivacity_benchmarking_cv_model_pb.CvModel;
      reader.readMessage(value,vivacity_benchmarking_cv_model_pb.CvModel.deserializeBinaryFromReader);
      msg.setCreateCvModel(value);
      break;
    case 69:
      var value = new vivacity_benchmarking_benchmark_run_pb.BenchmarkRun;
      reader.readMessage(value,vivacity_benchmarking_benchmark_run_pb.BenchmarkRun.deserializeBinaryFromReader);
      msg.setCreateBenchmarkRun(value);
      break;
    case 70:
      var value = new proto.vivacity.config.CommandGroupTemplateMessage;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader);
      msg.setCreateCommandGroupTemplate(value);
      break;
    case 71:
      var value = new proto.vivacity.config.CommandGroupTemplateMessage;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader);
      msg.setCommandGroupTemplateCreated(value);
      break;
    case 72:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setCreateAgent(value);
      break;
    case 73:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setAgentCreated(value);
      break;
    case 74:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setChangeAgent(value);
      break;
    case 75:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setAgentChanged(value);
      break;
    case 76:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setDeleteAgent(value);
      break;
    case 77:
      var value = new vivacity_config_agent_config_pb.Agent;
      reader.readMessage(value,vivacity_config_agent_config_pb.Agent.deserializeBinaryFromReader);
      msg.setAgentDeleted(value);
      break;
    case 78:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setCreateAgentStream(value);
      break;
    case 79:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setAgentStreamCreated(value);
      break;
    case 80:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setChangeAgentStream(value);
      break;
    case 81:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setAgentStreamChanged(value);
      break;
    case 82:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setDeleteAgentStream(value);
      break;
    case 83:
      var value = new vivacity_config_agent_config_pb.AgentStream;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentStream.deserializeBinaryFromReader);
      msg.setAgentStreamDeleted(value);
      break;
    case 84:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setCreateCountry(value);
      break;
    case 85:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setCountryCreated(value);
      break;
    case 86:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setChangeCountry(value);
      break;
    case 87:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setCountryChanged(value);
      break;
    case 88:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setDeleteCountry(value);
      break;
    case 89:
      var value = new vivacity_config_region_config_pb.Country;
      reader.readMessage(value,vivacity_config_region_config_pb.Country.deserializeBinaryFromReader);
      msg.setCountryDeleted(value);
      break;
    case 90:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setCreateRegion(value);
      break;
    case 91:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setRegionCreated(value);
      break;
    case 92:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setChangeRegion(value);
      break;
    case 93:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setRegionChanged(value);
      break;
    case 94:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setDeleteRegion(value);
      break;
    case 95:
      var value = new vivacity_config_region_config_pb.Region;
      reader.readMessage(value,vivacity_config_region_config_pb.Region.deserializeBinaryFromReader);
      msg.setRegionDeleted(value);
      break;
    case 96:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setCreateController(value);
      break;
    case 97:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setControllerCreated(value);
      break;
    case 98:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setChangeController(value);
      break;
    case 99:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setControllerChanged(value);
      break;
    case 100:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setDeleteController(value);
      break;
    case 101:
      var value = new vivacity_config_controller_config_pb.Controller;
      reader.readMessage(value,vivacity_config_controller_config_pb.Controller.deserializeBinaryFromReader);
      msg.setControllerDeleted(value);
      break;
    case 102:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setCreateControllerStream(value);
      break;
    case 103:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setControllerStreamCreated(value);
      break;
    case 104:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setChangeControllerStream(value);
      break;
    case 105:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setControllerStreamChanged(value);
      break;
    case 106:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setDeleteControllerStream(value);
      break;
    case 107:
      var value = new vivacity_config_controller_config_pb.ControllerStream;
      reader.readMessage(value,vivacity_config_controller_config_pb.ControllerStream.deserializeBinaryFromReader);
      msg.setControllerStreamDeleted(value);
      break;
    case 108:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setCreateVelocity(value);
      break;
    case 109:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setVelocityCreated(value);
      break;
    case 110:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setChangeVelocity(value);
      break;
    case 111:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setVelocityChanged(value);
      break;
    case 112:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setDeleteVelocity(value);
      break;
    case 113:
      var value = new vivacity_config_instation_config_pb.Velocity;
      reader.readMessage(value,vivacity_config_instation_config_pb.Velocity.deserializeBinaryFromReader);
      msg.setVelocityDeleted(value);
      break;
    case 114:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setCreateAgentConfig(value);
      break;
    case 115:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setAgentConfigCreated(value);
      break;
    case 116:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setChangeAgentConfig(value);
      break;
    case 117:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setAgentConfigChanged(value);
      break;
    case 118:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setDeleteAgentConfig(value);
      break;
    case 119:
      var value = new vivacity_config_agent_config_pb.AgentConfig;
      reader.readMessage(value,vivacity_config_agent_config_pb.AgentConfig.deserializeBinaryFromReader);
      msg.setAgentConfigDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.ConfigMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.ConfigMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.ConfigMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.ConfigMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventMessageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTimestampMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTracingCtx();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      vivacity_core_tracing_context_pb.TracingContext.serializeBinaryToWriter
    );
  }
  f = message.getBatchedConfigMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.vivacity.config.ConfigMessage.serializeBinaryToWriter
    );
  }
  f = message.getReducedBatchedConfigMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.vivacity.config.ConfigMessage.serializeBinaryToWriter
    );
  }
  f = message.getChangeZoneImageSpace();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      vivacity_config_zone_config_pb.ZonesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getZoneImageSpaceChanged();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      vivacity_config_zone_config_pb.ZonesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getChangeCountlineImageSpace();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      vivacity_config_countline_config_pb.CountlinesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getCountlineImageSpaceChanged();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      vivacity_config_countline_config_pb.CountlinesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getChangePerspectiveTransform();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.serializeBinaryToWriter
    );
  }
  f = message.getPerspectiveTransformChanged();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram.serializeBinaryToWriter
    );
  }
  f = message.getChangeCountlineRealWorldSpace();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      vivacity_config_countline_config_pb.CountlinesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getCountlineRealWorldSpaceChanged();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      vivacity_config_countline_config_pb.CountlinesConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getChangeZonalAlert();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      vivacity_config_alerts_config_pb.ZonalAlertConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getZonalAlertChanged();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      vivacity_config_alerts_config_pb.ZonalAlertConfigChange.serializeBinaryToWriter
    );
  }
  f = message.getGetHardwareId();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_hardware_pb.GetOrCreateHardwareId.serializeBinaryToWriter
    );
  }
  f = message.getCreateHardwareId();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_config_hardware_pb.GetOrCreateHardwareId.serializeBinaryToWriter
    );
  }
  f = message.getHardwareIdTokenResponse();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_config_hardware_pb.HardwareIdTokenResponse.serializeBinaryToWriter
    );
  }
  f = message.getHardwareIdCreated();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_config_hardware_pb.HardwareIdCreated.serializeBinaryToWriter
    );
  }
  f = message.getVaultResourceCreated();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_config_vault_pb.VaultResourceCreated.serializeBinaryToWriter
    );
  }
  f = message.getChangeHardwareFields();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      vivacity_config_hardware_pb.HardwareFields.serializeBinaryToWriter
    );
  }
  f = message.getHardwareFieldsChanged();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      vivacity_config_hardware_pb.HardwareFields.serializeBinaryToWriter
    );
  }
  f = message.getCreateVisionProgram();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getVisionProgramCreated();
  if (f !== 0) {
    writer.writeUint32(
      32,
      f
    );
  }
  f = message.getChangeVisionProgramFields();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramFields.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramFieldsChanged();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramFields.serializeBinaryToWriter
    );
  }
  f = message.getLinkCountlineImageSpaceToRealWorld();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.serializeBinaryToWriter
    );
  }
  f = message.getCountlineImageSpaceToRealWorldLinked();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkCountlineImageSpaceToRealWorld();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.serializeBinaryToWriter
    );
  }
  f = message.getCountlineImageSpaceToRealWorldUnlinked();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld.serializeBinaryToWriter
    );
  }
  f = message.getCreateFileTemplate();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getFileTemplateCreated();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getChangeFileTemplateFields();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getFileTemplateFieldsChanged();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getDeleteFileTemplate();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getFileTemplateDeleted();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      vivacity_config_file_templates_pb.FileTemplate.serializeBinaryToWriter
    );
  }
  f = message.getCreateRenderedFileEntry();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRenderedFileEntryCreated();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getDeleteRenderedFile();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRenderedFileDeleted();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRenderFile();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getFileRendered();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getUpdateFileOnHardware();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getFileOnHardwareUpdated();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRemoteFileStateOnDbUpdated();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getRetrieveRenderedFiles();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      vivacity_config_file_templates_pb.RetrieveFile.serializeBinaryToWriter
    );
  }
  f = message.getLinkVisionProgramToHardware();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardware.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToHardwareLinked();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardware.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkVisionProgramToHardware();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardware.serializeBinaryToWriter
    );
  }
  f = message.getVisionProgramToHardwareUnlinked();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      vivacity_config_vision_program_modification_pb.VisionProgramToHardware.serializeBinaryToWriter
    );
  }
  f = message.getChangeLinkageCronjobToHardware();
  if (f != null) {
    writer.writeMessage(
      64,
      f,
      vivacity_config_cronjob_pb.CronjobToHardwareLinkage.serializeBinaryToWriter
    );
  }
  f = message.getCronjobToHardwareLinkageChanged();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      vivacity_config_cronjob_pb.CronjobToHardwareLinkage.serializeBinaryToWriter
    );
  }
  f = message.getChangeCronjob();
  if (f != null) {
    writer.writeMessage(
      66,
      f,
      vivacity_config_cronjob_pb.Cronjob.serializeBinaryToWriter
    );
  }
  f = message.getCronjobChanged();
  if (f != null) {
    writer.writeMessage(
      67,
      f,
      vivacity_config_cronjob_pb.Cronjob.serializeBinaryToWriter
    );
  }
  f = message.getCreateCvModel();
  if (f != null) {
    writer.writeMessage(
      68,
      f,
      vivacity_benchmarking_cv_model_pb.CvModel.serializeBinaryToWriter
    );
  }
  f = message.getCreateBenchmarkRun();
  if (f != null) {
    writer.writeMessage(
      69,
      f,
      vivacity_benchmarking_benchmark_run_pb.BenchmarkRun.serializeBinaryToWriter
    );
  }
  f = message.getCreateCommandGroupTemplate();
  if (f != null) {
    writer.writeMessage(
      70,
      f,
      proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter
    );
  }
  f = message.getCommandGroupTemplateCreated();
  if (f != null) {
    writer.writeMessage(
      71,
      f,
      proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter
    );
  }
  f = message.getCreateAgent();
  if (f != null) {
    writer.writeMessage(
      72,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getAgentCreated();
  if (f != null) {
    writer.writeMessage(
      73,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getChangeAgent();
  if (f != null) {
    writer.writeMessage(
      74,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getAgentChanged();
  if (f != null) {
    writer.writeMessage(
      75,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAgent();
  if (f != null) {
    writer.writeMessage(
      76,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getAgentDeleted();
  if (f != null) {
    writer.writeMessage(
      77,
      f,
      vivacity_config_agent_config_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getCreateAgentStream();
  if (f != null) {
    writer.writeMessage(
      78,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getAgentStreamCreated();
  if (f != null) {
    writer.writeMessage(
      79,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getChangeAgentStream();
  if (f != null) {
    writer.writeMessage(
      80,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getAgentStreamChanged();
  if (f != null) {
    writer.writeMessage(
      81,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAgentStream();
  if (f != null) {
    writer.writeMessage(
      82,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getAgentStreamDeleted();
  if (f != null) {
    writer.writeMessage(
      83,
      f,
      vivacity_config_agent_config_pb.AgentStream.serializeBinaryToWriter
    );
  }
  f = message.getCreateCountry();
  if (f != null) {
    writer.writeMessage(
      84,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getCountryCreated();
  if (f != null) {
    writer.writeMessage(
      85,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getChangeCountry();
  if (f != null) {
    writer.writeMessage(
      86,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getCountryChanged();
  if (f != null) {
    writer.writeMessage(
      87,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getDeleteCountry();
  if (f != null) {
    writer.writeMessage(
      88,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getCountryDeleted();
  if (f != null) {
    writer.writeMessage(
      89,
      f,
      vivacity_config_region_config_pb.Country.serializeBinaryToWriter
    );
  }
  f = message.getCreateRegion();
  if (f != null) {
    writer.writeMessage(
      90,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getRegionCreated();
  if (f != null) {
    writer.writeMessage(
      91,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getChangeRegion();
  if (f != null) {
    writer.writeMessage(
      92,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getRegionChanged();
  if (f != null) {
    writer.writeMessage(
      93,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getDeleteRegion();
  if (f != null) {
    writer.writeMessage(
      94,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getRegionDeleted();
  if (f != null) {
    writer.writeMessage(
      95,
      f,
      vivacity_config_region_config_pb.Region.serializeBinaryToWriter
    );
  }
  f = message.getCreateController();
  if (f != null) {
    writer.writeMessage(
      96,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getControllerCreated();
  if (f != null) {
    writer.writeMessage(
      97,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getChangeController();
  if (f != null) {
    writer.writeMessage(
      98,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getControllerChanged();
  if (f != null) {
    writer.writeMessage(
      99,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getDeleteController();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getControllerDeleted();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      vivacity_config_controller_config_pb.Controller.serializeBinaryToWriter
    );
  }
  f = message.getCreateControllerStream();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamCreated();
  if (f != null) {
    writer.writeMessage(
      103,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getChangeControllerStream();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamChanged();
  if (f != null) {
    writer.writeMessage(
      105,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getDeleteControllerStream();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getControllerStreamDeleted();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      vivacity_config_controller_config_pb.ControllerStream.serializeBinaryToWriter
    );
  }
  f = message.getCreateVelocity();
  if (f != null) {
    writer.writeMessage(
      108,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getVelocityCreated();
  if (f != null) {
    writer.writeMessage(
      109,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getChangeVelocity();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getVelocityChanged();
  if (f != null) {
    writer.writeMessage(
      111,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getDeleteVelocity();
  if (f != null) {
    writer.writeMessage(
      112,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getVelocityDeleted();
  if (f != null) {
    writer.writeMessage(
      113,
      f,
      vivacity_config_instation_config_pb.Velocity.serializeBinaryToWriter
    );
  }
  f = message.getCreateAgentConfig();
  if (f != null) {
    writer.writeMessage(
      114,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getAgentConfigCreated();
  if (f != null) {
    writer.writeMessage(
      115,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getChangeAgentConfig();
  if (f != null) {
    writer.writeMessage(
      116,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getAgentConfigChanged();
  if (f != null) {
    writer.writeMessage(
      117,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAgentConfig();
  if (f != null) {
    writer.writeMessage(
      118,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
  f = message.getAgentConfigDeleted();
  if (f != null) {
    writer.writeMessage(
      119,
      f,
      vivacity_config_agent_config_pb.AgentConfig.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.ConfigMessage.EventMessageType = {
  UNKNOWN_EVENT_MESSAGE_TYPE: 0,
  BATCHED_CONFIG_MESSAGES: 7,
  REDUCED_BATCHED_CONFIG_MESSAGES: 8,
  CHANGE_ZONE_IMAGE_SPACE: 11,
  ZONE_IMAGE_SPACE_CHANGED: 12,
  CHANGE_COUNTLINE_IMAGE_SPACE: 13,
  COUNTLINE_IMAGE_SPACE_CHANGED: 14,
  CHANGE_PERSPECTIVE_TRANSFORM: 15,
  PERSPECTIVE_TRANSFORM_CHANGED: 16,
  CHANGE_COUNTLINE_REAL_WORLD_SPACE: 17,
  COUNTLINE_REAL_WORLD_SPACE_CHANGED: 18,
  CHANGE_ZONAL_ALERT: 19,
  ZONAL_ALERT_CHANGED: 20,
  GET_HARDWARE_ID: 21,
  CREATE_HARDWARE_ID: 22,
  HARDWARE_ID_TOKEN_RESPONSE: 23,
  HARDWARE_ID_CREATED: 24,
  VAULT_RESOURCE_CREATED: 25,
  CHANGE_HARDWARE_FIELDS: 26,
  HARDWARE_FIELDS_CHANGED: 27,
  CREATE_VISION_PROGRAM: 31,
  VISION_PROGRAM_CREATED: 32,
  CHANGE_VISION_PROGRAM_FIELDS: 33,
  VISION_PROGRAM_FIELDS_CHANGED: 34,
  LINK_COUNTLINE_IMAGE_SPACE_TO_REAL_WORLD: 35,
  COUNTLINE_IMAGE_SPACE_TO_REAL_WORLD_LINKED: 36,
  UNLINK_COUNTLINE_IMAGE_SPACE_TO_REAL_WORLD: 37,
  COUNTLINE_IMAGE_SPACE_TO_REAL_WORLD_UNLINKED: 38,
  CREATE_FILE_TEMPLATE: 40,
  FILE_TEMPLATE_CREATED: 41,
  CHANGE_FILE_TEMPLATE_FIELDS: 42,
  FILE_TEMPLATE_FIELDS_CHANGED: 43,
  DELETE_FILE_TEMPLATE: 44,
  FILE_TEMPLATE_DELETED: 45,
  CREATE_RENDERED_FILE_ENTRY: 46,
  RENDERED_FILE_ENTRY_CREATED: 47,
  DELETE_RENDERED_FILE: 48,
  RENDERED_FILE_DELETED: 49,
  RENDER_FILE: 50,
  FILE_RENDERED: 51,
  UPDATE_FILE_ON_HARDWARE: 52,
  FILE_ON_HARDWARE_UPDATED: 53,
  REMOTE_FILE_STATE_ON_DB_UPDATED: 54,
  RETRIEVE_RENDERED_FILES: 55,
  LINK_VISION_PROGRAM_TO_HARDWARE: 60,
  VISION_PROGRAM_TO_HARDWARE_LINKED: 61,
  UNLINK_VISION_PROGRAM_TO_HARDWARE: 62,
  VISION_PROGRAM_TO_HARDWARE_UNLINKED: 63,
  CHANGE_LINKAGE_CRONJOB_TO_HARDWARE: 64,
  CRONJOB_TO_HARDWARE_LINKAGE_CHANGED: 65,
  CHANGE_CRONJOB: 66,
  CRONJOB_CHANGED: 67,
  CREATE_CV_MODEL: 68,
  CV_MODEL_CREATED: 69,
  CREATE_BENCHMARK_RUN: 70,
  BENCHMARK_RUN_CREATED: 71,
  CREATE_COMMAND_GROUP_TEMPLATE: 72,
  COMMAND_GROUP_TEMPLATE_CREATED: 73,
  CREATE_AGENT: 74,
  AGENT_CREATED: 75,
  CHANGE_AGENT: 76,
  AGENT_CHANGED: 77,
  DELETE_AGENT: 78,
  AGENT_DELETED: 79,
  CREATE_AGENT_STREAM: 80,
  AGENT_STREAM_CREATED: 81,
  CHANGE_AGENT_STREAM: 82,
  AGENT_STREAM_CHANGED: 83,
  DELETE_AGENT_STREAM: 84,
  AGENT_STREAM_DELETED: 85,
  CREATE_CONTROLLER: 86,
  CONTROLLER_CREATED: 87,
  CHANGE_CONTROLLER: 88,
  CONTROLLER_CHANGED: 89,
  DELETE_CONTROLLER: 90,
  CONTROLLER_DELETED: 91,
  CREATE_CONTROLLER_STREAM: 92,
  CONTROLLER_STREAM_CREATED: 93,
  CHANGE_CONTROLLER_STREAM: 94,
  CONTROLLER_STREAM_CHANGED: 95,
  DELETE_CONTROLLER_STREAM: 96,
  CONTROLLER_STREAM_DELETED: 97,
  CREATE_COUNTRY: 98,
  COUNTRY_CREATED: 99,
  CHANGE_COUNTRY: 100,
  COUNTRY_CHANGED: 101,
  DELETE_COUNTRY: 102,
  COUNTRY_DELETED: 103,
  CREATE_REGION: 104,
  REGION_CREATED: 105,
  CHANGE_REGION: 106,
  REGION_CHANGED: 107,
  DELETE_REGION: 108,
  REGION_DELETED: 109,
  CREATE_VELOCITY: 110,
  VELOCITY_CREATED: 111,
  CHANGE_VELOCITY: 112,
  VELOCITY_CHANGED: 113,
  DELETE_VELOCITY: 114,
  VELOCITY_DELETED: 115,
  CREATE_AGENT_CONFIG: 116,
  AGENT_CONFIG_CREATED: 117,
  CHANGE_AGENT_CONFIG: 118,
  AGENT_CONFIG_CHANGED: 119,
  DELETE_AGENT_CONFIG: 120,
  AGENT_CONFIG_DELETED: 121
};

/**
 * optional EventMessageType event_message_type = 1;
 * @return {!proto.vivacity.config.ConfigMessage.EventMessageType}
 */
proto.vivacity.config.ConfigMessage.prototype.getEventMessageType = function() {
  return /** @type {!proto.vivacity.config.ConfigMessage.EventMessageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.vivacity.config.ConfigMessage.EventMessageType} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setEventMessageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional OriginatingSystem originating_system = 2;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.ConfigMessage.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 user_id = 3;
 * @return {number}
 */
proto.vivacity.config.ConfigMessage.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 timestamp_microseconds = 4;
 * @return {number}
 */
proto.vivacity.config.ConfigMessage.prototype.getTimestampMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setTimestampMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string correlation_id = 5;
 * @return {string}
 */
proto.vivacity.config.ConfigMessage.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional vivacity.core.TracingContext tracing_ctx = 6;
 * @return {?proto.vivacity.core.TracingContext}
 */
proto.vivacity.config.ConfigMessage.prototype.getTracingCtx = function() {
  return /** @type{?proto.vivacity.core.TracingContext} */ (
    jspb.Message.getWrapperField(this, vivacity_core_tracing_context_pb.TracingContext, 6));
};


/**
 * @param {?proto.vivacity.core.TracingContext|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setTracingCtx = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearTracingCtx = function() {
  return this.setTracingCtx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasTracingCtx = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ConfigMessage batched_config_messages = 7;
 * @return {!Array<!proto.vivacity.config.ConfigMessage>}
 */
proto.vivacity.config.ConfigMessage.prototype.getBatchedConfigMessagesList = function() {
  return /** @type{!Array<!proto.vivacity.config.ConfigMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.ConfigMessage, 7));
};


/**
 * @param {!Array<!proto.vivacity.config.ConfigMessage>} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setBatchedConfigMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.vivacity.config.ConfigMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.addBatchedConfigMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.vivacity.config.ConfigMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearBatchedConfigMessagesList = function() {
  return this.setBatchedConfigMessagesList([]);
};


/**
 * repeated ConfigMessage reduced_batched_config_messages = 8;
 * @return {!Array<!proto.vivacity.config.ConfigMessage>}
 */
proto.vivacity.config.ConfigMessage.prototype.getReducedBatchedConfigMessagesList = function() {
  return /** @type{!Array<!proto.vivacity.config.ConfigMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.ConfigMessage, 8));
};


/**
 * @param {!Array<!proto.vivacity.config.ConfigMessage>} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setReducedBatchedConfigMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.vivacity.config.ConfigMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.ConfigMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.addReducedBatchedConfigMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.vivacity.config.ConfigMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearReducedBatchedConfigMessagesList = function() {
  return this.setReducedBatchedConfigMessagesList([]);
};


/**
 * optional ZonesConfigChange change_zone_image_space = 11;
 * @return {?proto.vivacity.config.ZonesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeZoneImageSpace = function() {
  return /** @type{?proto.vivacity.config.ZonesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonesConfigChange, 11));
};


/**
 * @param {?proto.vivacity.config.ZonesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeZoneImageSpace = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeZoneImageSpace = function() {
  return this.setChangeZoneImageSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeZoneImageSpace = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ZonesConfigChange zone_image_space_changed = 12;
 * @return {?proto.vivacity.config.ZonesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getZoneImageSpaceChanged = function() {
  return /** @type{?proto.vivacity.config.ZonesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_zone_config_pb.ZonesConfigChange, 12));
};


/**
 * @param {?proto.vivacity.config.ZonesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZoneImageSpaceChanged = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZoneImageSpaceChanged = function() {
  return this.setZoneImageSpaceChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZoneImageSpaceChanged = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional CountlinesConfigChange change_countline_image_space = 13;
 * @return {?proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCountlineImageSpace = function() {
  return /** @type{?proto.vivacity.config.CountlinesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlinesConfigChange, 13));
};


/**
 * @param {?proto.vivacity.config.CountlinesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCountlineImageSpace = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCountlineImageSpace = function() {
  return this.setChangeCountlineImageSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCountlineImageSpace = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional CountlinesConfigChange countline_image_space_changed = 14;
 * @return {?proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineImageSpaceChanged = function() {
  return /** @type{?proto.vivacity.config.CountlinesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlinesConfigChange, 14));
};


/**
 * @param {?proto.vivacity.config.CountlinesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineImageSpaceChanged = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineImageSpaceChanged = function() {
  return this.setCountlineImageSpaceChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineImageSpaceChanged = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional PerspectiveTransformConfigByVisionProgram change_perspective_transform = 15;
 * @return {?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangePerspectiveTransform = function() {
  return /** @type{?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram} */ (
    jspb.Message.getWrapperField(this, vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram, 15));
};


/**
 * @param {?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangePerspectiveTransform = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangePerspectiveTransform = function() {
  return this.setChangePerspectiveTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangePerspectiveTransform = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PerspectiveTransformConfigByVisionProgram perspective_transform_changed = 16;
 * @return {?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram}
 */
proto.vivacity.config.ConfigMessage.prototype.getPerspectiveTransformChanged = function() {
  return /** @type{?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram} */ (
    jspb.Message.getWrapperField(this, vivacity_config_perspective_transform_pb.PerspectiveTransformConfigByVisionProgram, 16));
};


/**
 * @param {?proto.vivacity.config.PerspectiveTransformConfigByVisionProgram|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setPerspectiveTransformChanged = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearPerspectiveTransformChanged = function() {
  return this.setPerspectiveTransformChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasPerspectiveTransformChanged = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional CountlinesConfigChange change_countline_real_world_space = 17;
 * @return {?proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCountlineRealWorldSpace = function() {
  return /** @type{?proto.vivacity.config.CountlinesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlinesConfigChange, 17));
};


/**
 * @param {?proto.vivacity.config.CountlinesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCountlineRealWorldSpace = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCountlineRealWorldSpace = function() {
  return this.setChangeCountlineRealWorldSpace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCountlineRealWorldSpace = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional CountlinesConfigChange countline_real_world_space_changed = 18;
 * @return {?proto.vivacity.config.CountlinesConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineRealWorldSpaceChanged = function() {
  return /** @type{?proto.vivacity.config.CountlinesConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlinesConfigChange, 18));
};


/**
 * @param {?proto.vivacity.config.CountlinesConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineRealWorldSpaceChanged = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineRealWorldSpaceChanged = function() {
  return this.setCountlineRealWorldSpaceChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineRealWorldSpaceChanged = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ZonalAlertConfigChange change_zonal_alert = 19;
 * @return {?proto.vivacity.config.ZonalAlertConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeZonalAlert = function() {
  return /** @type{?proto.vivacity.config.ZonalAlertConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_alerts_config_pb.ZonalAlertConfigChange, 19));
};


/**
 * @param {?proto.vivacity.config.ZonalAlertConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeZonalAlert = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeZonalAlert = function() {
  return this.setChangeZonalAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeZonalAlert = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ZonalAlertConfigChange zonal_alert_changed = 20;
 * @return {?proto.vivacity.config.ZonalAlertConfigChange}
 */
proto.vivacity.config.ConfigMessage.prototype.getZonalAlertChanged = function() {
  return /** @type{?proto.vivacity.config.ZonalAlertConfigChange} */ (
    jspb.Message.getWrapperField(this, vivacity_config_alerts_config_pb.ZonalAlertConfigChange, 20));
};


/**
 * @param {?proto.vivacity.config.ZonalAlertConfigChange|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setZonalAlertChanged = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearZonalAlertChanged = function() {
  return this.setZonalAlertChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasZonalAlertChanged = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional GetOrCreateHardwareId get_hardware_id = 21;
 * @return {?proto.vivacity.config.GetOrCreateHardwareId}
 */
proto.vivacity.config.ConfigMessage.prototype.getGetHardwareId = function() {
  return /** @type{?proto.vivacity.config.GetOrCreateHardwareId} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.GetOrCreateHardwareId, 21));
};


/**
 * @param {?proto.vivacity.config.GetOrCreateHardwareId|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setGetHardwareId = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearGetHardwareId = function() {
  return this.setGetHardwareId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasGetHardwareId = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional GetOrCreateHardwareId create_hardware_id = 22;
 * @return {?proto.vivacity.config.GetOrCreateHardwareId}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateHardwareId = function() {
  return /** @type{?proto.vivacity.config.GetOrCreateHardwareId} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.GetOrCreateHardwareId, 22));
};


/**
 * @param {?proto.vivacity.config.GetOrCreateHardwareId|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateHardwareId = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateHardwareId = function() {
  return this.setCreateHardwareId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateHardwareId = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional HardwareIdTokenResponse hardware_id_token_response = 23;
 * @return {?proto.vivacity.config.HardwareIdTokenResponse}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwareIdTokenResponse = function() {
  return /** @type{?proto.vivacity.config.HardwareIdTokenResponse} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareIdTokenResponse, 23));
};


/**
 * @param {?proto.vivacity.config.HardwareIdTokenResponse|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwareIdTokenResponse = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwareIdTokenResponse = function() {
  return this.setHardwareIdTokenResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwareIdTokenResponse = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional HardwareIdCreated hardware_id_created = 24;
 * @return {?proto.vivacity.config.HardwareIdCreated}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwareIdCreated = function() {
  return /** @type{?proto.vivacity.config.HardwareIdCreated} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareIdCreated, 24));
};


/**
 * @param {?proto.vivacity.config.HardwareIdCreated|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwareIdCreated = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwareIdCreated = function() {
  return this.setHardwareIdCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwareIdCreated = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional VaultResourceCreated vault_resource_created = 25;
 * @return {?proto.vivacity.config.VaultResourceCreated}
 */
proto.vivacity.config.ConfigMessage.prototype.getVaultResourceCreated = function() {
  return /** @type{?proto.vivacity.config.VaultResourceCreated} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vault_pb.VaultResourceCreated, 25));
};


/**
 * @param {?proto.vivacity.config.VaultResourceCreated|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVaultResourceCreated = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVaultResourceCreated = function() {
  return this.setVaultResourceCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVaultResourceCreated = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional HardwareFields change_hardware_fields = 26;
 * @return {?proto.vivacity.config.HardwareFields}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeHardwareFields = function() {
  return /** @type{?proto.vivacity.config.HardwareFields} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareFields, 26));
};


/**
 * @param {?proto.vivacity.config.HardwareFields|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeHardwareFields = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeHardwareFields = function() {
  return this.setChangeHardwareFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeHardwareFields = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional HardwareFields hardware_fields_changed = 27;
 * @return {?proto.vivacity.config.HardwareFields}
 */
proto.vivacity.config.ConfigMessage.prototype.getHardwareFieldsChanged = function() {
  return /** @type{?proto.vivacity.config.HardwareFields} */ (
    jspb.Message.getWrapperField(this, vivacity_config_hardware_pb.HardwareFields, 27));
};


/**
 * @param {?proto.vivacity.config.HardwareFields|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setHardwareFieldsChanged = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearHardwareFieldsChanged = function() {
  return this.setHardwareFieldsChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasHardwareFieldsChanged = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional bool create_vision_program = 31;
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateVisionProgram = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setCreateVisionProgram = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional uint32 vision_program_created = 32;
 * @return {number}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional VisionProgramFields change_vision_program_fields = 33;
 * @return {?proto.vivacity.config.VisionProgramFields}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeVisionProgramFields = function() {
  return /** @type{?proto.vivacity.config.VisionProgramFields} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramFields, 33));
};


/**
 * @param {?proto.vivacity.config.VisionProgramFields|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeVisionProgramFields = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeVisionProgramFields = function() {
  return this.setChangeVisionProgramFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeVisionProgramFields = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional VisionProgramFields vision_program_fields_changed = 34;
 * @return {?proto.vivacity.config.VisionProgramFields}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramFieldsChanged = function() {
  return /** @type{?proto.vivacity.config.VisionProgramFields} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramFields, 34));
};


/**
 * @param {?proto.vivacity.config.VisionProgramFields|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramFieldsChanged = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramFieldsChanged = function() {
  return this.setVisionProgramFieldsChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramFieldsChanged = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional CountlineImageSpaceToRealWorld link_countline_image_space_to_real_world = 35;
 * @return {?proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.ConfigMessage.prototype.getLinkCountlineImageSpaceToRealWorld = function() {
  return /** @type{?proto.vivacity.config.CountlineImageSpaceToRealWorld} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld, 35));
};


/**
 * @param {?proto.vivacity.config.CountlineImageSpaceToRealWorld|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setLinkCountlineImageSpaceToRealWorld = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearLinkCountlineImageSpaceToRealWorld = function() {
  return this.setLinkCountlineImageSpaceToRealWorld(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasLinkCountlineImageSpaceToRealWorld = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional CountlineImageSpaceToRealWorld countline_image_space_to_real_world_linked = 36;
 * @return {?proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineImageSpaceToRealWorldLinked = function() {
  return /** @type{?proto.vivacity.config.CountlineImageSpaceToRealWorld} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld, 36));
};


/**
 * @param {?proto.vivacity.config.CountlineImageSpaceToRealWorld|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineImageSpaceToRealWorldLinked = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineImageSpaceToRealWorldLinked = function() {
  return this.setCountlineImageSpaceToRealWorldLinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineImageSpaceToRealWorldLinked = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional CountlineImageSpaceToRealWorld unlink_countline_image_space_to_real_world = 37;
 * @return {?proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.ConfigMessage.prototype.getUnlinkCountlineImageSpaceToRealWorld = function() {
  return /** @type{?proto.vivacity.config.CountlineImageSpaceToRealWorld} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld, 37));
};


/**
 * @param {?proto.vivacity.config.CountlineImageSpaceToRealWorld|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUnlinkCountlineImageSpaceToRealWorld = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUnlinkCountlineImageSpaceToRealWorld = function() {
  return this.setUnlinkCountlineImageSpaceToRealWorld(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUnlinkCountlineImageSpaceToRealWorld = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional CountlineImageSpaceToRealWorld countline_image_space_to_real_world_unlinked = 38;
 * @return {?proto.vivacity.config.CountlineImageSpaceToRealWorld}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountlineImageSpaceToRealWorldUnlinked = function() {
  return /** @type{?proto.vivacity.config.CountlineImageSpaceToRealWorld} */ (
    jspb.Message.getWrapperField(this, vivacity_config_countline_config_pb.CountlineImageSpaceToRealWorld, 38));
};


/**
 * @param {?proto.vivacity.config.CountlineImageSpaceToRealWorld|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountlineImageSpaceToRealWorldUnlinked = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountlineImageSpaceToRealWorldUnlinked = function() {
  return this.setCountlineImageSpaceToRealWorldUnlinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountlineImageSpaceToRealWorldUnlinked = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional FileTemplate create_file_template = 40;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateFileTemplate = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 40));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateFileTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateFileTemplate = function() {
  return this.setCreateFileTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateFileTemplate = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional FileTemplate file_template_created = 41;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileTemplateCreated = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 41));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileTemplateCreated = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileTemplateCreated = function() {
  return this.setFileTemplateCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileTemplateCreated = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional FileTemplate change_file_template_fields = 42;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeFileTemplateFields = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 42));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeFileTemplateFields = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeFileTemplateFields = function() {
  return this.setChangeFileTemplateFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeFileTemplateFields = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional FileTemplate file_template_fields_changed = 43;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileTemplateFieldsChanged = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 43));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileTemplateFieldsChanged = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileTemplateFieldsChanged = function() {
  return this.setFileTemplateFieldsChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileTemplateFieldsChanged = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional FileTemplate delete_file_template = 44;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteFileTemplate = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 44));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteFileTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteFileTemplate = function() {
  return this.setDeleteFileTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteFileTemplate = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional FileTemplate file_template_deleted = 45;
 * @return {?proto.vivacity.config.FileTemplate}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileTemplateDeleted = function() {
  return /** @type{?proto.vivacity.config.FileTemplate} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.FileTemplate, 45));
};


/**
 * @param {?proto.vivacity.config.FileTemplate|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileTemplateDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileTemplateDeleted = function() {
  return this.setFileTemplateDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileTemplateDeleted = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional RenderedFile create_rendered_file_entry = 46;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateRenderedFileEntry = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 46));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateRenderedFileEntry = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateRenderedFileEntry = function() {
  return this.setCreateRenderedFileEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateRenderedFileEntry = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional RenderedFile rendered_file_entry_created = 47;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderedFileEntryCreated = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 47));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderedFileEntryCreated = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderedFileEntryCreated = function() {
  return this.setRenderedFileEntryCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderedFileEntryCreated = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional RenderedFile delete_rendered_file = 48;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteRenderedFile = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 48));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteRenderedFile = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteRenderedFile = function() {
  return this.setDeleteRenderedFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteRenderedFile = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional RenderedFile rendered_file_deleted = 49;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderedFileDeleted = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 49));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderedFileDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 49, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderedFileDeleted = function() {
  return this.setRenderedFileDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderedFileDeleted = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional RenderedFile render_file = 50;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRenderFile = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 50));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRenderFile = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRenderFile = function() {
  return this.setRenderFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRenderFile = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional RenderedFile file_rendered = 51;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileRendered = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 51));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileRendered = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileRendered = function() {
  return this.setFileRendered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileRendered = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional RenderedFile update_file_on_hardware = 52;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getUpdateFileOnHardware = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 52));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUpdateFileOnHardware = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUpdateFileOnHardware = function() {
  return this.setUpdateFileOnHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUpdateFileOnHardware = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional RenderedFile file_on_hardware_updated = 53;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getFileOnHardwareUpdated = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 53));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setFileOnHardwareUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearFileOnHardwareUpdated = function() {
  return this.setFileOnHardwareUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasFileOnHardwareUpdated = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional RenderedFile remote_file_state_on_db_updated = 54;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRemoteFileStateOnDbUpdated = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 54));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRemoteFileStateOnDbUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRemoteFileStateOnDbUpdated = function() {
  return this.setRemoteFileStateOnDbUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRemoteFileStateOnDbUpdated = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional RetrieveFile retrieve_rendered_files = 55;
 * @return {?proto.vivacity.config.RetrieveFile}
 */
proto.vivacity.config.ConfigMessage.prototype.getRetrieveRenderedFiles = function() {
  return /** @type{?proto.vivacity.config.RetrieveFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RetrieveFile, 55));
};


/**
 * @param {?proto.vivacity.config.RetrieveFile|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRetrieveRenderedFiles = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRetrieveRenderedFiles = function() {
  return this.setRetrieveRenderedFiles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRetrieveRenderedFiles = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional VisionProgramToHardware link_vision_program_to_hardware = 60;
 * @return {?proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getLinkVisionProgramToHardware = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardware, 60));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setLinkVisionProgramToHardware = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearLinkVisionProgramToHardware = function() {
  return this.setLinkVisionProgramToHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasLinkVisionProgramToHardware = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional VisionProgramToHardware vision_program_to_hardware_linked = 61;
 * @return {?proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramToHardwareLinked = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardware, 61));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramToHardwareLinked = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramToHardwareLinked = function() {
  return this.setVisionProgramToHardwareLinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramToHardwareLinked = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional VisionProgramToHardware unlink_vision_program_to_hardware = 62;
 * @return {?proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getUnlinkVisionProgramToHardware = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardware, 62));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setUnlinkVisionProgramToHardware = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearUnlinkVisionProgramToHardware = function() {
  return this.setUnlinkVisionProgramToHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasUnlinkVisionProgramToHardware = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional VisionProgramToHardware vision_program_to_hardware_unlinked = 63;
 * @return {?proto.vivacity.config.VisionProgramToHardware}
 */
proto.vivacity.config.ConfigMessage.prototype.getVisionProgramToHardwareUnlinked = function() {
  return /** @type{?proto.vivacity.config.VisionProgramToHardware} */ (
    jspb.Message.getWrapperField(this, vivacity_config_vision_program_modification_pb.VisionProgramToHardware, 63));
};


/**
 * @param {?proto.vivacity.config.VisionProgramToHardware|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVisionProgramToHardwareUnlinked = function(value) {
  return jspb.Message.setWrapperField(this, 63, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVisionProgramToHardwareUnlinked = function() {
  return this.setVisionProgramToHardwareUnlinked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVisionProgramToHardwareUnlinked = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional CronjobToHardwareLinkage change_linkage_cronjob_to_hardware = 64;
 * @return {?proto.vivacity.config.CronjobToHardwareLinkage}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeLinkageCronjobToHardware = function() {
  return /** @type{?proto.vivacity.config.CronjobToHardwareLinkage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cronjob_pb.CronjobToHardwareLinkage, 64));
};


/**
 * @param {?proto.vivacity.config.CronjobToHardwareLinkage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeLinkageCronjobToHardware = function(value) {
  return jspb.Message.setWrapperField(this, 64, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeLinkageCronjobToHardware = function() {
  return this.setChangeLinkageCronjobToHardware(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeLinkageCronjobToHardware = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional CronjobToHardwareLinkage cronjob_to_hardware_linkage_changed = 65;
 * @return {?proto.vivacity.config.CronjobToHardwareLinkage}
 */
proto.vivacity.config.ConfigMessage.prototype.getCronjobToHardwareLinkageChanged = function() {
  return /** @type{?proto.vivacity.config.CronjobToHardwareLinkage} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cronjob_pb.CronjobToHardwareLinkage, 65));
};


/**
 * @param {?proto.vivacity.config.CronjobToHardwareLinkage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCronjobToHardwareLinkageChanged = function(value) {
  return jspb.Message.setWrapperField(this, 65, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCronjobToHardwareLinkageChanged = function() {
  return this.setCronjobToHardwareLinkageChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCronjobToHardwareLinkageChanged = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional Cronjob change_cronjob = 66;
 * @return {?proto.vivacity.config.Cronjob}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCronjob = function() {
  return /** @type{?proto.vivacity.config.Cronjob} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cronjob_pb.Cronjob, 66));
};


/**
 * @param {?proto.vivacity.config.Cronjob|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCronjob = function(value) {
  return jspb.Message.setWrapperField(this, 66, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCronjob = function() {
  return this.setChangeCronjob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCronjob = function() {
  return jspb.Message.getField(this, 66) != null;
};


/**
 * optional Cronjob cronjob_changed = 67;
 * @return {?proto.vivacity.config.Cronjob}
 */
proto.vivacity.config.ConfigMessage.prototype.getCronjobChanged = function() {
  return /** @type{?proto.vivacity.config.Cronjob} */ (
    jspb.Message.getWrapperField(this, vivacity_config_cronjob_pb.Cronjob, 67));
};


/**
 * @param {?proto.vivacity.config.Cronjob|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCronjobChanged = function(value) {
  return jspb.Message.setWrapperField(this, 67, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCronjobChanged = function() {
  return this.setCronjobChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCronjobChanged = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional vivacity.benchmarking.CvModel create_cv_model = 68;
 * @return {?proto.vivacity.benchmarking.CvModel}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCvModel = function() {
  return /** @type{?proto.vivacity.benchmarking.CvModel} */ (
    jspb.Message.getWrapperField(this, vivacity_benchmarking_cv_model_pb.CvModel, 68));
};


/**
 * @param {?proto.vivacity.benchmarking.CvModel|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCvModel = function(value) {
  return jspb.Message.setWrapperField(this, 68, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCvModel = function() {
  return this.setCreateCvModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCvModel = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional vivacity.benchmarking.BenchmarkRun create_benchmark_run = 69;
 * @return {?proto.vivacity.benchmarking.BenchmarkRun}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateBenchmarkRun = function() {
  return /** @type{?proto.vivacity.benchmarking.BenchmarkRun} */ (
    jspb.Message.getWrapperField(this, vivacity_benchmarking_benchmark_run_pb.BenchmarkRun, 69));
};


/**
 * @param {?proto.vivacity.benchmarking.BenchmarkRun|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateBenchmarkRun = function(value) {
  return jspb.Message.setWrapperField(this, 69, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateBenchmarkRun = function() {
  return this.setCreateBenchmarkRun(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateBenchmarkRun = function() {
  return jspb.Message.getField(this, 69) != null;
};


/**
 * optional CommandGroupTemplateMessage create_command_group_template = 70;
 * @return {?proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCommandGroupTemplate = function() {
  return /** @type{?proto.vivacity.config.CommandGroupTemplateMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.CommandGroupTemplateMessage, 70));
};


/**
 * @param {?proto.vivacity.config.CommandGroupTemplateMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCommandGroupTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 70, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCommandGroupTemplate = function() {
  return this.setCreateCommandGroupTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCommandGroupTemplate = function() {
  return jspb.Message.getField(this, 70) != null;
};


/**
 * optional CommandGroupTemplateMessage command_group_template_created = 71;
 * @return {?proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.ConfigMessage.prototype.getCommandGroupTemplateCreated = function() {
  return /** @type{?proto.vivacity.config.CommandGroupTemplateMessage} */ (
    jspb.Message.getWrapperField(this, proto.vivacity.config.CommandGroupTemplateMessage, 71));
};


/**
 * @param {?proto.vivacity.config.CommandGroupTemplateMessage|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCommandGroupTemplateCreated = function(value) {
  return jspb.Message.setWrapperField(this, 71, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCommandGroupTemplateCreated = function() {
  return this.setCommandGroupTemplateCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCommandGroupTemplateCreated = function() {
  return jspb.Message.getField(this, 71) != null;
};


/**
 * optional Agent create_agent = 72;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateAgent = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 72));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateAgent = function(value) {
  return jspb.Message.setWrapperField(this, 72, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateAgent = function() {
  return this.setCreateAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateAgent = function() {
  return jspb.Message.getField(this, 72) != null;
};


/**
 * optional Agent agent_created = 73;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentCreated = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 73));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentCreated = function(value) {
  return jspb.Message.setWrapperField(this, 73, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentCreated = function() {
  return this.setAgentCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentCreated = function() {
  return jspb.Message.getField(this, 73) != null;
};


/**
 * optional Agent change_agent = 74;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeAgent = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 74));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeAgent = function(value) {
  return jspb.Message.setWrapperField(this, 74, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeAgent = function() {
  return this.setChangeAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeAgent = function() {
  return jspb.Message.getField(this, 74) != null;
};


/**
 * optional Agent agent_changed = 75;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentChanged = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 75));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentChanged = function(value) {
  return jspb.Message.setWrapperField(this, 75, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentChanged = function() {
  return this.setAgentChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentChanged = function() {
  return jspb.Message.getField(this, 75) != null;
};


/**
 * optional Agent delete_agent = 76;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteAgent = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 76));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteAgent = function(value) {
  return jspb.Message.setWrapperField(this, 76, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteAgent = function() {
  return this.setDeleteAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteAgent = function() {
  return jspb.Message.getField(this, 76) != null;
};


/**
 * optional Agent agent_deleted = 77;
 * @return {?proto.vivacity.config.Agent}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentDeleted = function() {
  return /** @type{?proto.vivacity.config.Agent} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.Agent, 77));
};


/**
 * @param {?proto.vivacity.config.Agent|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 77, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentDeleted = function() {
  return this.setAgentDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentDeleted = function() {
  return jspb.Message.getField(this, 77) != null;
};


/**
 * optional AgentStream create_agent_stream = 78;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateAgentStream = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 78));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateAgentStream = function(value) {
  return jspb.Message.setWrapperField(this, 78, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateAgentStream = function() {
  return this.setCreateAgentStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateAgentStream = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * optional AgentStream agent_stream_created = 79;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentStreamCreated = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 79));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentStreamCreated = function(value) {
  return jspb.Message.setWrapperField(this, 79, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentStreamCreated = function() {
  return this.setAgentStreamCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentStreamCreated = function() {
  return jspb.Message.getField(this, 79) != null;
};


/**
 * optional AgentStream change_agent_stream = 80;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeAgentStream = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 80));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeAgentStream = function(value) {
  return jspb.Message.setWrapperField(this, 80, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeAgentStream = function() {
  return this.setChangeAgentStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeAgentStream = function() {
  return jspb.Message.getField(this, 80) != null;
};


/**
 * optional AgentStream agent_stream_changed = 81;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentStreamChanged = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 81));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentStreamChanged = function(value) {
  return jspb.Message.setWrapperField(this, 81, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentStreamChanged = function() {
  return this.setAgentStreamChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentStreamChanged = function() {
  return jspb.Message.getField(this, 81) != null;
};


/**
 * optional AgentStream delete_agent_stream = 82;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteAgentStream = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 82));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteAgentStream = function(value) {
  return jspb.Message.setWrapperField(this, 82, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteAgentStream = function() {
  return this.setDeleteAgentStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteAgentStream = function() {
  return jspb.Message.getField(this, 82) != null;
};


/**
 * optional AgentStream agent_stream_deleted = 83;
 * @return {?proto.vivacity.config.AgentStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentStreamDeleted = function() {
  return /** @type{?proto.vivacity.config.AgentStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentStream, 83));
};


/**
 * @param {?proto.vivacity.config.AgentStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentStreamDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 83, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentStreamDeleted = function() {
  return this.setAgentStreamDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentStreamDeleted = function() {
  return jspb.Message.getField(this, 83) != null;
};


/**
 * optional Country create_country = 84;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateCountry = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 84));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateCountry = function(value) {
  return jspb.Message.setWrapperField(this, 84, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateCountry = function() {
  return this.setCreateCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateCountry = function() {
  return jspb.Message.getField(this, 84) != null;
};


/**
 * optional Country country_created = 85;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountryCreated = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 85));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountryCreated = function(value) {
  return jspb.Message.setWrapperField(this, 85, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountryCreated = function() {
  return this.setCountryCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountryCreated = function() {
  return jspb.Message.getField(this, 85) != null;
};


/**
 * optional Country change_country = 86;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeCountry = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 86));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeCountry = function(value) {
  return jspb.Message.setWrapperField(this, 86, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeCountry = function() {
  return this.setChangeCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeCountry = function() {
  return jspb.Message.getField(this, 86) != null;
};


/**
 * optional Country country_changed = 87;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountryChanged = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 87));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountryChanged = function(value) {
  return jspb.Message.setWrapperField(this, 87, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountryChanged = function() {
  return this.setCountryChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountryChanged = function() {
  return jspb.Message.getField(this, 87) != null;
};


/**
 * optional Country delete_country = 88;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteCountry = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 88));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteCountry = function(value) {
  return jspb.Message.setWrapperField(this, 88, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteCountry = function() {
  return this.setDeleteCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteCountry = function() {
  return jspb.Message.getField(this, 88) != null;
};


/**
 * optional Country country_deleted = 89;
 * @return {?proto.vivacity.config.Country}
 */
proto.vivacity.config.ConfigMessage.prototype.getCountryDeleted = function() {
  return /** @type{?proto.vivacity.config.Country} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Country, 89));
};


/**
 * @param {?proto.vivacity.config.Country|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCountryDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 89, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCountryDeleted = function() {
  return this.setCountryDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCountryDeleted = function() {
  return jspb.Message.getField(this, 89) != null;
};


/**
 * optional Region create_region = 90;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateRegion = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 90));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateRegion = function(value) {
  return jspb.Message.setWrapperField(this, 90, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateRegion = function() {
  return this.setCreateRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateRegion = function() {
  return jspb.Message.getField(this, 90) != null;
};


/**
 * optional Region region_created = 91;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getRegionCreated = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 91));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRegionCreated = function(value) {
  return jspb.Message.setWrapperField(this, 91, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRegionCreated = function() {
  return this.setRegionCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRegionCreated = function() {
  return jspb.Message.getField(this, 91) != null;
};


/**
 * optional Region change_region = 92;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeRegion = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 92));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeRegion = function(value) {
  return jspb.Message.setWrapperField(this, 92, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeRegion = function() {
  return this.setChangeRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeRegion = function() {
  return jspb.Message.getField(this, 92) != null;
};


/**
 * optional Region region_changed = 93;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getRegionChanged = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 93));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRegionChanged = function(value) {
  return jspb.Message.setWrapperField(this, 93, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRegionChanged = function() {
  return this.setRegionChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRegionChanged = function() {
  return jspb.Message.getField(this, 93) != null;
};


/**
 * optional Region delete_region = 94;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteRegion = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 94));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteRegion = function(value) {
  return jspb.Message.setWrapperField(this, 94, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteRegion = function() {
  return this.setDeleteRegion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteRegion = function() {
  return jspb.Message.getField(this, 94) != null;
};


/**
 * optional Region region_deleted = 95;
 * @return {?proto.vivacity.config.Region}
 */
proto.vivacity.config.ConfigMessage.prototype.getRegionDeleted = function() {
  return /** @type{?proto.vivacity.config.Region} */ (
    jspb.Message.getWrapperField(this, vivacity_config_region_config_pb.Region, 95));
};


/**
 * @param {?proto.vivacity.config.Region|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setRegionDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 95, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearRegionDeleted = function() {
  return this.setRegionDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasRegionDeleted = function() {
  return jspb.Message.getField(this, 95) != null;
};


/**
 * optional Controller create_controller = 96;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateController = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 96));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateController = function(value) {
  return jspb.Message.setWrapperField(this, 96, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateController = function() {
  return this.setCreateController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateController = function() {
  return jspb.Message.getField(this, 96) != null;
};


/**
 * optional Controller controller_created = 97;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerCreated = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 97));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerCreated = function(value) {
  return jspb.Message.setWrapperField(this, 97, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerCreated = function() {
  return this.setControllerCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerCreated = function() {
  return jspb.Message.getField(this, 97) != null;
};


/**
 * optional Controller change_controller = 98;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeController = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 98));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeController = function(value) {
  return jspb.Message.setWrapperField(this, 98, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeController = function() {
  return this.setChangeController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeController = function() {
  return jspb.Message.getField(this, 98) != null;
};


/**
 * optional Controller controller_changed = 99;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerChanged = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 99));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerChanged = function(value) {
  return jspb.Message.setWrapperField(this, 99, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerChanged = function() {
  return this.setControllerChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerChanged = function() {
  return jspb.Message.getField(this, 99) != null;
};


/**
 * optional Controller delete_controller = 100;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteController = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 100));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteController = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteController = function() {
  return this.setDeleteController(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteController = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional Controller controller_deleted = 101;
 * @return {?proto.vivacity.config.Controller}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerDeleted = function() {
  return /** @type{?proto.vivacity.config.Controller} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.Controller, 101));
};


/**
 * @param {?proto.vivacity.config.Controller|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerDeleted = function() {
  return this.setControllerDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerDeleted = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional ControllerStream create_controller_stream = 102;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateControllerStream = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 102));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 102, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateControllerStream = function() {
  return this.setCreateControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateControllerStream = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional ControllerStream controller_stream_created = 103;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamCreated = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 103));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamCreated = function(value) {
  return jspb.Message.setWrapperField(this, 103, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamCreated = function() {
  return this.setControllerStreamCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamCreated = function() {
  return jspb.Message.getField(this, 103) != null;
};


/**
 * optional ControllerStream change_controller_stream = 104;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeControllerStream = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 104));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 104, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeControllerStream = function() {
  return this.setChangeControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeControllerStream = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional ControllerStream controller_stream_changed = 105;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamChanged = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 105));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamChanged = function(value) {
  return jspb.Message.setWrapperField(this, 105, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamChanged = function() {
  return this.setControllerStreamChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamChanged = function() {
  return jspb.Message.getField(this, 105) != null;
};


/**
 * optional ControllerStream delete_controller_stream = 106;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteControllerStream = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 106));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteControllerStream = function(value) {
  return jspb.Message.setWrapperField(this, 106, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteControllerStream = function() {
  return this.setDeleteControllerStream(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteControllerStream = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional ControllerStream controller_stream_deleted = 107;
 * @return {?proto.vivacity.config.ControllerStream}
 */
proto.vivacity.config.ConfigMessage.prototype.getControllerStreamDeleted = function() {
  return /** @type{?proto.vivacity.config.ControllerStream} */ (
    jspb.Message.getWrapperField(this, vivacity_config_controller_config_pb.ControllerStream, 107));
};


/**
 * @param {?proto.vivacity.config.ControllerStream|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setControllerStreamDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 107, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearControllerStreamDeleted = function() {
  return this.setControllerStreamDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasControllerStreamDeleted = function() {
  return jspb.Message.getField(this, 107) != null;
};


/**
 * optional Velocity create_velocity = 108;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateVelocity = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 108));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 108, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateVelocity = function() {
  return this.setCreateVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateVelocity = function() {
  return jspb.Message.getField(this, 108) != null;
};


/**
 * optional Velocity velocity_created = 109;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getVelocityCreated = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 109));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVelocityCreated = function(value) {
  return jspb.Message.setWrapperField(this, 109, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVelocityCreated = function() {
  return this.setVelocityCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVelocityCreated = function() {
  return jspb.Message.getField(this, 109) != null;
};


/**
 * optional Velocity change_velocity = 110;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeVelocity = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 110));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeVelocity = function() {
  return this.setChangeVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeVelocity = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional Velocity velocity_changed = 111;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getVelocityChanged = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 111));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVelocityChanged = function(value) {
  return jspb.Message.setWrapperField(this, 111, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVelocityChanged = function() {
  return this.setVelocityChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVelocityChanged = function() {
  return jspb.Message.getField(this, 111) != null;
};


/**
 * optional Velocity delete_velocity = 112;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteVelocity = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 112));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 112, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteVelocity = function() {
  return this.setDeleteVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteVelocity = function() {
  return jspb.Message.getField(this, 112) != null;
};


/**
 * optional Velocity velocity_deleted = 113;
 * @return {?proto.vivacity.config.Velocity}
 */
proto.vivacity.config.ConfigMessage.prototype.getVelocityDeleted = function() {
  return /** @type{?proto.vivacity.config.Velocity} */ (
    jspb.Message.getWrapperField(this, vivacity_config_instation_config_pb.Velocity, 113));
};


/**
 * @param {?proto.vivacity.config.Velocity|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setVelocityDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 113, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearVelocityDeleted = function() {
  return this.setVelocityDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasVelocityDeleted = function() {
  return jspb.Message.getField(this, 113) != null;
};


/**
 * optional AgentConfig create_agent_config = 114;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getCreateAgentConfig = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 114));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setCreateAgentConfig = function(value) {
  return jspb.Message.setWrapperField(this, 114, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearCreateAgentConfig = function() {
  return this.setCreateAgentConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasCreateAgentConfig = function() {
  return jspb.Message.getField(this, 114) != null;
};


/**
 * optional AgentConfig agent_config_created = 115;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentConfigCreated = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 115));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentConfigCreated = function(value) {
  return jspb.Message.setWrapperField(this, 115, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentConfigCreated = function() {
  return this.setAgentConfigCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentConfigCreated = function() {
  return jspb.Message.getField(this, 115) != null;
};


/**
 * optional AgentConfig change_agent_config = 116;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getChangeAgentConfig = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 116));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setChangeAgentConfig = function(value) {
  return jspb.Message.setWrapperField(this, 116, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearChangeAgentConfig = function() {
  return this.setChangeAgentConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasChangeAgentConfig = function() {
  return jspb.Message.getField(this, 116) != null;
};


/**
 * optional AgentConfig agent_config_changed = 117;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentConfigChanged = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 117));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentConfigChanged = function(value) {
  return jspb.Message.setWrapperField(this, 117, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentConfigChanged = function() {
  return this.setAgentConfigChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentConfigChanged = function() {
  return jspb.Message.getField(this, 117) != null;
};


/**
 * optional AgentConfig delete_agent_config = 118;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getDeleteAgentConfig = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 118));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setDeleteAgentConfig = function(value) {
  return jspb.Message.setWrapperField(this, 118, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearDeleteAgentConfig = function() {
  return this.setDeleteAgentConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasDeleteAgentConfig = function() {
  return jspb.Message.getField(this, 118) != null;
};


/**
 * optional AgentConfig agent_config_deleted = 119;
 * @return {?proto.vivacity.config.AgentConfig}
 */
proto.vivacity.config.ConfigMessage.prototype.getAgentConfigDeleted = function() {
  return /** @type{?proto.vivacity.config.AgentConfig} */ (
    jspb.Message.getWrapperField(this, vivacity_config_agent_config_pb.AgentConfig, 119));
};


/**
 * @param {?proto.vivacity.config.AgentConfig|undefined} value
 * @return {!proto.vivacity.config.ConfigMessage} returns this
*/
proto.vivacity.config.ConfigMessage.prototype.setAgentConfigDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 119, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.ConfigMessage} returns this
 */
proto.vivacity.config.ConfigMessage.prototype.clearAgentConfigDeleted = function() {
  return this.setAgentConfigDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.ConfigMessage.prototype.hasAgentConfigDeleted = function() {
  return jspb.Message.getField(this, 119) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.CommandGroupTemplateMessage.repeatedFields_ = [6,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CommandGroupTemplateMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CommandGroupTemplateMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupTemplateMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    entityType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    query: jspb.Message.getFieldWithDefault(msg, 5, ""),
    childQueriesList: jspb.Message.toObjectList(msg.getChildQueriesList(),
    proto.vivacity.config.CommandGroupTemplateQuery.toObject, includeInstance),
    hardwareIdQuery: jspb.Message.getFieldWithDefault(msg, 7, ""),
    commandsRequestsList: jspb.Message.toObjectList(msg.getCommandsRequestsList(),
    proto.vivacity.config.CommandRequest.toObject, includeInstance),
    leftDelimiter: jspb.Message.getFieldWithDefault(msg, 9, ""),
    rightDelimiter: jspb.Message.getFieldWithDefault(msg, 10, ""),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 11, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hardwareAvailabilityTimeoutMicroseconds: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CommandGroupTemplateMessage;
  return proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CommandGroupTemplateMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage}
 */
proto.vivacity.config.CommandGroupTemplateMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 6:
      var value = new proto.vivacity.config.CommandGroupTemplateQuery;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinaryFromReader);
      msg.addChildQueries(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareIdQuery(value);
      break;
    case 8:
      var value = new proto.vivacity.config.CommandRequest;
      reader.readMessage(value,proto.vivacity.config.CommandRequest.deserializeBinaryFromReader);
      msg.addCommandsRequests(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftDelimiter(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightDelimiter(value);
      break;
    case 11:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setHardwareAvailabilityTimeoutMicroseconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CommandGroupTemplateMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupTemplateMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChildQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.vivacity.config.CommandGroupTemplateQuery.serializeBinaryToWriter
    );
  }
  f = message.getHardwareIdQuery();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCommandsRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.vivacity.config.CommandRequest.serializeBinaryToWriter
    );
  }
  f = message.getLeftDelimiter();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRightDelimiter();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHardwareAvailabilityTimeoutMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string entity_type = 4;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getEntityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string query = 5;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated CommandGroupTemplateQuery child_queries = 6;
 * @return {!Array<!proto.vivacity.config.CommandGroupTemplateQuery>}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getChildQueriesList = function() {
  return /** @type{!Array<!proto.vivacity.config.CommandGroupTemplateQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.CommandGroupTemplateQuery, 6));
};


/**
 * @param {!Array<!proto.vivacity.config.CommandGroupTemplateQuery>} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
*/
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setChildQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.addChildQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.vivacity.config.CommandGroupTemplateQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.clearChildQueriesList = function() {
  return this.setChildQueriesList([]);
};


/**
 * optional string hardware_id_query = 7;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getHardwareIdQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setHardwareIdQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated CommandRequest commands_requests = 8;
 * @return {!Array<!proto.vivacity.config.CommandRequest>}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getCommandsRequestsList = function() {
  return /** @type{!Array<!proto.vivacity.config.CommandRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.CommandRequest, 8));
};


/**
 * @param {!Array<!proto.vivacity.config.CommandRequest>} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
*/
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setCommandsRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.vivacity.config.CommandRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CommandRequest}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.addCommandsRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.vivacity.config.CommandRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.clearCommandsRequestsList = function() {
  return this.setCommandsRequestsList([]);
};


/**
 * optional string left_delimiter = 9;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getLeftDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setLeftDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string right_delimiter = 10;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getRightDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setRightDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional OriginatingSystem originating_system = 11;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional uint32 user_id = 12;
 * @return {number}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string correlation_id = 13;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint64 hardware_availability_timeout_microseconds = 14;
 * @return {number}
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.getHardwareAvailabilityTimeoutMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupTemplateMessage} returns this
 */
proto.vivacity.config.CommandGroupTemplateMessage.prototype.setHardwareAvailabilityTimeoutMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.CommandGroupTemplateQuery.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CommandGroupTemplateQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupTemplateQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    query: jspb.Message.getFieldWithDefault(msg, 2, ""),
    childQueriesList: jspb.Message.toObjectList(msg.getChildQueriesList(),
    proto.vivacity.config.CommandGroupTemplateQuery.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery}
 */
proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CommandGroupTemplateQuery;
  return proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery}
 */
proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 3:
      var value = new proto.vivacity.config.CommandGroupTemplateQuery;
      reader.readMessage(value,proto.vivacity.config.CommandGroupTemplateQuery.deserializeBinaryFromReader);
      msg.addChildQueries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CommandGroupTemplateQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupTemplateQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChildQueriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.vivacity.config.CommandGroupTemplateQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery} returns this
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery} returns this
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CommandGroupTemplateQuery child_queries = 3;
 * @return {!Array<!proto.vivacity.config.CommandGroupTemplateQuery>}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.getChildQueriesList = function() {
  return /** @type{!Array<!proto.vivacity.config.CommandGroupTemplateQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.CommandGroupTemplateQuery, 3));
};


/**
 * @param {!Array<!proto.vivacity.config.CommandGroupTemplateQuery>} value
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery} returns this
*/
proto.vivacity.config.CommandGroupTemplateQuery.prototype.setChildQueriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.vivacity.config.CommandGroupTemplateQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery}
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.addChildQueries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.vivacity.config.CommandGroupTemplateQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CommandGroupTemplateQuery} returns this
 */
proto.vivacity.config.CommandGroupTemplateQuery.prototype.clearChildQueriesList = function() {
  return this.setChildQueriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.vivacity.config.CommandGroupRequest.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CommandGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CommandGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CommandGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    commandGroupTemplateId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    commandState: jspb.Message.getFieldWithDefault(msg, 3, 0),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    correlationId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    kafkaOffset: jspb.Message.getFieldWithDefault(msg, 9, 0),
    async: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    token: jspb.Message.getFieldWithDefault(msg, 11, ""),
    errorResponseType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    error: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hardwareAvailabilityTimeoutMicroseconds: jspb.Message.getFieldWithDefault(msg, 14, 0),
    vaultSignature: jspb.Message.getFieldWithDefault(msg, 15, ""),
    customTemplateValuesMap: (f = msg.getCustomTemplateValuesMap()) ? f.toObject(includeInstance, undefined) : [],
    commandsRequestsList: jspb.Message.toObjectList(msg.getCommandsRequestsList(),
    proto.vivacity.config.CommandRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CommandGroupRequest}
 */
proto.vivacity.config.CommandGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CommandGroupRequest;
  return proto.vivacity.config.CommandGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CommandGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CommandGroupRequest}
 */
proto.vivacity.config.CommandGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommandGroupTemplateId(value);
      break;
    case 3:
      var value = /** @type {!proto.vivacity.config.CommandGroupRequest.CommandState} */ (reader.readEnum());
      msg.setCommandState(value);
      break;
    case 4:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKafkaOffset(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAsync(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 12:
      var value = /** @type {!proto.vivacity.config.CommandGroupRequest.ErrorResponseType} */ (reader.readEnum());
      msg.setErrorResponseType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setHardwareAvailabilityTimeoutMicroseconds(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setVaultSignature(value);
      break;
    case 16:
      var value = msg.getCustomTemplateValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 20:
      var value = new proto.vivacity.config.CommandRequest;
      reader.readMessage(value,proto.vivacity.config.CommandRequest.deserializeBinaryFromReader);
      msg.addCommandsRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CommandGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CommandGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CommandGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCommandGroupTemplateId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCommandState();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getKafkaOffset();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getAsync();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getErrorResponseType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHardwareAvailabilityTimeoutMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getVaultSignature();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCustomTemplateValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getCommandsRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.vivacity.config.CommandRequest.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.CommandGroupRequest.CommandState = {
  UNKNOWN_COMMAND_STATE: 0,
  RENDER: 1,
  PENDING: 2,
  EXECUTE: 3,
  SUCCESS: 4,
  FAILED: 5,
  TIMED_OUT: 6,
  CANCEL: 7,
  CANCELLED: 8
};

/**
 * @enum {number}
 */
proto.vivacity.config.CommandGroupRequest.ErrorResponseType = {
  UNKNOWN_ERROR_RESPONSE: 0,
  NO_ERROR_RESPONSE: 1,
  COULD_NOT_MARSHAL_PROTOBUF: 2,
  VERIFICATION_AND_NEW_KEYS_AND_RELOGIN_FAILED: 3,
  VERIFICATION_AND_NEW_KEYS_FAILED: 4,
  NEW_KEYS_EMPTY: 5,
  VERIFICATION_FAILED: 6,
  CANCEL_ON_NON_RUNNING_COMMAND: 7
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 command_group_template_id = 2;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCommandGroupTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setCommandGroupTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CommandState command_state = 3;
 * @return {!proto.vivacity.config.CommandGroupRequest.CommandState}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCommandState = function() {
  return /** @type {!proto.vivacity.config.CommandGroupRequest.CommandState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.vivacity.config.CommandGroupRequest.CommandState} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setCommandState = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional OriginatingSystem originating_system = 4;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 user_id = 5;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string correlation_id = 6;
 * @return {string}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 entity_id = 7;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 hardware_id = 8;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 kafka_offset = 9;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getKafkaOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setKafkaOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool async = 10;
 * @return {boolean}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getAsync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setAsync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string token = 11;
 * @return {string}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional ErrorResponseType error_response_type = 12;
 * @return {!proto.vivacity.config.CommandGroupRequest.ErrorResponseType}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getErrorResponseType = function() {
  return /** @type {!proto.vivacity.config.CommandGroupRequest.ErrorResponseType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.vivacity.config.CommandGroupRequest.ErrorResponseType} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setErrorResponseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string error = 13;
 * @return {string}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint64 hardware_availability_timeout_microseconds = 14;
 * @return {number}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getHardwareAvailabilityTimeoutMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setHardwareAvailabilityTimeoutMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string vault_signature = 15;
 * @return {string}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getVaultSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.setVaultSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * map<string, string> custom_template_values = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCustomTemplateValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.clearCustomTemplateValuesMap = function() {
  this.getCustomTemplateValuesMap().clear();
  return this;
};


/**
 * repeated CommandRequest commands_requests = 20;
 * @return {!Array<!proto.vivacity.config.CommandRequest>}
 */
proto.vivacity.config.CommandGroupRequest.prototype.getCommandsRequestsList = function() {
  return /** @type{!Array<!proto.vivacity.config.CommandRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.vivacity.config.CommandRequest, 20));
};


/**
 * @param {!Array<!proto.vivacity.config.CommandRequest>} value
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
*/
proto.vivacity.config.CommandGroupRequest.prototype.setCommandsRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.vivacity.config.CommandRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.vivacity.config.CommandRequest}
 */
proto.vivacity.config.CommandGroupRequest.prototype.addCommandsRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.vivacity.config.CommandRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.vivacity.config.CommandGroupRequest} returns this
 */
proto.vivacity.config.CommandGroupRequest.prototype.clearCommandsRequestsList = function() {
  return this.setCommandsRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.vivacity.config.CommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.vivacity.config.CommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.vivacity.config.CommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correlationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originatingSystem: jspb.Message.getFieldWithDefault(msg, 2, 0),
    commandGroupRequestId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    serialIdInCommandGroup: jspb.Message.getFieldWithDefault(msg, 4, 0),
    commandRequestExecutionSerialId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    commandType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    commandTimeoutMicroseconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    retryOnFailure: jspb.Message.getFieldWithDefault(msg, 9, 0),
    continueGroupIfFailed: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    startedAtMicroseconds: jspb.Message.getFieldWithDefault(msg, 11, 0),
    managedFileTemplatedRenderedFileId: jspb.Message.getFieldWithDefault(msg, 20, 0),
    managedFile: (f = msg.getManagedFile()) && vivacity_config_file_templates_pb.RenderedFile.toObject(includeInstance, f),
    dockerImageCommand: (f = msg.getDockerImageCommand()) && vivacity_command_docker_pb.DockerImageCommand.toObject(includeInstance, f),
    serviceCommand: (f = msg.getServiceCommand()) && vivacity_command_service_pb.ServiceCommand.toObject(includeInstance, f),
    unmanagedFileCommand: (f = msg.getUnmanagedFileCommand()) && vivacity_command_unmanaged_file_pb.UnmanagedFileCommand.toObject(includeInstance, f),
    shellCommand: (f = msg.getShellCommand()) && vivacity_command_shell_pb.ShellCommand.toObject(includeInstance, f),
    commandExecutionState: jspb.Message.getFieldWithDefault(msg, 31, 0),
    errorResponseType: jspb.Message.getFieldWithDefault(msg, 32, 0),
    error: jspb.Message.getFieldWithDefault(msg, 33, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.vivacity.config.CommandRequest}
 */
proto.vivacity.config.CommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.vivacity.config.CommandRequest;
  return proto.vivacity.config.CommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.vivacity.config.CommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.vivacity.config.CommandRequest}
 */
proto.vivacity.config.CommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrelationId(value);
      break;
    case 2:
      var value = /** @type {!proto.vivacity.config.OriginatingSystem} */ (reader.readEnum());
      msg.setOriginatingSystem(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommandGroupRequestId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSerialIdInCommandGroup(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCommandRequestExecutionSerialId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHardwareId(value);
      break;
    case 7:
      var value = /** @type {!proto.vivacity.config.CommandRequest.CommandType} */ (reader.readEnum());
      msg.setCommandType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCommandTimeoutMicroseconds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRetryOnFailure(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContinueGroupIfFailed(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartedAtMicroseconds(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setManagedFileTemplatedRenderedFileId(value);
      break;
    case 21:
      var value = new vivacity_config_file_templates_pb.RenderedFile;
      reader.readMessage(value,vivacity_config_file_templates_pb.RenderedFile.deserializeBinaryFromReader);
      msg.setManagedFile(value);
      break;
    case 22:
      var value = new vivacity_command_docker_pb.DockerImageCommand;
      reader.readMessage(value,vivacity_command_docker_pb.DockerImageCommand.deserializeBinaryFromReader);
      msg.setDockerImageCommand(value);
      break;
    case 23:
      var value = new vivacity_command_service_pb.ServiceCommand;
      reader.readMessage(value,vivacity_command_service_pb.ServiceCommand.deserializeBinaryFromReader);
      msg.setServiceCommand(value);
      break;
    case 24:
      var value = new vivacity_command_unmanaged_file_pb.UnmanagedFileCommand;
      reader.readMessage(value,vivacity_command_unmanaged_file_pb.UnmanagedFileCommand.deserializeBinaryFromReader);
      msg.setUnmanagedFileCommand(value);
      break;
    case 25:
      var value = new vivacity_command_shell_pb.ShellCommand;
      reader.readMessage(value,vivacity_command_shell_pb.ShellCommand.deserializeBinaryFromReader);
      msg.setShellCommand(value);
      break;
    case 31:
      var value = /** @type {!proto.vivacity.config.CommandRequest.CommandState} */ (reader.readEnum());
      msg.setCommandExecutionState(value);
      break;
    case 32:
      var value = /** @type {!proto.vivacity.config.CommandRequest.ErrorResponseType} */ (reader.readEnum());
      msg.setErrorResponseType(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.vivacity.config.CommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.vivacity.config.CommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.vivacity.config.CommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.vivacity.config.CommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrelationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginatingSystem();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCommandGroupRequestId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSerialIdInCommandGroup();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCommandRequestExecutionSerialId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getHardwareId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCommandType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCommandTimeoutMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getRetryOnFailure();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getContinueGroupIfFailed();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getStartedAtMicroseconds();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getManagedFileTemplatedRenderedFileId();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getManagedFile();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      vivacity_config_file_templates_pb.RenderedFile.serializeBinaryToWriter
    );
  }
  f = message.getDockerImageCommand();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      vivacity_command_docker_pb.DockerImageCommand.serializeBinaryToWriter
    );
  }
  f = message.getServiceCommand();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      vivacity_command_service_pb.ServiceCommand.serializeBinaryToWriter
    );
  }
  f = message.getUnmanagedFileCommand();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      vivacity_command_unmanaged_file_pb.UnmanagedFileCommand.serializeBinaryToWriter
    );
  }
  f = message.getShellCommand();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      vivacity_command_shell_pb.ShellCommand.serializeBinaryToWriter
    );
  }
  f = message.getCommandExecutionState();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
  f = message.getErrorResponseType();
  if (f !== 0.0) {
    writer.writeEnum(
      32,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.vivacity.config.CommandRequest.CommandType = {
  UNKNOWN_COMMAND_TYPE: 0,
  MANAGED_FILE: 10,
  DOCKER_IMAGE: 11,
  SERVICE: 12,
  UNMANAGED_FILE: 13,
  SHELL: 14
};

/**
 * @enum {number}
 */
proto.vivacity.config.CommandRequest.CommandState = {
  UNKNOWN_COMMAND_STATE: 0,
  STARTED: 4,
  SUCCESS: 5,
  FAILED: 6,
  TIMED_OUT: 7,
  CANCEL: 8,
  CANCELLED: 9,
  FAILED_RETRYING: 10,
  TIMED_OUT_RETRYING: 11,
  INTERMEDIATE: 12
};

/**
 * @enum {number}
 */
proto.vivacity.config.CommandRequest.ErrorResponseType = {
  UNKNOWN_ERROR_RESPONSE: 0,
  NO_ERROR_RESPONSE: 1,
  UNKNOWN_COMMAND_REQUEST_TYPE: 2
};

/**
 * optional string correlation_id = 1;
 * @return {string}
 */
proto.vivacity.config.CommandRequest.prototype.getCorrelationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCorrelationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OriginatingSystem originating_system = 2;
 * @return {!proto.vivacity.config.OriginatingSystem}
 */
proto.vivacity.config.CommandRequest.prototype.getOriginatingSystem = function() {
  return /** @type {!proto.vivacity.config.OriginatingSystem} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.vivacity.config.OriginatingSystem} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setOriginatingSystem = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 command_group_request_id = 3;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandGroupRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandGroupRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 serial_id_in_command_group = 4;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getSerialIdInCommandGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setSerialIdInCommandGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 command_request_execution_serial_id = 5;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandRequestExecutionSerialId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandRequestExecutionSerialId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 hardware_id = 6;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getHardwareId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional CommandType command_type = 7;
 * @return {!proto.vivacity.config.CommandRequest.CommandType}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandType = function() {
  return /** @type {!proto.vivacity.config.CommandRequest.CommandType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.vivacity.config.CommandRequest.CommandType} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional uint64 command_timeout_microseconds = 8;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandTimeoutMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandTimeoutMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 retry_on_failure = 9;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getRetryOnFailure = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setRetryOnFailure = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool continue_group_if_failed = 10;
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.getContinueGroupIfFailed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setContinueGroupIfFailed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional uint64 started_at_microseconds = 11;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getStartedAtMicroseconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setStartedAtMicroseconds = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 managed_file_templated_rendered_file_id = 20;
 * @return {number}
 */
proto.vivacity.config.CommandRequest.prototype.getManagedFileTemplatedRenderedFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setManagedFileTemplatedRenderedFileId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional RenderedFile managed_file = 21;
 * @return {?proto.vivacity.config.RenderedFile}
 */
proto.vivacity.config.CommandRequest.prototype.getManagedFile = function() {
  return /** @type{?proto.vivacity.config.RenderedFile} */ (
    jspb.Message.getWrapperField(this, vivacity_config_file_templates_pb.RenderedFile, 21));
};


/**
 * @param {?proto.vivacity.config.RenderedFile|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setManagedFile = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearManagedFile = function() {
  return this.setManagedFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasManagedFile = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional vivacity.command.DockerImageCommand docker_image_command = 22;
 * @return {?proto.vivacity.command.DockerImageCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getDockerImageCommand = function() {
  return /** @type{?proto.vivacity.command.DockerImageCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_docker_pb.DockerImageCommand, 22));
};


/**
 * @param {?proto.vivacity.command.DockerImageCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setDockerImageCommand = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearDockerImageCommand = function() {
  return this.setDockerImageCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasDockerImageCommand = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional vivacity.command.ServiceCommand service_command = 23;
 * @return {?proto.vivacity.command.ServiceCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getServiceCommand = function() {
  return /** @type{?proto.vivacity.command.ServiceCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_service_pb.ServiceCommand, 23));
};


/**
 * @param {?proto.vivacity.command.ServiceCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setServiceCommand = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearServiceCommand = function() {
  return this.setServiceCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasServiceCommand = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional vivacity.command.UnmanagedFileCommand unmanaged_file_command = 24;
 * @return {?proto.vivacity.command.UnmanagedFileCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getUnmanagedFileCommand = function() {
  return /** @type{?proto.vivacity.command.UnmanagedFileCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_unmanaged_file_pb.UnmanagedFileCommand, 24));
};


/**
 * @param {?proto.vivacity.command.UnmanagedFileCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setUnmanagedFileCommand = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearUnmanagedFileCommand = function() {
  return this.setUnmanagedFileCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasUnmanagedFileCommand = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional vivacity.command.ShellCommand shell_command = 25;
 * @return {?proto.vivacity.command.ShellCommand}
 */
proto.vivacity.config.CommandRequest.prototype.getShellCommand = function() {
  return /** @type{?proto.vivacity.command.ShellCommand} */ (
    jspb.Message.getWrapperField(this, vivacity_command_shell_pb.ShellCommand, 25));
};


/**
 * @param {?proto.vivacity.command.ShellCommand|undefined} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
*/
proto.vivacity.config.CommandRequest.prototype.setShellCommand = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.clearShellCommand = function() {
  return this.setShellCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.vivacity.config.CommandRequest.prototype.hasShellCommand = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional CommandState command_execution_state = 31;
 * @return {!proto.vivacity.config.CommandRequest.CommandState}
 */
proto.vivacity.config.CommandRequest.prototype.getCommandExecutionState = function() {
  return /** @type {!proto.vivacity.config.CommandRequest.CommandState} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.vivacity.config.CommandRequest.CommandState} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setCommandExecutionState = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};


/**
 * optional ErrorResponseType error_response_type = 32;
 * @return {!proto.vivacity.config.CommandRequest.ErrorResponseType}
 */
proto.vivacity.config.CommandRequest.prototype.getErrorResponseType = function() {
  return /** @type {!proto.vivacity.config.CommandRequest.ErrorResponseType} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.vivacity.config.CommandRequest.ErrorResponseType} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setErrorResponseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 32, value);
};


/**
 * optional string error = 33;
 * @return {string}
 */
proto.vivacity.config.CommandRequest.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.vivacity.config.CommandRequest} returns this
 */
proto.vivacity.config.CommandRequest.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * @enum {number}
 */
proto.vivacity.config.OriginatingSystem = {
  UNKNOWN_ORIGINATING_SYSTEM: 0,
  AUDACITY: 1,
  ZONE_IMAGE_SPACE_REDUCER: 2,
  VIVACITY_HARDWARE: 3,
  WORKSHOP_FLASHER: 4,
  INIT_HANDLER: 5,
  HARDWARE_ID_REDUCER: 6,
  POSTGRES_REDUCER: 7,
  COUNTLINE_IMAGE_SPACE_REDUCER: 8,
  VISION_PROGRAM_MODIFICATION_REDUCER: 9,
  PERSPECTIVE_TRANSFORM_REDUCER: 10,
  COUNTLINE_REAL_WORLD_SPACE_REDUCER: 11,
  BATCHED_CONFIG_MESSAGES_REDUCER: 12,
  COUNTLINE_LINK_REDUCER: 13,
  FILE_RETRIEVER_HANDLER: 14,
  CREATE_FILE_TEMPLATE_REDUCER: 15,
  CHANGE_FILE_TEMPLATE_REDUCER: 16,
  DELETE_FILE_TEMPLATE_REDUCER: 17,
  CREATE_RENDERED_FILE_ENTRY_REDUCER: 18,
  DELETE_RENDERED_FILE_REDUCER: 19,
  RENDER_FILE_REDUCER: 20,
  FILE_ON_HARDWARE_UPDATED_REDUCER: 21,
  CHANGE_HARDWARE_FIELDS_REDUCER: 22,
  HWMAN_FILE_RETRIEVER_CLIENT: 23,
  VAULT_REDUCER: 24,
  VISION_PROGRAM_AND_HARDWARE_LINK_REDUCER: 25,
  ZONAL_ALERTS_REDUCER: 26,
  DEVELOPER: 27,
  CRONJOB_HARDWARE_LINK_REDUCER: 28,
  CHANGE_CRONJOB_REDUCER: 29,
  CV_MODEL_REDUCER: 30,
  BENCHMARK_RUN_REDUCER: 31,
  COMMAND_GROUP_TEMPLATE_REDUCER: 32,
  COMMAND_RENDERER: 33,
  HWMAN_COMMAND_MANAGEMENT: 34,
  COMMAND_HANDLER: 35,
  CREATE_AGENT_REDUCER: 36,
  CHANGE_AGENT_REDUCER: 37,
  DELETE_AGENT_REDUCER: 38,
  CREATE_AGENT_STREAM_REDUCER: 39,
  CHANGE_AGENT_STREAM_REDUCER: 40,
  DELETE_AGENT_STREAM_REDUCER: 41,
  CREATE_COUNTRY_REDUCER: 42,
  CHANGE_COUNTRY_REDUCER: 43,
  DELETE_COUNTRY_REDUCER: 44,
  CREATE_REGION_REDUCER: 45,
  CHANGE_REGION_REDUCER: 46,
  DELETE_REGION_REDUCER: 47,
  CREATE_CONTROLLER_REDUCER: 48,
  CHANGE_CONTROLLER_REDUCER: 49,
  DELETE_CONTROLLER_REDUCER: 50,
  CREATE_CONTROLLER_STREAM_REDUCER: 51,
  CHANGE_CONTROLLER_STREAM_REDUCER: 52,
  DELETE_CONTROLLER_STREAM_REDUCER: 53,
  CREATE_VELOCITY_REDUCER: 54,
  CHANGE_VELOCITY_REDUCER: 55,
  DELETE_VELOCITY_REDUCER: 56,
  CREATE_AGENT_CONFIG_REDUCER: 57,
  CHANGE_AGENT_CONFIG_REDUCER: 58,
  DELETE_AGENT_CONFIG_REDUCER: 59
};

goog.object.extend(exports, proto.vivacity.config);
