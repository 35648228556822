import React, {useEffect, useState} from "react"


export const Video: React.FC<{RtcPeerId: string | null, stream: MediaStream | undefined, onPlay: ()=>void}> = ({RtcPeerId, stream, onPlay}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [calledOnPlay, setCalledOnPlay] = useState(false);
  useEffect(() => {
    if(isPlaying && !calledOnPlay) {
      onPlay()
      setCalledOnPlay(true)
    }
  }, [isPlaying, calledOnPlay])
  if (!RtcPeerId) {
    return null;
  }
  return <video
    key={"video-ref-video" + RtcPeerId}
    ref={(vidRef) => {
      if (vidRef && stream) {
        vidRef.srcObject = stream;
      }
    }}
    id={"video-ref-video" + RtcPeerId}
    style={{
      width: "100%",
      height: "auto",
    }}
    onCanPlay={(e) => {
      e.currentTarget.play()
        .then(() => {
          console.log("playing video", stream);
          setIsPlaying(true);
        })
        .catch(reason => {
          console.error("failed to play video", reason)
        })
    }}
    autoPlay={true}
    muted={true}
    playsInline={true}
  />
}