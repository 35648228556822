import {ENVIRONMENT} from "./getEnvironment";

let urls = {
    signallingUrl: "webrtc-signalling.vivacitylabs.com"
};

const hostname = window.location.hostname;
if (hostname.includes("localhost")) {
    urls.signallingUrl = "webrtc-signalling.dev.vivacitylabs.com";
} else if (hostname.includes("dev.vivacitylabs.com")) {
    urls.signallingUrl = "webrtc-signalling.dev.vivacitylabs.com";
} else if (hostname.includes("staging.vivacitylabs.com")) {
    urls.signallingUrl = "webrtc-signalling.staging.vivacitylabs.com";
}

export default urls;
