import { inject, observer } from "mobx-react";
import React from "react"
import StoreRoot from "../../stores/StoreRoot";
import { DataChannelSelectOption } from "../../utils/dataChannelOptions";
import { VideoSelectOption } from "../../utils/videoOptions";
import {PeerID, PrincipalID, RemotePeer, VideoID} from "../common/types"
import qs from "qs";
import { WebRTCError } from "../../stores/WebRTCPeerConnectionState";
import { Alert } from "reactstrap"
import PeerConnectionDebugInfo from "../PeerConnectionDebugInfo"
import { Video } from "./Video"

type State = {
    selectedVideo: VideoSelectOption | undefined
    selectedDataChannels: DataChannelSelectOption[]
    visionProgramID: string
    connectionStarted: boolean
    buttonOn: boolean
    width: number
    height: number
    error: string
    status: string
    videoReady: boolean
    stream: MediaStream | undefined
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

@inject("appState")
@observer
class DeprecatedVideoFrame extends React.Component<{ appState: StoreRoot, selectedVideo: VideoSelectOption }, State> {
    private videoID: VideoID | undefined;
    constructor(props: { appState: StoreRoot, selectedVideo: VideoSelectOption }) {
        super(props);
        this.props.appState.RTC.setAutoReconnectEnabled(true);

        const initialAspectRatio = 1080/1920;
        let { width, height } = getWindowDimensions();
        let {width: newWidth, height: newHeight} = this.constrainAspectRatio(width, height, initialAspectRatio);

        this.state = {
            selectedVideo: props.selectedVideo,
            selectedDataChannels: [],
            visionProgramID: "",
            buttonOn: false,
            connectionStarted: false,
            width: newWidth,
            height: newHeight,
            error: "",
            status: "",
            stream: undefined,
            videoReady: false,
        }
        this.props.appState.RTC.on("peer-connection-failure", (principalID: PrincipalID, peerID: PeerID) => {
            let remotePeerSettings = this.props.appState.RemotePeers.get(principalID);
            if (this.state.selectedVideo){
                if (!remotePeerSettings ) {
                   remotePeerSettings = {
                       desiredDataChannels: this.state.selectedDataChannels,
                       desiredVideoTracks: [this.state.selectedVideo.value]
                   }
                }
                this.props.appState.RTC.sendRequestPeerConnectionMessage(principalID, remotePeerSettings.desiredVideoTracks, remotePeerSettings.desiredDataChannels);
            }
        });
        this.props.appState.RTC.on("peer-connection-permanent-failure", (principalID: PrincipalID, peerID: PeerID) => {
            const msg = `Connection to ${principalID} is unavailable or too unstable and has been removed; try using snapshots or a lower quality video feed`;
            const error: WebRTCError = {
                type: "PEER_OFFLINE_OR_UNSTABLE",
                message: msg,
                label: ""
            }
            this.props.appState.RTC.globalErrors.push(error);
            this.props.appState.RemotePeers.delete(principalID);
            this.props.appState.RTC.currentPeerIdByPrincipalId.delete(principalID);
        });

    }

    handleResize() {
        let { width, height } = getWindowDimensions()
        this.setState((prev) => {
            return {
                ...prev,
                width,
                height,
            }
        })
    }

    constrainAspectRatio(width: number, height: number, aspectRatio: number): {width: number, height: number} {
        const newHeight = width * aspectRatio;
        const newWidth = height / aspectRatio;
        if (height/width > aspectRatio) {
            // Shrink height for padding
            height = newHeight;
        } else {
            // Shrink width for padding
            width = newWidth
        }
        return {
            width, height
        }
    }

    async componentDidMount(){
        this.setState(old => {return {...old, status: "Retrieved JWT"}})
        // get vision program ID from query params
        const search = window.location.search;
        var query = qs.parse(search, { ignoreQueryPrefix: true });
        const vpid = query.vpid || "";
        this.setState(
            { visionProgramID: vpid as string}
        );

        this.setState(old => {return {...old, status: "Connecting to Signalling Server"}})
        // Wait 5 seconds for connection to signalling server to be established
        let tries = 0;
        while (!(this.props.appState.SignallingServerConnection.connectionStatus === "OPEN")){
            await new Promise(resolve =>
            {
                setTimeout(() => {
                    resolve('');
                }, 500)
            });
            tries += 1;
            if (tries >= 10){
                break;
            }
        }
        window.addEventListener("resize", this.handleResize.bind(this))

        if (this.props.appState.SignallingServerConnection.connectionStatus !== "OPEN") {
            this.setState((old) => {return { ...old, error:"Could not connect to signalling server. Refresh the page to try again",  }});
            return;
        }
        this.startVisionProgramConnection();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize.bind(this))
    }
    componentDidUpdate(prevProps: Readonly<{
        appState: StoreRoot;
        selectedVideo: VideoSelectOption
    }>, prevState: Readonly<State>, snapshot?: any) {

        if (this.videoID) {
            const stream = this.props.appState.RTC.streamsByID.get(this.videoID.toString());
            if(stream !== this.state.stream) {
                this.setState((old) => {return {...old, stream: stream}});
            }
        }
    }

    startVisionProgramConnection() {
        // Establish connection
        if (this.state.visionProgramID !== "" && this.props.appState.SignallingServerConnection.connectionStatus === "OPEN") {
            const parsedVpid = parseInt(this.state.visionProgramID, 10)
            const visionProgramString = `vision-program-${this.state.visionProgramID}`
            if (!isNaN(parsedVpid) && this.state.selectedVideo) {
                const remotePeer: RemotePeer = {
                    desiredDataChannels: this.state.selectedDataChannels,
                    desiredVideoTracks: [this.state.selectedVideo.value]
                }
                this.props.appState.RemotePeers.set(visionProgramString, remotePeer)
                this.setState(old => {return {...old, status: `Requesting connection to VPID ${parsedVpid}`}})
                this.props.appState.RTC.sendRequestPeerConnectionMessage(visionProgramString, [this.state.selectedVideo.value], this.state.selectedDataChannels);
            } else {
                console.error("VPID must be a number")
                this.setState((old) => {return { ...old, error: "Vision Program ID must be a number",  }});
            }
        } else if (this.state.visionProgramID === "") {
            this.setState((old) => {return { ...old, error: "Vision Program ID is not set" }});
        }
    }


    render() {
        // let video: JSX.Element | null = null;
        let debugConnection: JSX.Element | null  = null;
        let RtcPeerId2: string | null = null
        const activePrincipalIds = [...this.props.appState.RTC.currentPeerIdByPrincipalId.keys()];
        activePrincipalIds.sort().forEach(principalId => {
            const RtcPeerId = this.props.appState.RTC.currentPeerIdByPrincipalId.get(principalId);
            if (!RtcPeerId)
                return;
            const peerConn = this.props.appState.RTC.peerConnections.get(RtcPeerId);
            if (!peerConn)
                return;


            this.props.appState.RTC.streams.forEach((peerStreams, streamPeerID) => {
                if (RtcPeerId === streamPeerID) {
                    peerStreams.forEach((mediaStreams) => {
                        mediaStreams.forEach((mediaStream) => {
                            this.videoID = new VideoID(RtcPeerId, mediaStream.id);
                            const tracks = mediaStream.getVideoTracks();
                            if (tracks.length > 0) {
                                RtcPeerId2 = RtcPeerId
                            }
                        });
                    });
                }
            })
            debugConnection = (<PeerConnectionDebugInfo key={RtcPeerId} peerId={RtcPeerId} appState={this.props.appState}/>);
        });
        let globalError: JSX.Element[] = [];
        if (this.props.appState.RTC.globalErrors.length > 0) {

            globalError = this.props.appState.RTC.globalErrors.filter((val, index) =>
              this.props.appState.RTC.globalErrors.findIndex(
                (val2, index2) =>
                  val.label == val2.label &&
                  val.type == val2.type &&
                  val.message == val2.message) === index).map(err => <Alert key={err.type+err.message+err.label} color={"danger"}>{err.type}: {err.message} {err.label} </Alert>)
        }
        return (
          <div>
              {!this.state.videoReady && this.state.status !== "" &&
                <Alert color={"info"}>{this.state.status}</Alert>
              }
              {!this.state.videoReady && this.props.appState.RTC.lastSignallingMsg !== "" &&
                <Alert color={"info"}>{this.props.appState.RTC.lastSignallingMsg}</Alert>
              }
              {(this.props.appState.SignallingServerConnection.connectionStatus !== "OPEN") &&
                <Alert color={"danger"}>Unable to connect to signalling server</Alert>}
              {this.state.error !== "" &&
                <Alert color={"danger"}>{this.state.error}</Alert>
              }
              {globalError}
              <Video RtcPeerId={RtcPeerId2} stream={this.state.stream} onPlay={()=> {
                  this.setState(old => {
                      return {...old, videoReady: true};
                  });
              }}
              />
              {debugConnection}
          </div>
        );
    }
}

export default DeprecatedVideoFrame;
